import React, { useEffect, useRef, useState, useMemo } from "react";

const CounterSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  // Memoize the counters array
  const counters = useMemo(
    () => [
      { label: "Partner Universities", count: 150 },
      { label: "Students Processed", count: 10000 },
      { label: "Countries Served", count: 25 },
    ],
    []
  );

  const [displayedCounts, setDisplayedCounts] = useState(counters.map(() => 0));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once the element is visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    const currentRef = ref.current; // Copy ref.current to a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the copied variable
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const duration = 2000; // Duration of the counting animation in ms
      const animationFrames = 60; // Number of animation frames

      counters.forEach((counter, index) => {
        let start = 0;
        const end = counter.count;
        const increment = Math.ceil(end / (duration / (1000 / animationFrames)));

        const counterInterval = setInterval(() => {
          start += increment;
          if (start >= end) {
            start = end; // Ensure it doesn't exceed the final count
            clearInterval(counterInterval);
          }
          setDisplayedCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = start;
            return newCounts;
          });
        }, 1000 / animationFrames);
      });
    }
  }, [isVisible, counters]);

  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <div ref={ref} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center">
          {counters.map((counter, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105 duration-300 ease-in-out"
            >
              <h2 className={`text-4xl font-bold text-[#eb4934] mb-2`}>
                {displayedCounts[index].toLocaleString()} +
              </h2>
              <p className="text-lg font-medium text-gray-700">{counter.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
