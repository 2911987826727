import React from "react";

// Word documents samples:
// 1. Relationship Verification Sample
// 2. Birth Certificate Sample
// 3. Address Verification Sample
// 4. Name Verification Sample
// 5. Tax clearance Certificate Sample
// 6. Income source Verification SarmpJe

import RelationShipVerification from "../../../images/relationship-verification.png";
import BirthCertificateLogo from "../../../images/birth-certificate.png";
import AddressVerification from "../../../images/address-verification.png";
import NameVerification from "../../../images/name-verification.png";
import TaxClearance from "../../../images/tax-clearance.png";
import IncomeSourceVerification from "../../../images/income-verification.png";
import ThirdPartyIncomeVerification from "../../../images/third-party-income.png";
import { Link } from "react-router-dom";
import SEO from "../../../components/SEO";
const DocumentsBuilder = () => {
  return (
    <div className="py-20 font-montserrat">
      <SEO
        title={"Document Builder"}
        description={
          " Build your documents, and manage your applications with ease."
        }
        name={"Document Builder"}
        type={"website"}
        path={"/tools/documents"}
      />
      <h1 className="px-8 text-2xl sm:text-3xl font-bold text-center">
        Word Documents
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center max-w-7xl mx-auto pt-10 px-4">
        <div className="group flex flex-col items-center shadow-[4px_3px_14px_6px_rgba(204,204,204,0.77)] w-full h-full transform hover:-translate-y-2 transition-all duration-500">
          <Link to={"relationship-certificate"}>
            <div className="w-full">
              <div
                to={"income-source-certificate"}
                className="pt-5 block relative"
              >
                <img
                  src={RelationShipVerification}
                  className="mx-auto w-[80px] sm:w-[130px] object-contain"
                  alt="relationship"
                />
              </div>
            </div>
            <div className="px-2 py-5">
              <div className="group-hover:text-black transition-all duration-500 text-[#333] text-base sm:text-xl font-medium ">
                RelationShip Verification
              </div>
            </div>
          </Link>
        </div>
        <div className="group flex flex-col items-center shadow-[4px_3px_14px_6px_rgba(204,204,204,0.77)] w-full transform hover:-translate-y-2 transition-all duration-500">
          <Link to={"birth-certificate"}>
            <div className="w-full">
              <div className="pt-5 block relative">
                <img
                  src={BirthCertificateLogo}
                  className="mx-auto w-[70px] sm:w-[130px] object-contain"
                  alt="birth certificate logo"
                />
              </div>
            </div>
            <div className="px-2 py-5">
              <div className="group-hover:text-black transition-all duration-500 text-[#333] text-base sm:text-xl font-medium ">
                Birth Certificate
              </div>
            </div>
          </Link>
        </div>
        <div className="group flex flex-col items-center shadow-[4px_3px_14px_6px_rgba(204,204,204,0.77)] w-full transform hover:-translate-y-2 transition-all duration-500">
          <Link to={"address-verification"}>
            <div className="w-full">
              <div className="pt-5 block relative">
                <img
                  src={AddressVerification}
                  className="mx-auto w-[80px] sm:w-[130px] object-contain"
                  alt="address"
                />
              </div>
            </div>
            <div className="px-2 py-5">
              <div className="group-hover:text-black transition-all duration-500 text-[#333] text-base sm:text-xl font-medium ">
                Address Verification
              </div>
            </div>
          </Link>
        </div>
        <div className="group flex flex-col items-center shadow-[4px_3px_14px_6px_rgba(204,204,204,0.77)] w-full transform hover:-translate-y-2 transition-all duration-500">
          <Link to={"name-verification"}>
            <div className="w-full">
              <div className="pt-5 block relative">
                <img
                  src={NameVerification}
                  className="mx-auto w-[80px] sm:w-[130px] object-contain"
                  alt="name verification"
                />
              </div>
            </div>
            <div className="px-2 py-5">
              <div className="group-hover:text-black transition-all duration-500 text-[#333] text-base sm:text-xl font-medium ">
                Name Verification
              </div>
            </div>
          </Link>
        </div>

        <div className="group flex flex-col items-center shadow-[4px_3px_14px_6px_rgba(204,204,204,0.77)] w-full transform hover:-translate-y-2 transition-all duration-500">
          <Link to={"tax-clearance"}>
            <div className="w-full">
              <div className="pt-5 block relative">
                <img
                  src={TaxClearance}
                  className="mx-auto w-[100px] sm:w-[150px] object-contain"
                  alt="tax clearance"
                />
              </div>
            </div>
            <div className="px-2 py-5">
              <div className="group-hover:text-black transition-all duration-500 text-[#333] text-base sm:text-xl font-medium ">
                Tax Clearance
              </div>
            </div>
          </Link>
        </div>
        <div className="group flex flex-col items-center shadow-[4px_3px_14px_6px_rgba(204,204,204,0.77)] w-full transform hover:-translate-y-2 transition-all duration-500">
          <Link to={"income-source-certificate"}>
            <div className="w-full">
              <div className="pt-5 block relative">
                <img
                  src={IncomeSourceVerification}
                  className="mx-auto w-[75px] sm:w-[104px] object-contain"
                  alt="income source verification"
                />
              </div>
            </div>
            <div className="px-2 py-5">
              <div className="group-hover:text-black transition-all duration-500 text-[#333] text-base sm:text-xl font-medium ">
                Income Source Verification
              </div>
            </div>
          </Link>
        </div>
        <div className="group flex flex-col items-center shadow-[4px_3px_14px_6px_rgba(204,204,204,0.77)] w-full transform hover:-translate-y-2 transition-all duration-500">
          <Link to={"third-party-income-verification"}>
            <div className="w-full">
              <div className="pt-5 block relative">
                <img
                  src={ThirdPartyIncomeVerification}
                  className="mx-auto w-[100px] sm:w-[140px] object-contain"
                  alt="income source verification"
                />
              </div>
            </div>
            <div className="px-2 py-5">
              <div className="group-hover:text-black transition-all duration-500 text-[#333] text-base sm:text-xl font-medium ">
                Thirdparty Income Verification
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DocumentsBuilder;
