import React from "react";
import { Outlet } from "react-router-dom";
import CanadaTabs from "./navCanada";
import SEO from "../../../components/SEO";
const StudyInCanada = () => {
  return (
    <div className="max-w-7xl mx-auto p-4">
      <SEO
        title={"Study Abroad | Canada"}
        description={
          "Why Study in Canada.Canada is renowned for its high-quality education system, diverse cultural landscape, and welcoming environment. With numerous world-class universities and a strong emphasis on research and innovation, Canada provides students with exceptional opportunities for academic and personal growth."
        }
        name={"Study Abroad Canada"}
        type={"website"}
        path={"/study-abroad/canada"}
      />
      <CanadaTabs />
      <Outlet />
    </div>
  );
};

export default StudyInCanada;
