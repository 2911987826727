import React from "react";
import BranchHomePage from "../components/branch/ourBranchesHome";
import SEO from "../components/SEO";
const OurBranches = () => {
  return (
    <div>
      <SEO
        title={"Our Branches"}
        description={
          "Our Branches.A well-established head office located in the heart of Kathmandu. Known for its excellent service and friendly staff."
        }
        name={"Our Branches"}
        type={"website"}
        path={"/our-branches"}
      />
      <BranchHomePage />
    </div>
  );
};

export default OurBranches;
