import React from 'react';
import { Link } from 'react-router-dom';
import Destinations from '../../../data/destinationdata';

const OverviewUnitedKingdom = () => {
  // Find the destination object for the United Kingdom
  const uk = Destinations.find(dest => dest.name === "United Kingdom");

  // Sort universities by rank
  const sortedUniversities = uk.universities.sort((a, b) => {
    const rankA = parseInt(a.rank.split(' ')[0].replace(/\D/g, ''), 10);
    const rankB = parseInt(b.rank.split(' ')[0].replace(/\D/g, ''), 10);
    return rankA - rankB;
  });

  // Get the top 5 universities
  const topUniversities = sortedUniversities.slice(0, 5);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Overview</h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Why Study in the United Kingdom?</h2>
        <p className="text-gray-700">
          The United Kingdom is renowned for its rich history, diverse culture, and prestigious educational institutions. With a variety of world-class universities and cutting-edge research facilities, the UK offers an excellent academic environment for international students.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Top Universities</h2>
        <ul className="list-disc pl-5 text-gray-700 font-semibold">
          {topUniversities.map((university, index) => (
            <li key={index}>{university.name} ({university.rank})</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Admission Requirements</h2>
        <p className="text-gray-700">
          Admission to UK universities generally requires academic transcripts, proof of English language proficiency (e.g., IELTS or TOEFL), letters of recommendation, and a personal statement. Specific requirements may vary by program and institution.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Cost of Living</h2>
        <p className="text-gray-700">
          The cost of living in the UK varies by city, with London being one of the most expensive cities. Other cities like Manchester, Birmingham, and Edinburgh offer more affordable living options. Students should budget for tuition, accommodation, food, transportation, and other personal expenses.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Scholarships and Financial Aid</h2>
        <p className="text-gray-700">
          The UK offers numerous scholarships and financial aid opportunities for international students, including government-funded scholarships, university-specific awards, and private scholarships. It is important to research and apply for these opportunities early to maximize your chances of receiving financial support.
        </p>
      </section>

      <section>
        <h2 className="text-base lg:text-lg font-semibold mb-4">Post-Study Opportunities</h2>
        <p className="text-gray-700">
          International students in the UK can benefit from the Graduate Route, which allows them to stay and work in the UK for up to two years (or three years for Ph.D. graduates) after completing their studies. This provides valuable work experience and can be a pathway to longer-term employment.
        </p>
      </section>

      <Link to="/make-an-enquiry">
        <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
          Start Your Application
        </button>
      </Link>

    </div>
  );
}

export default OverviewUnitedKingdom;
