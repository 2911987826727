import React from "react";

const VisaStatusPage = () => {
  return (
    <div className="px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Check Your Visa Status</h1>
      <div className="w-full h-[600px] flex justify-center items-center">
        <iframe
          src="https://cmst.xyz/visa-status"
          title="Visa Status"
          className="w-full h-full border border-gray-300 rounded-lg"
          loading="lazy"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VisaStatusPage;
