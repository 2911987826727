import React from "react";
import { Outlet } from "react-router-dom";
import EuropeTabs from "./navEurope";
import SEO from "../../../components/SEO";
const StudyInEurope = () => {
  return (
    <div className="max-w-7xl mx-auto p-4">
      <SEO
        title={"Study Abroad | Europe"}
        description={
          "Why Study in Europe.Europe is home to some of the world's oldest and most prestigious universities. The continent offers a rich cultural heritage, diverse educational opportunities, and a variety of study programs in multiple languages. Studying in Europe provides an exceptional academic experience and a chance to explore a multitude of cultures."
        }
        name={"Study Abroad Europe"}
        type={"website"}
        path={"/study-abroad/europe"}
      />
      <EuropeTabs />
      <Outlet />
    </div>
  );
};

export default StudyInEurope;
