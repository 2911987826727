import React, { useState } from 'react';
import Modal from '../modal';
import toast, { Toaster } from 'react-hot-toast';
import axiosInstance from '../../../../api/axiosInstance'; // Assuming axiosInstance is already configured
import { useAuth } from '../../../../context/authContext';

const initialState = {
    name: "",
    address: "",
    phone_number: "",
    position: "",
    branch: null,
    email: "",
};

const AddTeamMember = ({ onClose, isOpen, members, setMembers, branchData }) => {
    const [values, setValues] = useState(initialState);
    const [photo, setPhoto] = useState({
        imagePreview: null,
        imageFile: null,
    })

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPhoto({
                imagePreview: URL.createObjectURL(file),
                imageFile: file
            })
        }
    }
    const { accessToken } = useAuth();

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            name: values.name,
            address: values.address,
            phone_number: values.phone_number,
            position: values.position,
            branch: Number(values.branch),
            email: values.email,
            photo: photo.imageFile
        }
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('address', data.address);
        formData.append('phone_number', data.phone_number);
        formData.append('position', data.position);
        formData.append('branch', data.branch);
        formData.append('email', data.email);
        formData.append('photo', data.photo);
        const toastLoading = toast.loading("Submitting...");

        try {


            // Attach the token to the headers for authorization
            const response = await axiosInstance.post(
                '/api/users/team/team-members/',
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`, // Attach the token in the Authorization header
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.status === 201) {
                toast.dismiss(toastLoading);
                toast.success("Successfully submitted");
                setValues(initialState);
                setPhoto({
                    imageFile: null,
                    imagePreview: null
                })
                const branchName = branchData.find(branch => branch.id === response.data.branch).branch_name;
                setMembers([...members, { ...response.data, branchName }]);

                // Close the modal after successful submission
                onClose();
            }
        } catch (error) {
            toast.dismiss(toastLoading);

            if (error.response && error.response.data) {
                const errors = error.response.data;
                for (const field in errors) {
                    if (errors.hasOwnProperty(field)) {
                        errors[field].forEach(error => toast.error(error + " in " + field));
                    }
                }
            } else {
                toast.error("Something went wrong!");
            }
        }
    };


    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg">
                <h2 className="text-2xl font-bold mb-6">Add Member Details</h2>
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name='name'
                            value={values.name}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="address">Address</label>
                        <input
                            type="text"
                            id="address"
                            name='address'
                            value={values.address}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="phone_number">Phone Number</label>
                        <input
                            type="text"
                            id="phone_number"
                            name='phone_number'
                            value={values.phone_number}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="position">Position</label>
                        <input
                            type="tel" // Changed to "tel" to avoid number increment/decrement buttons
                            id="position"
                            name='position'
                            value={values.position}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="branch">Branch</label>
                        <select name="branch" id="branch"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                            defaultValue={""}
                            required
                            onChange={(event) => {
                                setValues({ ...values, branch: event.target.value })
                            }}>
                            <option value="" disabled>Select Branch</option>
                            {
                                branchData.map(branch => (
                                    <option key={branch.id} value={branch.id}>{branch.branch_name}</option>
                                ))
                            }
                        </select>

                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="email">Email</label>
                        <input
                            type="email" // Changed to "tel" to avoid number increment/decrement buttons
                            id="email"
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>

                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="mapLink">Photo</label>
                        <input
                            type="file"
                            id="photo"
                            name='photo'
                            onChange={handleFileChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />

                    </div>
                    <div className='self-center w-full flex justify-center'>
                        {
                            photo.imagePreview ? (
                                <img src={photo.imagePreview} alt="preview" className="size-48 object-cover rounded-full mt-2" />
                            ) : (
                                <div className="size-48 bg-gray-400/50 text-gray-600 mt-2 flex items-center justify-center rounded-full" >
                                    <p>No Image Choosen</p>
                                </div>
                            )
                        }
                    </div>


                    <div className="col-span-1 md:col-span-2 flex justify-end gap-4 mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white rounded-full px-4 py-2 text-xs md:text-sm hover:bg-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`rounded-full px-4 py-2 text-xs md:text-sm bg-[#01132b] text-white flex items-center gap-2 hover:bg-blue-600 mt-4 sm:mt-0`}
                        >
                            Add Member
                        </button>
                    </div>
                </form>
            </div>
            <Toaster />
        </Modal>
    );
};

export default AddTeamMember;
