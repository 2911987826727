import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../api/axiosInstance';
import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from '../../../context/authContext';
import ReactQuill from 'react-quill';

const generateUniqueId = () => Math.floor(Math.random() * 1000000);

const EditOrAddArticlePage = () => {
  const { accessToken, userId } = useAuth();
  const { articleid } = useParams();
  const navigate = useNavigate();
  const [touched, setTouched] = useState({
    title: false,
    description: false,
  })

  const [article, setArticle] = useState({
    id: generateUniqueId(),
    title: '',
    author: userId,
    date: new Date().toISOString().split('T')[0],
    keywords: [],
    category: '',

    featured: false,
  });
  const [categories, setCategories] = useState('');
  const [keywords, setKeywords] = useState('');
  const [thumbnail, setThumbnail] = useState({
    file: null,
    imagePreview: null
  });
  const [featuredImage, setFeaturedImage] = useState({
    file: null,
    imagePreview: null
  });
  const [isFeatured, setIsFeatured] = useState(false);
  const [error, setError] = useState('');
  const [descriptionHtml, setDescriptionHtml] = useState('');

  const handleFeaturedImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setFeaturedImage({
        file,
        imagePreview: previewUrl
      });
    }
  }

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setThumbnail({
        file,
        imagePreview: previewUrl
      });
    }
  }

  useEffect(() => {
    if (articleid === 'new') {
    } else {
      // Fetch article logic
      axiosInstance.get("/api/users/articles/").then(response => {
        const fetchedArticle = response.data.data.find((art) => art.id === parseInt(articleid));
        if (fetchedArticle) {

          setArticle({
            title: fetchedArticle.title,
            description: fetchedArticle.description,
            author: fetchedArticle.author,
            date: fetchedArticle.date,
            keywords: fetchedArticle.keywords,
            category: fetchedArticle.category,
            featured: fetchedArticle.featured,

          });
          setFeaturedImage(prev => ({ ...prev, imagePreview: fetchedArticle.featured_image }));
          setThumbnail(prev => ({ ...prev, imagePreview: fetchedArticle.thumbnail }));
          setCategories(fetchedArticle.category);
          setKeywords(fetchedArticle.keywords);
          setIsFeatured(fetchedArticle.featured);
          setDescriptionHtml(fetchedArticle.description);
        } else {
          setError('Article not found. Redirecting to create a new article.');
          setTimeout(() => navigate(`/admin/dashboard/edit/article/${articleid}`), 2000);
        }
      }).catch(error => {
        console.log(error)
      })


    }
  }, [articleid, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle((prevArticle) => ({ ...prevArticle, [name]: value }));
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setCategories(newCategory);
    setArticle((prevArticle) => ({
      ...prevArticle,
      category: newCategory.split(/[,;]\s*/).map((cat) => cat.trim()).join(', '),
    }));
  };

  const handleKeywordsChange = (e) => {
    const newKeywords = e.target.value;
    setKeywords(newKeywords);
    setArticle((prevArticle) => ({
      ...prevArticle,
      keywords: newKeywords.split(/[,;]\s*/).map((kw) => kw.trim()).join(", "),
    }));
  };

  const handleFeaturedChange = (e) => {
    const isFeatured = e.target.value === 'true';
    setIsFeatured(isFeatured);
    setArticle((prevArticle) => ({ ...prevArticle, featured: isFeatured }));
  };

  const prepareFormData = () => {
    const formData = new FormData();
    formData.append('title', article.title);
    formData.append('description', descriptionHtml);
    formData.append('date', article.date);
    formData.append('keywords', article.keywords);
    formData.append('category', article.category);
    formData.append('thumbnail', thumbnail.file);
    formData.append('featured_image', featuredImage.file);
    formData.append('featured', article.featured);
    formData.append('author', article.author);

    return formData;
  }

  const handleSave = async () => {
    const toastLoading = toast.loading("Saving...");
    const formData = prepareFormData();

    try {
      const response = await axiosInstance.post('/api/users/articles/', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.data.status === "success") {
        toast.dismiss(toastLoading);
        setArticle({
          id: null,
          title: '',
          author: 1,
          date: new Date().toISOString().split('T')[0],
          keywords: [],
          category: '',
          thumbnail: "",
          featured_image: "",
          featured: false,
        });
        setThumbnail({ file: null, imagePreview: "" });
        setFeaturedImage({ file: null, imagePreview: "" });
        setCategories('');
        setKeywords('');
        setDescriptionHtml('');

        toast.success("Article Posted successfully!");
        navigate(`/admin/dashboard/articles/`);
      }

    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const errors = error.response.data.message;
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            errors[field].forEach(error => toast.error(error + " in " + field));
          }
        }
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      toast.dismiss(toastLoading)
    }
  };

  const handleEditClick = async () => {
    const toastLoading = toast.loading("Please Wait...");
    const formData = new FormData();
    formData.append('title', article.title);
    formData.append('description', descriptionHtml);
    formData.append('date', article.date);
    formData.append('keywords', article.keywords);
    formData.append('category', article.category);
    if (thumbnail.file) {
      formData.append('thumbnail', thumbnail.file);
    }
    if (featuredImage.file) {
      formData.append('featured_image', featuredImage.file);
    }
    formData.append('featured', article.featured);
    formData.append('author', article.author);


    try {
      const response = await axiosInstance.patch(`/api/users/articles/${articleid}/`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.data.status === "success") {
        toast.dismiss(toastLoading)
        toast.success("Article updated successfully");
        navigate(`/admin/dashboard/articles/`);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      toast.dismiss(toastLoading)
    }

  }

  const handleCancel = () => {
    navigate('/admin/dashboard/articles/');
  };

  const handleRichTextChange = (value) => {
    setDescriptionHtml(value);
  };

  if (!article && !error) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="p-6 max-w-full mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">
        {articleid === 'new' ? 'Add New Article' : 'Edit Article'}
      </h1>

      <div>
        <h2 className="text-xl font-semibold mb-4 text-gray-700">Article Details</h2>
        <form className="space-y-4 bg-white p-6 rounded-lg shadow-md" encType='multipart/form-data'>

          <div>
            <label className="block text-sm font-medium mb-2 text-gray-600" htmlFor="title">Title&nbsp;{
              !article.title && touched.title ? <span className="text-red-500">(Title is required)*</span> : null
            }</label>
            <input
              type="text"
              id="title"
              name="title"
              value={article.title}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onBlur={() => setTouched(prev => ({ ...prev, title: true }))}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-600" htmlFor="description">Description&nbsp;{
              !descriptionHtml && touched.description ? <span className="text-red-500">(Description is required)*</span> : null
            }</label>
            {/* <TinyMCEEditorComponent
              // Pass the descriptionHtml state as the value
              value={descriptionHtml}
              onChange={(content) => {
                setDescriptionHtml(content);
              }}
              placeholder={"Type Something......"}
              onBlur={()=> setTouched(prev => ({...prev, description: true}))}
            /> */}

            <div className="border bg-gray-100 rounded-md mt-2 h-[300px]">
              <div className="grid gap-2 grid-cols-1 bg-white">

                <div className="w-full h-full pb-10">

                  <ReactQuill
                    value={descriptionHtml}
                    onChange={(value) => handleRichTextChange(value)}
                    className="rounded-md w-full h-[260px]"
                    theme="snow"
                    name="description"
                    onBlur={() => setTouched(prev => ({ ...prev, description: true }))}
                  />
                </div>

              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-600" htmlFor="category">Category</label>
            <input
              type="text"
              id="category"
              name="category"
              value={categories}
              onChange={handleCategoryChange}
              className="w-full border border-gray-300 rounded-lg p-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <small className="text-gray-500">Separate categories with commas or semicolons.</small>
          </div>
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-600" htmlFor="keywords">Keywords</label>
            <input
              type="text"
              id="keywords"
              name="keywords"
              value={keywords}
              onChange={handleKeywordsChange}
              className="w-full border border-gray-300 rounded-lg p-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <small className="text-gray-500">Separate keywords with commas or semicolons.</small>
          </div>
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-600" htmlFor="thumbnail">Thumbnail</label>
            <input
              type="file"
              id="thumbnail"
              name="thumbnail"
              onChange={handleThumbnailChange}
              className="w-full border border-gray-300 rounded-lg p-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className='w-[300px] h-[300px] rounded-lg overflow-hidden'>
              {
                thumbnail.imagePreview ? <img src={thumbnail.imagePreview} alt="thumbnail" className='w-full h-full object-cover' /> : <div className='w-full h-full bg-gray-200 flex justify-center items-center'>
                  <p>Image not selected</p>
                </div>
              }
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-600" htmlFor="featuredImage">Featured Image</label>
            <input
              type="file"
              id="featuredImage"
              name="featuredImage"
              onChange={handleFeaturedImageChange}
              className="w-full border border-gray-300 rounded-lg p-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
              accept='image/*'
            />
            <div className='w-[300px] h-[300px] rounded-lg overflow-hidden'>
              {
                featuredImage.imagePreview ? <img src={featuredImage.imagePreview} alt="featured" className='w-full h-full object-cover' /> : <div className='w-full h-full bg-gray-200 flex justify-center items-center'>
                  <p>Image not selected</p>
                </div>
              }
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-600">Featured</label>
            <div className="flex gap-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="featured"
                  value="true"
                  checked={isFeatured === true}
                  onChange={handleFeaturedChange}
                  className="form-radio text-blue-500"
                />
                <span className="text-gray-700">Yes</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="featured"
                  value="false"
                  checked={isFeatured === false}
                  onChange={handleFeaturedChange}
                  className="form-radio text-blue-500"
                />
                <span className="text-gray-700">No</span>
              </label>
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">

            {
              articleid === 'new' ? <>
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
              </> : <>
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Edit
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
              </>

            }

          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default EditOrAddArticlePage;
