import React, { useEffect, useState } from 'react'
import axiosInstance from '../api/axiosInstance'
import SEO from '../components/SEO';


const AboutUs = () => {

    return (
        <div className="bg-gray-50 min-h-screen py-12">
            <SEO
                title={"About Us | AAEC Global"}
                description={"We are a leading educational consultancy firm, dedicated to guiding students towards academic success. With years of experience in the education sector, we provide personalized support, helping students find the best institutions and programs globally"}
                name={"About Us"}
                type={"website"}
                path={"/about-us"}
            />
            <div className="container mx-auto px-6 lg:px-12">
                <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    About Us
                </h1>
                <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-8 lg:space-y-0 lg:space-x-12">
                    <div className="lg:w-1/2">
                        <img src="logo.png" alt="Educational Consultancy" className="rounded-lg shadow-lg w-full" />
                    </div>
                    <div className="lg:w-1/2">
                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                            Who We Are
                        </h2>
                        <p className="text-gray-600 text-lg leading-relaxed mb-6">
                            We are a leading educational consultancy firm, dedicated to guiding students
                            towards academic success. With years of experience in the education sector,
                            we provide personalized support, helping students find the best institutions
                            and programs globally.
                        </p>
                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                            Our Mission
                        </h2>
                        <p className="text-gray-600 text-lg leading-relaxed mb-6">
                            Our mission is to empower students by providing expert advice and comprehensive
                            solutions for their academic journey. We believe that every student deserves
                            access to the best education opportunities and the resources to thrive.
                        </p>
                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                            Why Choose Us
                        </h2>
                        <ul className="list-disc pl-6 text-gray-600 text-lg leading-relaxed">
                            <li>
                                Expert Guidance from Experienced Advisors
                            </li>
                            <li>
                                Comprehensive Support for International Students
                            </li>
                            <li>
                                Partnerships with Top Educational Institutions
                            </li>
                            <li>
                                Tailored Solutions to Meet Individual Needs
                            </li>
                        </ul>
                    </div>
                </div>

                <CardWrapper />
            </div>
        </div>
    )
}


// Card Component
const Card = ({ data }) => {
    const { name, address, phone_number, position, branch, email, photo } = data;

    return (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 transform hover:scale-105 transition-transform duration-300">
            <img src={photo} alt={`${name}'s`} className="w-full h-48 object-cover" />
            <div className="p-4">
                <h2 className="text-2xl font-bold text-gray-800 mb-2">{name}</h2>
                <h3 className="text-lg text-blue-600 mb-2">{position}</h3>
                <div className="text-sm text-gray-600 mb-3">
                    <p><span className="font-semibold">Branch:</span> {branch}</p>
                    <p><span className="font-semibold">Address:</span> {address}</p>
                    <p><span className="font-semibold">Phone:</span> {phone_number}</p>
                    <p><span className="font-semibold">Email:</span> <a href={`mailto:${email}`} className="text-blue-500 underline">{email}</a></p>
                </div>
            </div>
        </div>
    );
};


// Card Wrapper Component
const CardWrapper = () => {
    const [cardsData, setCardsData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axiosInstance.get("/api/users/team/team-members/").then(data => {
            setCardsData(data.data.results);
        }).catch((error) => {
            setError(error.response.data.message || "Failed to fetch")
        }).finally(() => {
            setLoading(false);
        })
    }, []);


    return (
        <div className='pt-12'>
            <h3 className="text-3xl font-bold text-gray-800 mb-8">Our Teams</h3>
            {
                loading && !error && cardsData.length === 0 ? (
                    <div className="flex justify-center items-center h-96 text-gray-500 text-lg md:text-2xl col-span-full">
                        <p>Loading Teams ....</p>
                    </div>
                ) : error && !loading && cardsData.length === 0 ? (
                    <div className="flex justify-center items-center h-96 text-gray-500 text-lg md:text-2xl col-span-full">
                        <p>{error}</p>
                    </div>
                ) : !error && !loading && cardsData.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {
                            cardsData.map((cardData, index) => (
                                <Card key={index} data={cardData} />
                            ))
                        }
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-96 text-gray-500 text-lg md:text-2xl col-span-full">
                        <p>No Teams Found.</p>
                    </div>
                )
            }

        </div>
    );
};


export default AboutUs