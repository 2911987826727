import React from 'react';
import { FaFileAlt, FaClipboardList } from 'react-icons/fa'; // Importing icons from react-icons
import { Link } from 'react-router-dom'; // Importing Link for navigation

const ToolsPage = () => {
  return (
    <div className="py-20 max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-gray-900 text-center mb-2 text-pretty">Tools for Your Academic Journey</h1>

      <p className="text-lg text-gray-500 text-center mb-8">
        Enhance your application process with our handy tools. Build your CV, create professional documents, and manage your applications with ease.
      </p>

      <div className="py-5 flex flex-col md:flex-row justify-center items-center gap-8">
        {/* CV Builder */}
        <div className="flex flex-col items-center text-center bg-gray-100 p-6 rounded-lg shadow-lg">
          <FaFileAlt className="text-4xl text-blue-500 mb-4" />
          <h2 className="text-xl font-semibold mb-2">CV Builder</h2>
          <p className="text-gray-700 mb-4 text-pretty">
            Create a professional CV tailored to your field of study and career goals. Our CV builder provides customizable templates to showcase your achievements effectively.
          </p>
          <Link to="/tools/cv-builder" className="bg-[#01132b] hover:bg-[#1a2b40] hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">
            Build Your CV
          </Link>
        </div>

        {/* Document Creator */}
        <div className="flex flex-col items-center text-center bg-gray-100 p-6 rounded-lg shadow-lg">
          <FaClipboardList className="text-4xl text-green-500 mb-4" />
          <h2 className="text-xl font-semibold mb-2">Document Creator</h2>
          <p className="text-gray-700 mb-4 text-pretty">
            Easily create and manage your academic documents, including personal statements, cover letters, and other essential files. Our tool offers a range of templates and editing options.
          </p>
          <Link to="/tools/documents" className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold py-2 px-4 rounded">
            Create Documents
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ToolsPage;
