import React from 'react';
import { Link } from 'react-router-dom';
import Destinations from '../../../data/destinationdata';

const OverviewNewZealand = () => {
  // Find the destination object for New Zealand
  const newZealand = Destinations.find(dest => dest.name === "New Zealand");

  // Sort universities by rank
  const sortedUniversities = newZealand.universities.sort((a, b) => {
    const rankA = parseInt(a.rank.split(' ')[0].replace(/\D/g, ''), 10);
    const rankB = parseInt(b.rank.split(' ')[0].replace(/\D/g, ''), 10);
    return rankA - rankB;
  });

  // Get the top 5 universities
  const topUniversities = sortedUniversities.slice(0, 5);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Overview</h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Why Study in New Zealand?</h2>
        <p className="text-gray-700">
          New Zealand offers a unique educational experience with its high-quality institutions, stunning landscapes, and welcoming atmosphere. Known for its innovative teaching methods and research opportunities, New Zealand provides students with a comprehensive and enriching academic environment.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Top Universities</h2>
        <ul className="list-disc pl-5 text-gray-700 font-semibold">
          {topUniversities.map((university, index) => (
            <li key={index}>{university.name} ({university.rank})</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Admission Requirements</h2>
        <p className="text-gray-700">
          Admission to New Zealand universities typically requires academic transcripts, proof of English language proficiency (e.g., IELTS or TOEFL), letters of recommendation, and a personal statement. Specific requirements may vary depending on the program and institution.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Cost of Living</h2>
        <p className="text-gray-700">
          The cost of living in New Zealand varies by city. Major cities like Auckland and Wellington are more expensive, while smaller towns offer more affordable options. Students should budget for tuition, accommodation, food, transportation, and other personal expenses.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Scholarships and Financial Aid</h2>
        <p className="text-gray-700">
          New Zealand offers various scholarships and financial aid opportunities for international students, including government-funded scholarships, university-specific awards, and private scholarships. It is important to research and apply for these opportunities early to maximize your chances of receiving financial support.
        </p>
      </section>

      <section>
        <h2 className="text-base lg:text-lg font-semibold mb-4">Post-Study Opportunities</h2>
        <p className="text-gray-700">
          Graduates from New Zealand can apply for the Post-Study Work Visa, which allows them to work in New Zealand for up to three years, depending on the level of their qualification and the duration of their studies. This provides valuable work experience and can be a pathway to permanent residency.
        </p>
      </section>

      <Link to="/make-an-enquiry">
        <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
          Start Your Application
        </button>
      </Link>

    </div>
  );
};

export default OverviewNewZealand;
