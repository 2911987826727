import { useState } from "react";
import { useCV } from "../../../context/cvbuilder/hooks/useCV";

const PersonalDetails = () => {
    const { personalDetails, setPersonalDetails } = useCV();
    const [personalDetailsTouched, setPersonalDetailsTouched] = useState({
        fullName: false,
        city: false,
        email: false,
        country: false,
        phone: false,
    })

    const handleChange = (e) => {
        setPersonalDetails({ ...personalDetails, [e.target.name]: e.target.value });
    };

    const isValidName = /^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/.test(personalDetails.fullName);
    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(personalDetails.email);
    const isValidPhone = /^\+?[0-9]{1,4}?[ -.]?\(?[0-9]{1,4}?\)?[ -.]?[0-9]{1,4}[ -.]?[0-9]{1,4}[ -.]?[0-9]{1,9}$/.test(personalDetails.phone);
    return (
        <div>
            <div className="p-4 border bg-gray-100 rounded-md">
                <h2 className="text-xl md:text-2xl font-semibold mb-10">Personal Information</h2>
                <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {/* Full Name */}
                    <div className="mb-4 w-full">
                        <label className="block text-[15px] md:text-lg font-medium">Full Name <span className="text-red-500">*&nbsp;
                            {
                                personalDetailsTouched.fullName && personalDetails.fullName.length === 0 ? "( Full Name is required. )" : personalDetailsTouched.fullName && !isValidName && "( Enter a valid name )"
                            }
                        </span></label>
                        <input
                            type="text"
                            name="fullName"
                            value={personalDetails.fullName}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border rounded-md"
                            placeholder=""
                            aria-label='Full Name'
                            autoComplete='on'
                            onBlur={() => setPersonalDetailsTouched({ ...personalDetailsTouched, fullName: true })}
                        />
                    </div>

                    {/* City */}
                    <div className="mb-4 w-full">
                        <label className="block text-[15px] md:text-lg font-medium">City <span className="text-red-500">*&nbsp;
                            {
                                personalDetailsTouched.city && personalDetails.city.length === 0 && "( City is required )"
                            }
                        </span></label>
                        <input
                            type="text"
                            name="city"
                            value={personalDetails.city}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border rounded-md"
                            aria-label='city'
                            autoComplete='on'
                            onBlur={() => setPersonalDetailsTouched({ ...personalDetailsTouched, city: true })}
                        />
                    </div>

                    {/* Email */}
                    <div className="mb-4 w-full">
                        <label className="block text-[15px] md:text-lg font-medium">Email <span className="text-red-500">*&nbsp;
                            {
                                personalDetailsTouched.email && personalDetails.email.length === 0 ? "( Email is required )" : personalDetailsTouched.email && !isValidEmail && "( Please enter a valid email )"
                            }
                        </span></label>
                        <input
                            type="email"
                            name="email"
                            value={personalDetails.email}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border rounded-md"
                            aria-label='email'
                            autoComplete='on'
                            onBlur={() => setPersonalDetailsTouched({ ...personalDetailsTouched, email: true })}
                        />
                    </div>

                    {/* Country */}
                    <div className="mb-4 w-full">
                        <label className="block text-[15px] md:text-lg font-medium">Country <span className="text-red-500">*&nbsp;
                            {
                                personalDetailsTouched.country && personalDetails.country.length === 0 && "( Country is required )"
                            }
                        </span></label>
                        <input
                            type="text"
                            name="country"
                            value={personalDetails.country}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border rounded-md"
                            aria-label='country'
                            autoComplete='on'
                            onBlur={() => setPersonalDetailsTouched({ ...personalDetailsTouched, country: true })}
                        />
                    </div>

                    {/* Phone */}
                    <div className="mb-4 w-full">
                        <label className="block text-[15px] md:text-lg font-medium">Phone Number <span className="text-red-500">*&nbsp;
                            {
                                personalDetailsTouched.phone && personalDetails.phone.length === 0 ? "( Phone is required )" : personalDetailsTouched.phone && !isValidPhone && "( Enter a valid Phone )"
                            }
                        </span></label>
                        <input
                            type="tel"
                            name="phone"
                            value={personalDetails.phone}
                            onChange={handleChange}
                            aria-label="phone number"
                            autoComplete="on"
                            className="w-full mt-1 p-2 border rounded-md"
                            onBlur={() => setPersonalDetailsTouched({ ...personalDetailsTouched, phone: true })}

                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PersonalDetails