import React from 'react';
import Destination from '../components/destination';
import SEO from '../components/SEO';

function StudyAbroadDisplay() {
  return (
    <div className='max-w-7xl mx-auto px-4 py-8'>
      <SEO
        title={"Study Abroad | AAEC Global"}
        description={"Work With The Best Study Abroad Consultants In Nepal. Studying abroad can be a life-changing endeavor both in terms of exploring the world and career success. AAEC Global helps apprentices make the most of their time & investment with our proven approach to studying abroad in developed countries like Australia, USA, UK, Canada, New Zealand, and Europe. Being a leading study abroad consultant in Nepal, we offer competent career counseling & career planning advice. Our career counsellors can help you select an appropriate study abroad program based on your career choice. Rest assured that we will help you in making an informed career decision."}
        name={"Study Abroad"}
        type={"website"}
        path={"/study-abroad"}
      />
      <section className='mb-8'>
        <h1 className='text-3xl font-bold mb-4'>
          Work With The Best Study Abroad Consultants In Nepal
        </h1>
        <p className='mb-4'>
          Studying abroad can be a life-changing endeavor both in terms of exploring the world and career success. AAEC Global helps apprentices make the most of their time & investment with our proven approach to studying abroad in developed countries like Australia, USA, UK, Canada, New Zealand, and Europe. Being a leading study abroad consultant in Nepal, we offer competent career counseling & career planning advice. Our career counsellors can help you select an appropriate study abroad program based on your career choice. Rest assured that we will help you in making an informed career decision.
        </p>
        <h2 className='text-2xl font-semibold mb-2'>
          We Work For You, Not Universities:
        </h2>
        <p className='mb-4'>
          Unlike most study abroad consultants in Nepal we work for you not for any university. We don’t rely on commissions from universities – we’re dedicated to advice on what’s best for your career. As we’re not financed by a bank or a VC we’ve no pressure to make sales. And this freedom lets us think freely and provide solutions that will work best for your career.
        </p>
        <p className='mb-4'>
          We understand the pain, aspirations, and troubles families go through to give their child proper education. And it is our promise that no parent or student will be disappointed with the way we manage our study abroad consultancy.
        </p>
        <h2 className='text-2xl font-semibold mb-2'>
          Free Online Study Abroad Counseling:
        </h2>
        <p className='mb-4'>
          As we’re a global leader in international student services we love to help students study in English speaking nations like the US, UK, Canada, Australia, and New Zealand. We offer free online study abroad counseling sessions, and strive to make the process of applying to study abroad as hassle-free as possible. We’ve a crew of knowledgeable counsellors to assist students with their university & course selection, application process, offer letters, visa procedure, and pre-departure briefings. We also offer thorough guidance in all immigration procedures. So far, we’ve helped uncountable students find the right university to pursue their dream course. And now it is your turn!
        </p>
        <p>
          Let AAEC Global simplify study abroad for you! Schedule a meeting with one of our study abroad consultants in Kathmandu, Nepal now!
        </p>
      </section>
      <Destination />
    </div>
  );
}

export default StudyAbroadDisplay;
