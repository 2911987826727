import React, { useRef } from 'react'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import ReactQuill from 'react-quill';
import { FaPlus } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";


// table 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//modal
import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'

import { useCV } from "../../../context/cvbuilder/hooks/useCV"

// Initial state for an empty work experience
const initialWorkExperience = {
    jobTitle: '',
    employer: '',
    cityTown: '',
    country: '',
    startDate: null,
    endDate: null,
    workDetails: null,
    currentlyWorking: false,
};

const WorkExperience = () => {
    const { workExperiences, setWorkExperiences } = useCV();
    const [workExperience, setWorkExperience] = useState(initialWorkExperience);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [editId, setEditId] = React.useState(null);
    const handleOpen = () => {
        setOpen(true);
        setWorkExperience(initialWorkExperience);
    };
    const [quillKey, setQuillKey] = useState(0); // Add a key for ReactQuill
    const quillRef1 = useRef(null);  // For first modal
    const quillRef2 = useRef(null);  // For second modal

    const handleWorkExperienceChange = (e) => {
        setWorkExperience({ ...workExperience, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        setWorkExperiences(prev => ([...prev, { ...workExperience }]))
        setQuillKey(prevKey => prevKey + 1); // Update the key to force ReactQuill reset
        setWorkExperience({
            jobTitle: '',
            employer: '',
            cityTown: '',
            country: '',
            startDate: null,
            endDate: null,
            workDetails: null,
            currentlyWorking: false,
        })
        setOpen(false);

    }

    const handleEdit = (index) => {
        const newData = { ...workExperiences[index] }; // Spread to avoid mutation

        setTimeout(() => {
            setWorkExperience(newData);
        }, 100);
    }

    const handleEditSave = (index) => {
        const newData = workExperiences.map((data, i) => {
            if (i === index) {
                return {
                    ...workExperience
                }
            }
            return data;
        })

        setWorkExperiences(newData);
        setOpen2(false);
        setWorkExperience({
            jobTitle: '',
            employer: '',
            cityTown: '',
            country: '',
            startDate: null,
            endDate: null,
            workDetails: null,
            currentlyWorking: false,
        });
        setQuillKey(prev => prev + 1);
    }

    const handleDateChange = (newDate, fieldName) => {
        setWorkExperience({ ...workExperience, [fieldName]: newDate });
    };


    // Handle rich text change with a specific editor
    const handleRichTextChange = (field, value, quillRef) => {

        // Access the specific Quill instance through the passed ref
        const quillInstance = quillRef.current?.getEditor();
        if (quillInstance) {

            // You can now use deltaFormat for saving or other purposes
            setWorkExperience(prev => ({ ...prev, [field]: value }));
        } else {
            console.error('Quill editor is not available');
        }
    };


    const handleDelete = (index) => {
        const newData = workExperiences.filter((_, i) => i !== index);
        setWorkExperiences(newData);
    }

    const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            marginTop: "4px",
            '& .MuiInputBase-input': {
                paddingLeft: '8px',
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '8px',
                backgroundColor: "white",
            }
        }
    }));

    const CustomTable = styled(Table)(({ theme }) => ({
        "& .MuiTableCell-root": {
            fontSize: "18px",
            [theme.breakpoints.down("md")]: {
                fontSize: "15px"
            }
        },
        "& .MuiTableHead-root tr th": {
            fontWeight: "bolder"
        }
    }))


    return (
        <>
            <div className="p-4 border bg-gray-100 rounded-md mt-2">
                <div className='mb-10 flex justify-between'>
                    <h2 className="text-xl md:text-2xl font-semibold">Work Experience</h2>
                    <button onClick={handleOpen} className='px-1.5 lg:px-3 py-1 md:py-1.5 lg:py-2 bg-[#01132b] hover:bg-[#1a2b40] text-white rounded-sm flex items-center text-sm md:text-base'><span className='inline-block mr-4 hover:scale-105 focus:scale-95' ><FaPlus /></span>Add Record</button>
                </div>

                <TableContainer component={Paper}>
                    <CustomTable sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Employer</TableCell>
                                <TableCell>City/Town</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>Job Title</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {workExperiences.length !== 0 && workExperiences && workExperiences.map((row, id) => (
                                <TableRow
                                    key={id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.employer}
                                    </TableCell>
                                    <TableCell>
                                        {row.cityTown}
                                    </TableCell>
                                    <TableCell >{row.country}</TableCell>
                                    <TableCell >{row.jobTitle}</TableCell>
                                    <TableCell>{new Date(row.startDate).getFullYear() + " " + new Date(row.startDate).toLocaleDateString('default', { month: "short" })}</TableCell>
                                    <TableCell>{!row.currentlyWorking ? new Date(row.endDate).getFullYear() + " " + new Date(row.endDate).toLocaleDateString('default', { month: "short" }) : "Present"}</TableCell>

                                    <TableCell>
                                        <button onClick={() => {
                                            setOpen2(true)
                                            setEditId(id);
                                            handleEdit(id);
                                        }} className='px-3 py-2 mr-2 bg-blue-500 text-white rounded-sm'><FaEdit /></button>
                                        <button onClick={() => handleDelete(id)} className='px-3 py-2 bg-red-500 text-white rounded-sm'><MdDelete /></button>

                                    </TableCell>
                                </TableRow>
                            ))

                            }
                        </TableBody>

                    </CustomTable>
                </TableContainer>
                {
                    workExperiences.length === 0 && <div className='bg-white py-4'>
                        <h1 className='text-base lg:text-xl text-center'>Your work experiences will be shown here.</h1>
                    </div>
                }

                {/* Add modal */}
                <Dialog open={open} onClose={setOpen} className="relative z-50">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full w-full max-w-[1200px] mx-auto items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <h1 className='text-xl md:text-2xl font-bold mb-5 md:mb-10'>Add Work Experience</h1>
                                    {/* add modal   */}
                                    <div className="grid gap-2 grid-cols-1 md:grid-cols-3">
                                        {/* Full Name */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">Job Title <span className="text-red-500">*&nbsp;

                                            </span></label>
                                            <input
                                                type="text"
                                                name="jobTitle"
                                                value={workExperience.jobTitle}
                                                onChange={handleWorkExperienceChange}
                                                className="w-full mt-1 p-2 border rounded-md"
                                                placeholder=""
                                                aria-label='Job Title'
                                                autoComplete='on'
                                            />
                                        </div>

                                        {/* Employer */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">Employer <span className="text-red-500">*&nbsp;

                                            </span></label>
                                            <input
                                                type="text"
                                                name="employer"
                                                value={workExperience.employer}
                                                onChange={handleWorkExperienceChange}
                                                aria-label='employer'
                                                autoComplete='on'
                                                className="w-full mt-1 p-2 border rounded-md"
                                            />
                                        </div>

                                        {/* City/Town */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">City/Town <span className="text-red-500">*&nbsp;

                                            </span></label>
                                            <input
                                                type="text"
                                                name="cityTown"
                                                value={workExperience.cityTown}
                                                onChange={handleWorkExperienceChange}
                                                aria-label='city'
                                                autoComplete='on'
                                                className="w-full mt-1 p-2 border rounded-md"
                                            />
                                        </div>

                                        {/* Country */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">Country <span className="text-red-500">*&nbsp;

                                            </span></label>
                                            <input
                                                type="text"
                                                name="country"
                                                value={workExperience.country}
                                                onChange={handleWorkExperienceChange}
                                                aria-label='country'
                                                autoComplete='on'
                                                className="w-full mt-1 p-2 border rounded-md"
                                            />
                                        </div>

                                        {/* Start Date */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">Start Date <span className="text-red-500">*&nbsp;

                                            </span></label>


                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <CustomDatePicker
                                                    views={['year', 'month']}
                                                    sx={{ width: "100%" }}
                                                    inputFormat="MM/yyyy"
                                                    value={workExperience.startDate}
                                                    name='startDate'
                                                    aria-label='start date'
                                                    autoComplete='off'
                                                    onChange={(newDate) => handleDateChange(newDate, 'startDate')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <div className='ml-1 mt-2'>
                                                <input type="checkbox" className='scale-110 md:scale-150' checked={workExperience.currentlyWorking} onChange={() => {
                                                    setWorkExperience({ ...workExperience, currentlyWorking: !workExperience.currentlyWorking, endDate: workExperience.currentlyWorking ? null : workExperience.endDate })

                                                }} name="" id="" /> <span className='text-[15px] md:text-lg inline-block mx-2'>I currently work here.</span>
                                            </div>
                                        </div>

                                        {/* End Date */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">End Date <span className="text-red-500">*&nbsp;

                                            </span></label>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <CustomDatePicker
                                                    sx={{ width: "100%" }}
                                                    disabled={workExperience.currentlyWorking}
                                                    views={['year', 'month']}
                                                    inputFormat="MM/yyyy"
                                                    value={workExperience.endDate}
                                                    name='endDate'
                                                    aria-label='end date'
                                                    autoComplete='off'
                                                    onChange={(newDate) => handleDateChange(newDate, 'endDate')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <div className="mb-4 w-full h-[200px]">
                                        <label className="block text-[15px] md:text-lg font-medium">Work Details</label>
                                        <ReactQuill
                                            key={quillKey}
                                            ref={quillRef1}
                                            value={workExperience.workDetails}
                                            id='2'
                                            onChange={(value) => handleRichTextChange("workDetails", value, quillRef1)}
                                            className="bg-white rounded-md h-[140px]"
                                            theme="snow"
                                            name="workDetails"
                                            aria-label='work details'
                                            autoComplete='off'
                                        />
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 text-xl mt-5">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleSave()
                                        }}
                                        className="inline-flex w-full justify-center rounded-md bg-[#01132b] hover:bg-[#1a2b40] px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 sm:ml-3 sm:w-auto"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        data-autofocus
                                        onClick={() => setOpen(false)}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>


                {/* Edit modal */}
                <Dialog open={open2} onClose={setOpen2} className="relative z-50">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full w-full max-w-[1200px] mx-auto items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <h1 className='text-xl md:text-2xl font-bold mb-10'>Edit Work Experience</h1>
                                    {/* add modal   */}
                                    <div className="grid gap-2 grid-cols-1 md:grid-cols-3">
                                        {/* Job Title */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">Job Title <span className="text-red-500">*&nbsp;
                                            </span></label>
                                            <input
                                                type="text"
                                                name="jobTitle"
                                                value={workExperience.jobTitle}
                                                onChange={handleWorkExperienceChange}
                                                className="w-full mt-1 p-2 border rounded-md"
                                                aria-label='job title'
                                                autoComplete='on'
                                                placeholder=""
                                            />
                                        </div>

                                        {/* Employer */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">Employer <span className="text-red-500">*&nbsp;
                                            </span></label>
                                            <input
                                                type="text"
                                                name="employer"
                                                value={workExperience.employer}
                                                onChange={handleWorkExperienceChange}
                                                aria-label='employer'
                                                autoComplete='on'
                                                className="w-full mt-1 p-2 border rounded-md"
                                            />
                                        </div>
                                        {/* City/Town */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">City/Town <span className="text-red-500">*&nbsp;
                                            </span></label>
                                            <input
                                                type="text"
                                                name="cityTown"
                                                value={workExperience.cityTown}
                                                aria-label='city'
                                                autoComplete='on'
                                                onChange={handleWorkExperienceChange}
                                                className="w-full mt-1 p-2 border rounded-md"
                                            />
                                        </div>
                                        {/* Country */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">Country <span className="text-red-500">*&nbsp;
                                            </span></label>
                                            <input
                                                type="text"
                                                name="country"
                                                value={workExperience.country}
                                                aria-label='country'
                                                autoComplete='on'
                                                onChange={handleWorkExperienceChange}
                                                className="w-full mt-1 p-2 border rounded-md"
                                            />
                                        </div>
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">Start Date <span className="text-red-500">*&nbsp;
                                            </span></label>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <CustomDatePicker
                                                    views={['year', 'month']}
                                                    sx={{ width: "100%" }}
                                                    inputFormat="MM/yyyy"
                                                    value={workExperience.startDate}
                                                    name='startDate'
                                                    aria-label='start date'
                                                    autoComplete='off'
                                                    onChange={(newDate) => handleDateChange(newDate, 'startDate')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <div className='ml-1 mt-2'>
                                                <input type="checkbox" className='scale-110 md:scale-150' checked={workExperience.currentlyWorking} onChange={() => {
                                                    setWorkExperience({ ...workExperience, currentlyWorking: !workExperience.currentlyWorking, endDate: workExperience.currentlyWorking ? null : workExperience.endDate })

                                                }} name="" id="" /> <span className='text-[15px] md:text-lg inline-block mx-2'>I currently work here.</span>
                                            </div>
                                        </div>
                                        {/* End Date */}
                                        <div className="mb-4 w-full">
                                            <label className="block text-[15px] md:text-lg font-medium">End Date <span className="text-red-500">*&nbsp;

                                            </span></label>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <CustomDatePicker
                                                    sx={{ width: "100%" }}
                                                    disabled={workExperience.currentlyWorking}
                                                    views={['year', 'month']}
                                                    inputFormat="MM/yyyy"
                                                    value={workExperience.endDate}
                                                    name='endDate'
                                                    aria-label='end date'
                                                    autoComplete='off'
                                                    onChange={(newDate) => handleDateChange(newDate, 'endDate')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <div className="mb-4 w-full h-[200px]">

                                        <label className="block text-[15px] md:text-lg font-medium">Work Details</label>
                                        <ReactQuill
                                            key={quillKey}
                                            ref={quillRef2}  // Attach the ref to access Quill instance
                                            value={workExperience.workDetails}
                                            id='1'
                                            onChange={(value) => handleRichTextChange("workDetails", value, quillRef2)}
                                            className="bg-white rounded-md h-[200px]"
                                            theme="snow"
                                            name="workDetails"
                                            aria-label='work details'
                                            autoComplete='off'
                                        />
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 text-xl">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleEditSave(editId)
                                        }}
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 sm:ml-3 sm:w-auto"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        type="button"
                                        data-autofocus
                                        onClick={() => setOpen2(false)}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>

            </div>
        </>
    )
}

export default WorkExperience