import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/header";
import Dashnavbar from "./components/dashNav";

function Dashboard() {
  
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <div className="p-4">
        <Dashnavbar />
        <Outlet/>
      </div>
    </div>
  );
}

export default Dashboard;
