import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NepaliDate from "nepali-datetime";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import addressImage from "../../../../images/address-verification.png";

const AddressVerification = () => {
  const previewRef = useRef();
  const [information, setInformation] = useState({
    date: "",
    wardChairPersonName: "",
    previousLocalBody: "",
    previousWardNo: "",
    previousMetroPolitan: false,
    previousSubMetroPolitan: false,
    previousMunicipality: false,
    previousRuralRuralMunicipality: false,
    previousDistrict: "",
    previousProvince: "",
    currentLocalBody: "",
    currentWardNo: "",
    currentMetroPolitan: false,
    currentSubMetroPolitan: false,
    currentMunicipality: false,
    currentRuralRuralMunicipality: false,
    currentProvince: "",
    currentDistrict: "",
  });

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handleRadioChangeForLocalBody = (e) => {
    const { value } = e.target;

    setInformation({
      ...information,
      previousMetroPolitan: value === "previousMetroPolitan", // Set to true if selected
      previousSubMetroPolitan: value === "previousSubMetroPolitan", // Set to true if selected
      previousMunicipality: value === "previousMunicipality", // Set to true if selected
      previousRuralRuralMunicipality:
        value === "previousRuralRuralMunicipality", // Set to true if selected
    });
  };

  const handlRadioChangeForLocalBody2 = (e) => {
    const { value } = e.target;

    setInformation({
      ...information,
      currentMetroPolitan: value === "currentMetroPolitan", // Set to true if selected
      currentSubMetroPolitan: value === "currentSubMetroPolitan", // Set to true if selected
      currentMunicipality: value === "currentMunicipality", // Set to true if selected
      currentRuralRuralMunicipality: value === "currentRuralRuralMunicipality", // Set to true if selected
    });
  };

  function formatADDate(dateObject) {
    const { dayEn, monthEn, yearEn } = dateObject;

    // Helper function to add ordinal suffix
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 11th to 19th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Month names (assuming monthEn is 0-indexed)
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get month name
    const monthName = monthNames[monthEn]; // Using monthEn (0-indexed)

    // Format the date string
    const formattedDate = `${dayEn}${getOrdinalSuffix(
      dayEn
    )} ${monthName}, ${yearEn}`;

    return formattedDate;
  }

  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "tax_clearance_letter",
    pageStyle: `
            @page { 
                size: A4; 
                margin: 40px;  /* Set consistent margin for print */
            }
            body { 
                margin: 40px;  /* Set consistent margin for the body */
            }
        `,
  });

  return (
    <>
      <NavigationBreadcrumb
        slug={"address-verification"}
        name={"Address Verification"}
      />
      <SEO
        title={"Address Verification Certificate"}
        description={
          "Build your documents, and manage your applications with ease."
        }
        name={"Address Verification Certificate"}
        type={"website"}
        path={"/tools/documents/address-verification"}
        image={addressImage}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <div>
          <h1 className="text-2xl md:text-3xl font-semibold font-montserrat mb-4">
            Address Verification Certificate{" "}
          </h1>

          <div className="p-4 border bg-gray-100 rounded-md">
            <h1 className="pb-5 text-xl font-medium underline">
              Additional Information
            </h1>

            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* Date  */}
              <div className="mb-4 w-full font-montserrat">
                <label className="block text-sm md:text-lg font-medium mb-2">
                  Date<span className="text-red-500">*&nbsp;</span>
                </label>
                <NepaliDatePicker
                  inputClassName="cursor-pointer px-4 py-2 w-full"
                  value={information.date}
                  onChange={(value) => {
                    setInformation({ ...information, date: value });
                  }}
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                />
              </div>

              {/* ward chairperson name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Name
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardChairPersonName"
                  value={information.wardChairPersonName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Nawaraj Sapkota"
                  aria-label="Ward Chairperson Name input"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          <div className="p-4 border bg-gray-100 rounded-md">
            <h1 className="pb-10 text-xl font-medium underline">
              Previous Address
            </h1>
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* Previous LocalBody  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Local Body<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="previousLocalBody"
                  value={information.previousLocalBody}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Tulsipur"
                  aria-label="Local body input"
                  autoComplete="off"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="grid grid-cols-2 items-center h-[45px]">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="previousMetroPolitan"
                      checked={information.previousMetroPolitan}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1  border rounded-md scale-125"
                      aria-label="Previous Local Body is Metropolitan"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      MetroPolitian
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="previousSubMetroPolitan"
                      checked={information.previousSubMetroPolitan}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Previous Local Body is Sub-Metropolitan"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Sub-Metropolitan
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="previousMunicipality"
                      checked={information.previousMunicipality}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Previous Local Body is Municipality"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Municipality
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="previousRuralRuralMunicipality"
                      checked={information.previousRuralRuralMunicipality}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Previous Local Body is Rural Municipality"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Rural Municipality
                    </strong>
                  </div>
                </div>
              </div>
              {/*previous Ward No  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward No<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="number"
                  name="previousWardNo"
                  value={information.previousWardNo}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 05"
                  aria-label="Previous Ward No"
                  autoComplete="off"
                />
              </div>
              {/* previous district  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  District<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="previousDistrict"
                  value={information.previousDistrict}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Sunsari"
                  aria-label="Previous District"
                  autoComplete="off"
                />
              </div>
              {/* previous province  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Province<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="previousProvince"
                  value={information.previousProvince}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Koshi"
                  aria-label="Previous Province"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          <div className="my-5 p-4 border bg-gray-100 rounded-md">
            <h1 className="pb-10 text-xl font-medium underline">
              Current Address
            </h1>
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* Current LocalBody  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Local Body<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="currentLocalBody"
                  value={information.currentLocalBody}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Tulsipur"
                  aria-label="Current Local Body"
                  autoComplete="off"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="grid grid-cols-2 items-center h-[45px]">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody2"
                      value="currentMetroPolitan"
                      checked={information.currentMetroPolitan}
                      onChange={handlRadioChangeForLocalBody2}
                      className="mt-1  border rounded-md scale-125"
                      aria-label="Current Local Body is Metropolitan"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      MetroPolitian
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody2"
                      value="currentSubMetroPolitan"
                      checked={information.currentSubMetroPolitan}
                      onChange={handlRadioChangeForLocalBody2}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Current Local Body is Sub-Metropolitan"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Sub-Metropolitan
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody2"
                      value="currentMunicipality"
                      checked={information.currentMunicipality}
                      onChange={handlRadioChangeForLocalBody2}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Current Local Body is Municipality"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Municipality
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody2"
                      value="currentRuralRuralMunicipality"
                      checked={information.currentRuralRuralMunicipality}
                      onChange={handlRadioChangeForLocalBody2}
                      className="mt-1 border rounded-md scale-125"
                      aria-label="Current Local Body is Rural Municipality"
                      autoComplete="off"
                    />
                    <strong className="font-medium text-sm">
                      Rural Municipality
                    </strong>
                  </div>
                </div>
              </div>
              {/*current Ward No  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward No<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="number"
                  name="currentWardNo"
                  value={information.currentWardNo}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 05"
                  aria-label="Current Ward No"
                  autoComplete="off"
                />
              </div>
              {/* current district  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  District<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="currentDistrict"
                  value={information.currentDistrict}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Sunsari"
                  aria-label="Current District"
                  autoComplete="off"
                />
              </div>
              {/* current province  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Province<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="currentProvince"
                  value={information.currentProvince}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Koshi"
                  aria-label="Current Province"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 border bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-10">Document Preview</h2>
          <div ref={previewRef} className="p-4 A4-size font-montserrat">
            <div className="h-[200px] w-full"></div>
            <p className="text-right">
              Date:{" "}
              {information.date
                ? information.date
                : "................................."}
            </p>
            <div className="text-center pt-10">
              <p className="text-xl font-bold uppercase underline">
                Subject: Address Verification
              </p>
              <p className="mt-5 text-2xl font-bold underline">
                To Whom it May Concern
              </p>
            </div>
            <div className="pt-10 text-justify">
              According to the decision made by Government of Nepal in{" "}
              <strong>
                {information.date ? information.date + "." : "............. "}{" "}
                B.S. (
                {information.date
                  ? formatADDate(new NepaliDate(information.date))
                  : "............"}{" "}
                A.D.)
              </strong>{" "}
              the{" "}
              {information.previousMetroPolitan ||
                information.previousSubMetroPolitan ||
                information.previousMunicipality ||
                information.previousRuralRuralMunicipality
                ? information.previousMetroPolitan
                  ? `MetroPolitan City`
                  : information.previousSubMetroPolitan
                    ? `Sub-MetroPolitan City`
                    : information.previousMunicipality
                      ? `Municipality`
                      : `Rural Municipality`
                : "...................."}{" "}
              named &nbsp;
              <strong>
                {information.previousLocalBody &&
                  (information.previousMetroPolitan ||
                    information.previousSubMetroPolitan ||
                    information.previousMunicipality ||
                    information.previousRuralRuralMunicipality)
                  ? information.previousMetroPolitan
                    ? `${information.previousLocalBody} MetroPolitan City`
                    : information.previousSubMetroPolitan
                      ? `${information.previousLocalBody} Sub-MetroPolitan City`
                      : information.previousMunicipality
                        ? `${information.previousLocalBody} Municipality`
                        : `${information.previousRuralRuralMunicipality} Rural Municipality`
                  : " .................. "}
                &nbsp; Ward No
                {information.previousWardNo
                  ? " " + information.previousWardNo
                  : " ............... "}
                ,&nbsp;
                {information.previousDistrict
                  ? information.previousDistrict
                  : "................. "}
                &nbsp; District, Nepal{" "}
              </strong>
              has been changed into &nbsp;
              <strong>
                {information.currentLocalBody &&
                  (information.currentMetroPolitan ||
                    information.currentSubMetroPolitan ||
                    information.currentMunicipality ||
                    information.currentRuralRuralMunicipality)
                  ? information.currentMetroPolitan
                    ? `${information.currentLocalBody} MetroPolitan City`
                    : information.currentSubMetroPolitan
                      ? `${information.currentLocalBody} Sub-MetroPolitan City`
                      : information.currentMunicipality
                        ? `${information.currentLocalBody} Municipality`
                        : `${information.currentRuralRuralMunicipality} Rural Municipality`
                  : " ....................."}
                &nbsp; Ward No &nbsp;
                {information.currentWardNo
                  ? information.currentWardNo
                  : " ..............."}
                ,&nbsp;
                {information.currentDistrict
                  ? information.currentDistrict
                  : " ................. "}
                &nbsp; District, Province{" "}
                {information.currentProvince
                  ? information.currentProvince
                  : ".............."}
                , Nepal.
              </strong>
              <br />
              <br />
              Thus, this is hereby certified that&nbsp;
              <strong>
                {information.previousLocalBody &&
                  (information.previousMetroPolitan ||
                    information.previousSubMetroPolitan ||
                    information.previousMunicipality ||
                    information.previousRuralRuralMunicipality)
                  ? information.previousMetroPolitan
                    ? `${information.previousLocalBody} MetroPolitan City`
                    : information.previousSubMetroPolitan
                      ? `${information.previousLocalBody} Sub-MetroPolitan City`
                      : information.previousMunicipality
                        ? `${information.previousLocalBody} Municipality`
                        : `${information.previousRuralRuralMunicipality} Rural Municipality`
                  : " ....................."}
                , Ward No &nbsp;
                {information.previousWardNo
                  ? information.previousWardNo
                  : "..............."}
                , &nbsp;
                {information.previousDistrict
                  ? information.previousDistrict
                  : " ................. "}
                &nbsp; District, Nepal &nbsp;
              </strong>
              has changed into&nbsp;
              <strong>
                {information.currentLocalBody &&
                  (information.currentMetroPolitan ||
                    information.currentSubMetroPolitan ||
                    information.currentMunicipality ||
                    information.currentRuralRuralMunicipality)
                  ? information.currentMetroPolitan
                    ? `${information.currentLocalBody} MetroPolitan City`
                    : information.currentSubMetroPolitan
                      ? `${information.currentLocalBody} Sub-MetroPolitan City`
                      : information.currentMunicipality
                        ? `${information.currentLocalBody} Municipality`
                        : `${information.currentRuralRuralMunicipality} Rural Municipality`
                  : "....................."}
                ,&nbsp;Ward No&nbsp;
                {information.currentWardNo
                  ? information.currentWardNo
                  : "..............."}
                ,&nbsp;
                {information.currentDistrict
                  ? information.currentDistrict
                  : "................."}
                &nbsp; District, Province{" "}
                {information.currentProvince
                  ? information.currentProvince
                  : ".............."}
                , Nepal
              </strong>
              &nbsp;in the present.
            </div>

            <div className="pt-32">
              <div className=" flex flex-col items-end">
                <p>
                  {information.wardChairPersonName
                    ? information.wardChairPersonName
                    : "..........................................................."}
                </p>
                <p className="font-bold">Ward Chairperson</p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handlePrint();
          }}
          className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
        // disabled={!checkFields}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default AddressVerification;
