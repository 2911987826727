import React from 'react';

const AdvertisementBanner = () => {
  return (
    <div className="relative h-[200px] text-white flex items-center justify-center">
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            Ready to Study Abroad?
          </h1>
          <p className="text-lg md:text-xl mb-6">
            Explore new opportunities and get expert guidance.
          </p>
          <a
            href="/contact-us" // Update the href to your contact page route
            className="px-6 py-3 bg-white text-indigo-700 font-semibold rounded-lg shadow-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Contact Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default AdvertisementBanner;
