import ContactSvg from "../images/svgs/ContactSvg"
import { IoPerson } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../api/axiosInstance";
import SEO from "../components/SEO";

const initialState = {
    name: '',
    email: '',
    message: ''
}
const ContactUs = () => {
    const [data, setData] = useState(initialState);
    const [disabled, setDisabled] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const toastLoading = toast.loading("Sending message...");
        setDisabled(true);

        try {
            const response = await axiosInstance.post('/api/users/contact/', data);
            if (response.status === 201) {
                toast.dismiss(toastLoading);
                setDisabled(false);
                setData(initialState);
                toast.success("Message sent successfully!");
            }

        } catch (error) {
            if (error.response && error.response.data) {
                const errors = error.response.data;
                for (const field in errors) {
                    const error = errors[field];
                    toast.error(error + " in " + field);
                }

            }
        } finally {
            toast.dismiss(toastLoading);
            setDisabled(false);
        }
    };
    return (
        <div className='max-w-7xl mx-auto py-20 px-2 md:px-4'>
            <SEO
                title={"Contact Us | AAEC Global"}
                description={"Contact us for Expert Guidance from Experienced Advisors, Comprehensive Support for International Students,Partnerships with Top Educational Institutions, Tailored Solutions to Meet Individual Needs"}
                name={"Contact Us"}
                type={"website"}
                path={"/contact-us"}
            />
            <h1 className='text-3xl md:text-4xl font-bold'>Contact Us</h1>
            <div className="grid gap-10  grid-cols-1 lg:grid-cols-4 mt-7 md:mt-10">
                <form className="col-span-1 lg:col-span-2" onSubmit={handleSubmit}>
                    <div className="w-full mb-4 relative">
                        <input className="text-base md:text-lg outline-none focus:outline-1 focus:outline-blue-500 px-14 py-3 w-full rounded-lg placeholder:text-base placeholder:md:text-lg placeholder:text-[#83a4b0] bg-[#edf6fd]" value={data.name} name="name" onChange={handleChange} type="text" placeholder='Name' disabled={disabled} />
                        <IoPerson className="absolute left-3 top-[15px] text-xl md:text-2xl text-[#83a4b0]" />
                    </div>
                    <div className="w-full mb-4 relative">
                        <input className="text-base md:text-lg outline-none focus:outline-1 focus:outline-blue-500 px-14 py-3 w-full rounded-lg placeholder:text-base placeholder:md:text-lg placeholder:text-[#83a4b0] bg-[#edf6fd]" value={data.email} name="email" onChange={handleChange} type="text" placeholder='Email' disabled={disabled} />
                        <CiMail className="absolute left-3 top-[15px] text-xl md:text-2xl text-[#83a4b0]" />
                    </div>
                    <div className="w-full mb-4 relative">
                        <textarea className="text-base md:text-lg outline-none focus:outline-1 focus:outline-blue-500 px-4 py-3 w-full rounded-lg placeholder:text-base placeholder:md:text-lg placeholder:text-[#83a4b0] bg-[#edf6fd] h-[200px] resize-none" name="message" value={data.message} onChange={handleChange} placeholder="Message" disabled={disabled}></textarea>
                    </div>

                    <button className="w-full block bg-[#eb4934] text-white font-bold text-base md:text-xl py-2 rounded-lg hover:bg-[#5443c5] transition-all duration-300" disabled={disabled}>Send Message</button>

                </form>
                <div className="col-span-1 lg:col-span-2 hidden lg:block">
                    <ContactSvg className={"lg:w-[500px] xl:w-[600px]"} />
                </div>
            </div>
            <Toaster />
        </div>
    )
}

export default ContactUs