import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import Spinner from "./Spinner";

const DashboardProtect = () => {
    const { isAuthenticated, username, authLoading } = useAuth();
    if (authLoading) {
        return <Spinner />;
    }
    return !isAuthenticated && !username ? <Navigate to="/admin/login" /> : <Outlet />;
};

export default DashboardProtect;
