import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import nameImage from "../../../../images/name-verification.png";


const NameVerification = () => {
  const previewRef = useRef();
  const [information, setInformation] = useState({
    firstName: {
      name: "",
      gender: "",
    },
    secondName: {
      name: "",
      gender: "",
    },
    surNames: [],
    nameOfWardChairPerson: "",
    date: "",
  });
  const [surName, setSurName] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Split the name to handle nested fields (e.g. "firstName.gender")
    const nameParts = name.split(".");

    // Function to update nested state
    const updateNestedState = (state, nameParts) => {
      if (nameParts.length === 1) {
        // Update a simple field
        return { ...state, [nameParts[0]]: value };
      }

      // If it's a nested field (e.g. "firstName.gender")
      const [firstPart, ...restParts] = nameParts;
      return {
        ...state,
        [firstPart]: updateNestedState(state[firstPart], restParts),
      };
    };

    // Update state using the helper function
    setInformation((prevState) => updateNestedState(prevState, nameParts));
  };

  const handleAddSurName = () => {
    if (surName && surName.trim() !== "") {
      setInformation({
        ...information,
        surNames: [...information.surNames, surName],
      });
      setSurName("");
      setError("");
    } else {
      setError("Null value is not accepted.");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "tax_clearance_letter",
    pageStyle: `
            @page { 
                size: A4; 
                margin: 40px;  /* Set consistent margin for print */
            }
            body { 
                margin: 40px;  /* Set consistent margin for the body */
            }
        `,
  });

  return (
    <>

      <NavigationBreadcrumb
        slug={"name-verification"}
        name={"Name Verification"}
      />
      <SEO
        title={"Name Verification"}
        description={
          "Build your documents, and manage your applications with ease."
        }
        name={"Name Verification"}
        type={"website"}
        image={nameImage}
        path={"/tools/documents/name-verification"}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <div>
          <h1 className="text-2xl md:text-3xl mb-4 font-semibold font-montserrat">
            Family Name Verification{" "}
          </h1>

          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* First Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Name (1)<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="firstName.name"
                  value={information.firstName.name}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Gajendra Khadka"
                  aria-label="First Name input"
                  autoComplete="off"
                />
              </div>
              {/* first name checkbox  */}

              {/* first name checkbox */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="firstName.gender"
                    value="mr"
                    checked={information.firstName.gender === "mr"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Mr.</strong>

                  <input
                    type="radio"
                    name="firstName.gender"
                    value="mrs"
                    checked={information.firstName.gender === "mrs"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Mrs.</strong>

                  <input
                    type="radio"
                    name="firstName.gender"
                    value="ms"
                    checked={information.firstName.gender === "ms"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Miss.</strong>
                </div>
              </div>

              {/* Second Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Name (2)<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="secondName.name"
                  value={information.secondName.name}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Yubraj Basnet"
                  aria-label="Second Name input"
                  autoComplete="off"
                />
              </div>

              {/* Second name checkbox  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="secondName.gender"
                    value="mr"
                    checked={information.secondName.gender === "mr"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Mr.</strong>

                  <input
                    type="radio"
                    name="secondName.gender"
                    value="mrs"
                    checked={information.secondName.gender === "mrs"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Mrs.</strong>

                  <input
                    type="radio"
                    name="secondName.gender"
                    value="ms"
                    checked={information.secondName.gender === "ms"}
                    onChange={handleChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Miss.</strong>
                </div>
              </div>

              {/* Date  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Date<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="date"
                  value={information.date}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 20/01/2020"
                  aria-label="Date input"
                  autoComplete="off"
                />
              </div>

              {/* Name of Ward Chairperson  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Name
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="nameOfWardChairPerson"
                  value={information.nameOfWardChairPerson}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Raju Prasad Neupane"
                  aria-label="Ward Chairperson Name input"
                  autoComplete="off"
                />
              </div>

              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Surnames<span className="text-red-500">*&nbsp;</span>
                </label>
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                <div>
                  <div>
                    <input
                      type="text"
                      name="surNames"
                      value={surName}
                      onChange={(e) => {
                        setSurName(e.target.value);
                      }}
                      className="w-full mt-1 p-2 border rounded-md"
                      placeholder="Eg. Darji, Naubagh, Darnal, Nepali"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddSurName(e);
                        }
                      }}
                      aria-label="Surname input"
                      autoComplete="off"
                    />
                  </div>
                  <button
                    onClick={() => {
                      handleAddSurName();
                    }}
                    className="bg-[#01132b] hover:bg-[#1a2b40]  px-4 mt-1 py-1 text-white rounded-sm"
                  >
                    Add
                  </button>
                  <button
                    onClick={() => {
                      setInformation({
                        ...information,
                        surNames: [],
                      });
                    }}
                    className="bg-[#eb4934] hover:bg-[#d43b2e] px-4 mx-1 mt-1 py-1 text-white rounded-sm"
                  >
                    Reset
                  </button>
                </div>
                <p className="mt-1">
                  {information.surNames.length === 0
                    ? "Your Surnames will appear here"
                    : information.surNames.join(", ")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 border bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-10">Document Preview</h2>
          <div ref={previewRef} className="p-4 A4-size font-montserrat">
            <div className="h-[200px] w-full"></div>
            <p className="text-end">
              Date:{" "}
              {information.date
                ? information.date
                : "................................."}
            </p>
            <div className="text-center pt-10">
              <p className="text-xl underline font-bold">
                Subject: Family Name Verification
              </p>
            </div>
            <div className="pt-10 text-justify">
              This is to certify that{" "}
              <strong>
                {" "}
                {!information.firstName || !information.firstName.gender
                  ? "Mr./Mrs./Miss................"
                  : information.firstName.name &&
                    information.firstName.gender === "mr"
                    ? `Mr. ${information.firstName.name}`
                    : information.firstName.name &&
                      information.firstName.gender === "mrs"
                      ? `Mrs. ${information.firstName.name}`
                      : information.firstName.name &&
                      information.firstName.gender === "ms" &&
                      `Miss. ${information.firstName.name}`}
              </strong>{" "}
              and{" "}
              <strong>
                {!information.secondName || !information.secondName.gender
                  ? "Mr./Mrs./Miss................"
                  : information.secondName.name &&
                    information.secondName.gender === "mr"
                    ? `Mr. ${information.secondName.name}`
                    : information.secondName.name &&
                      information.secondName.gender === "mrs"
                      ? `Mrs. ${information.secondName.name}`
                      : information.secondName.name &&
                      information.secondName.gender === "ms" &&
                      `Miss. ${information.secondName.name}`}
              </strong>{" "}
              the surname{" "}
              <strong>
                {information.surNames.length === 0
                  ? "Eg: Darji, Naubagh, Darnal and Nepali"
                  : information.surNames.length === 1
                    ? information.surNames[0]
                    : information.surNames.slice(0, -1).join(", ") +
                    " and " +
                    information.surNames[information.surNames.length - 1]}
              </strong>{" "}
              are the same. These all surname belongs to the same community and
              follow the same religion.
            </div>

            <div className="pt-32">
              <div className=" flex flex-col items-end">
                <p className="">
                  {information.nameOfWardChairPerson
                    ? information.nameOfWardChairPerson
                    : "................................................................"}
                </p>
                <p className="font-bold">Ward Chairperson</p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handlePrint();
          }}
          className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default NameVerification;
