import React from 'react'
import SEO from '../../../components/SEO'

function LifeAtAustralia() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <SEO
        title={"Study Abroad | Australia Life"}
        description={"Australia is known for its rich cultural diversity, welcoming people from all over the world. As an international student, you'll experience a blend of cultures, cuisines, and traditions. This multicultural environment not only enhances your educational experience but also offers a vibrant and inclusive community."}
        name={"Study Abroad Australia Life"}
        type={"website"}
        path={"/study-abroad/australia/life-and-study/"}
      />
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">
        Life in Australia
      </h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Cultural Diversity</h2>
        <p className="text-gray-700">
          Australia is known for its rich cultural diversity, welcoming people from all over the world. As an international student, you'll experience a blend of cultures, cuisines, and traditions. This multicultural environment not only enhances your educational experience but also offers a vibrant and inclusive community.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Cost of Living</h2>
        <p className="text-gray-700">
          The cost of living in Australia varies depending on the city or town you live in. Major cities like Sydney, Melbourne, and Brisbane are more expensive, but they also offer more job opportunities and amenities. On average, international students should budget for accommodation, food, transport, and entertainment. Affordable living options are available in smaller towns and regional areas.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Accommodation</h2>
        <p className="text-gray-700">
          Students in Australia have a variety of accommodation options, including on-campus housing, private rentals, shared apartments, and homestays. On-campus housing offers convenience and a ready-made community, while private rentals and shared apartments provide more independence. Homestays allow you to live with an Australian family, offering a deeper cultural experience.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Work Opportunities</h2>
        <p className="text-gray-700">
          Australia allows international students to work part-time while studying. You can work up to 20 hours per week during the semester and full-time during breaks. This helps students gain valuable work experience and support themselves financially. The country has a strong job market with opportunities in retail, hospitality, and other sectors.
        </p>
      </section>

      <section>
        <h2 className="text-base lg:text-lg font-semibold mb-4">Healthcare and Insurance</h2>
        <p className="text-gray-700">
          International students in Australia must have Overseas Student Health Cover (OSHC) for the duration of their stay. OSHC provides access to essential medical services and covers visits to doctors, some hospital treatments, and limited prescription medications. It's important to choose a plan that suits your needs and understand what is covered under your policy.
        </p>
      </section>
    </div>
  )
}

export default LifeAtAustralia