import React from "react";
import { Outlet } from "react-router-dom";
import SEO from "../../components/SEO";
const ToolsDisp = () => {
  return (
    <div>
      <SEO
        title={"Tools"}
        description={
          "Tools for Your Academic Journey.Enhance your application process with our handy tools. Build your CV, create professional documents, and manage your applications with ease."
        }
        name={"Tools"}
        type={"website"}
        path={"/tools"}
      />
      <Outlet />
    </div>
  );
};

export default ToolsDisp;
