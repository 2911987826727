import React from "react";
import { Outlet } from "react-router-dom";
import UKTabs from "./navUK";
import SEO from "../../../components/SEO";

const StudyInUK = () => {
  return (
    <div className="max-w-7xl mx-auto p-4">
      <SEO
        title={"Study Abroad | United Kingdom"}
        description={
          "Why Study in the United Kingdom.The United Kingdom is renowned for its rich history, diverse culture, and prestigious educational institutions. With a variety of world-class universities and cutting-edge research facilities, the UK offers an excellent academic environment for international students."
        }
        name={"Study Abroad United Kingdom"}
        type={"website"}
        path={"/study-abroad/united-kingdom"}
      />
      <UKTabs />
      <Outlet />
    </div>
  );
};

export default StudyInUK;
