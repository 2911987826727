import React from "react";
import { Outlet } from "react-router-dom";
import AmericaTabs from "./navAmerica";
import SEO from "../../../components/SEO";
const StudyInAmerica = () => {
  return (
    <div className="max-w-7xl mx-auto p-4">
      <SEO
        title={"Study Abroad | United-states-of-america"}
        description={
          "Why Study in the United States.The United States is a leading destination for international students, offering a diverse range of programs, cutting-edge technology, and a global perspective. With hundreds of top-ranked universities, the US provides students with the opportunity to receive a high-quality education and engage in a multicultural environment."
        }
        name={"Study Abroad United-states-of-america"}
        type={"website"}
        path={"/study-abroad/united-states-of-america"}
      />
      <AmericaTabs />
      <Outlet />
    </div>
  );
};

export default StudyInAmerica;
