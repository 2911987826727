import React, { useState, useEffect, Suspense, lazy } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddBranch from "./branchComponent/addBranch";
import axiosInstance from "../../../api/axiosInstance";

const BranchDetails = lazy(() => import("./branchdetails"));

const BranchPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [branches, setBranches] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const itemsPerPage = 12;


  useEffect(() => {
    axiosInstance.get('/api/users/branches/').then(response => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const apiData = response.data.data;
      setBranches(apiData.slice(startIndex, endIndex));
      window.scrollTo({ top: 0, behavior: "smooth" });
    }).catch(error => {
      console.log(error);
    });
  }, [currentPage]);



  const totalPages = Math.ceil(branches.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };


  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  }

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <header className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h1 className="text-2xl sm:text-3xl font-bold">Branch Details</h1>
        <button
          onClick={handleAddModalOpen}
          className="bg-[#01132b] text-white rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-blue-600 mt-4 sm:mt-0"
        >
          <FontAwesomeIcon icon={faPlus} />
          Add Branch
        </button>
      </header>

      <div className="flex flex-col gap-6">
        <Suspense fallback={<div className="text-center">Loading...</div>}>
          <TransitionGroup>
            {branches.map((branch) => (
              <CSSTransition key={branch.id} timeout={500} classNames="fade">
                <BranchDetails
                  setBranches={setBranches}
                  branches={branches}
                  branch={branch}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Suspense>
      </div>

      <div className="flex justify-center gap-2 mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          aria-label="Previous Page"
          className="px-4 py-2 bg-[#01132b] text-white rounded disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            aria-label={`Page ${index + 1}`}
            className={`px-4 py-2 border rounded ${currentPage === index + 1
              ? "bg-[#01132b] text-white"
              : "bg-white text-blue-500"
              }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          aria-label="Next Page"
          className="px-4 py-2 bg-[#01132b] text-white rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      <AddBranch onClose={handleAddModalClose} branches={branches} setBranches={setBranches} isOpen={isAddModalOpen} />


    </div>
  );
};

export default BranchPage;
