import React from 'react'
import ReactQuill from 'react-quill'
import { useCV } from '../../../context/cvbuilder/hooks/useCV';

const Summary = () => {
    const { summary, setSummary } = useCV();

    const handleRichTextChange = (value) => {
        setSummary(value);
    };

    return (
        <>
            <div className="p-4 mb-2 border bg-gray-100 rounded-md mt-2 h-[300px]">
                <h2 className="text-xl md:text-2xl font-semibold mb-2">Summary</h2>
                <div className="grid gap-2 grid-cols-1 bg-white">
                    {/* Summary */}
                    <div className="w-full h-[200px] pb-5">
                        <ReactQuill
                            value={summary}
                            onChange={(value) => handleRichTextChange(value)}
                            className="rounded-md w-full h-[160px]"
                            theme="snow"
                            name="CV Summary"
                            aria-label='CV Summary'
                            autoComplete='off'
                        />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Summary