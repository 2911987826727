import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import { jwtDecode } from "jwt-decode";


const AuthContext = createContext();

function AuthProvider({ children }) {
    const [authLoading, setAuthLoading] = useState(true);
    const [username, setUsername] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userId] = useState(() => {
        try {
            const token = localStorage.getItem("auth") || sessionStorage.getItem("auth");
            return token ? jwtDecode(token)?.user_id || null : null;
        } catch (error) {
            console.error("Invalid token:", error);
            return null;
        }
    });
    const navigate = useNavigate();

    useEffect(() => {
        const storedAuth = JSON.parse(localStorage.getItem("auth")) || JSON.parse(sessionStorage.getItem("auth"));

        if (storedAuth) {
            const { accessToken, refreshToken } = storedAuth;

            validateAccessToken(accessToken, refreshToken)
                .then(({ authenticated, user }) => {
                    if (authenticated) {
                        setIsAuthenticated(true);
                        setUsername(user); // Set username from API response
                    } else {
                        throw new Error("Invalid access token");
                    }
                })
                .catch(() => {
                    clearAuthData();
                    navigate("/admin/login");
                })
                .finally(() => setAuthLoading(false));
        } else {
            setAuthLoading(false); // No stored authentication data
        }
    }, []); // Runs once on mount

    const validateAccessToken = async (accessToken, refreshToken) => {
        try {
            const res = await axiosInstance.get("/api/users/checklogin/", {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            const { authenticated, user } = res.data;
            if (authenticated) {
                return { authenticated, user };
            }

            throw new Error("Access token expired, attempting refresh...");
        } catch {
            return refreshAccessToken(refreshToken); // Try to refresh the token if access token is invalid
        }
    };

    const refreshAccessToken = async (refreshToken) => {
        try {
            const res = await axiosInstance.post("/api/users/token/refresh/", { refresh: refreshToken });
            const { access, refresh } = res.data.data;

            const updatedAuthData = { accessToken: access, refreshToken: refresh };
            saveAuthData(updatedAuthData);

            // Validate new access token
            return validateAccessToken(access, refresh);
        } catch (error) {
            throw new Error("Token refresh failed");
        }
    };

    const saveAuthData = (authData) => {
        const storage = localStorage.getItem("auth") ? localStorage : sessionStorage;
        storage.setItem("auth", JSON.stringify(authData));
    };

    const clearAuthData = () => {
        localStorage.removeItem("auth");
        sessionStorage.removeItem("auth");
        setIsAuthenticated(false);
        setUsername(null); // Clear username when logging out
    };

    const value = {
        accessToken: JSON.parse(localStorage.getItem("auth"))?.accessToken || JSON.parse(sessionStorage.getItem("auth"))?.accessToken,
        username,
        userId,
        isAuthenticated,
        authLoading,
        setIsAuthenticated,
        setUsername,
        clearAuthData,
        validateAccessToken,
    };

    return (
        <AuthContext.Provider value={value}>
            {!authLoading && children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;
