import { useRef } from "react";
import { useCV } from "../../../context/cvbuilder/hooks/useCV";
import { useReactToPrint } from "react-to-print";

const CvDocument = () => {
  const previewRef = useRef();

  const { workExperiences, personalDetails, skills, educationalDetails, languageDetails, summary } = useCV();
  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "CV",
    pageStyle: `
            @page { 
                size: A4; 
            }`,
  });
  return (
    <>
      <div className="p-4 border bg-white rounded-md">
        <h2 className="text-2xl font-semibold mb-5">CV Preview</h2>
        <div ref={previewRef} className="A4-size p-4 font-montserrat">
          <div className="flex justify-between">
            <h1 className="text-4xl font-bold self-end ">{personalDetails.fullName}</h1>
            <div className="text-sm">
              <p>{personalDetails.city}, {personalDetails.country}</p>
              <p>{personalDetails.phone}</p>
              <p>{personalDetails.email}</p>
            </div>
          </div>

          <div className="bg-[#e3e3e3] mt-2">
            <h1 className="uppercase px-2">Professional Summary</h1>
          </div>
          <div className="text-sm">
            <div className="custom-html pl-4" dangerouslySetInnerHTML={{ __html: summary }} />
          </div>

          <div className="bg-[#e3e3e3] mt-2">
            <h1 className="uppercase px-2">Skills</h1>
          </div>
          <div className="text-sm">
            <ul className="grid grid-cols-3 px-4">
              {skills.map((skill, i) => (
                <li key={i} className="list-disc">{skill}</li>
              ))}
            </ul>
          </div>

          <div className="bg-[#e3e3e3] mt-2">
            <h1 className="uppercase px-2">Experience</h1>
          </div>
          {
            workExperiences.map((experience, i) => (
              <div className="mt-2 text-sm">
                <div className="flex justify-between">
                  <p>{experience.jobTitle}</p>
                  <p>{new Date(experience?.startDate).toLocaleDateString("en-US", { year: "numeric", month: "long" })} - {experience.currentlyWorking ? "Current" : new Date(experience?.endDate).toLocaleDateString("en-US", { year: "numeric", month: "long" })}</p>
                </div>
                <p>{experience.employer} | {experience.cityTown}, {experience.country}</p>
                <div className="pl-4 custom-html" dangerouslySetInnerHTML={{ __html: experience?.workDetails }} />
              </div>
            ))
          }

          <div className="bg-[#e3e3e3] mt-2">
            <h1 className="uppercase px-2">Education</h1>
          </div>
          <div className="mt-2 text-sm">
            {
              educationalDetails.map((detail, i) => (
                <>
                  <div className="flex justify-between">
                    <p>{detail.fieldOfStudy} - {detail.degree}</p>
                    <p>{new Date(detail?.startDate).toLocaleDateString("en-US", { year: "numeric", month: "long" })} - {new Date(detail?.endDate).toLocaleDateString("en-US", { year: "numeric", month: "long" })}</p>
                  </div>
                  <p>{detail.schoolName}, {detail.schoolLocation}</p>
                  <div className="custom-html pl-4" dangerouslySetInnerHTML={{ __html: detail?.descriptionOrSpecialAwards }} />
                </>
              ))
            }

          </div>


          <div className="bg-[#e3e3e3] mt-2">
            <h1 className="uppercase px-2">Language</h1>

          </div>
          <div className="grid grid-cols-6 gap-2 text-sm mt-2">
            {
              languageDetails.map((lang, i) => (
                <p className="font-bold" key={i}>{lang}</p>
              ))
            }

          </div>

        </div>
      </div>
      {/* Button to download PDF */}
      <button
        onClick={() => {
          handlePrint();
        }}
        className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
      // disabled={!checkFields}
      >
        Download PDF
      </button>
    </>
  )
}

export default CvDocument