import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axiosInstance";

const ITEMS_PER_PAGE = 3;

const ArticleGrid = () => {
  const [articles, setArticles] = useState([]);
  const [users, setUsers] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axiosInstance.get("/api/users/articles");
        setArticles(response.data.data);

        // Extract unique author IDs from the articles
        const authorIds = [...new Set(response.data.data.map((article) => article.author))];

        // Fetch each author's username based on the user ID
        const userData = await Promise.all(
          authorIds.map((id) => axiosInstance.get(`/api/users/users/${id}/`))
        );

        const userMap = userData.reduce((acc, userResponse) => {
          const userDetail = userResponse.data;
          acc[userDetail.id] = userDetail.username;
          return acc;
        }, {});

        setUsers(userMap);
      } catch (error) {
        console.error("Error fetching articles or user data:", error);
      }
    };

    fetchArticles();
  }, []);

  const sortedArticles = articles.sort((a, b) => {
    if (a.featured === b.featured) {
      return new Date(b.date) - new Date(a.date);
    }
    return b.featured - a.featured;
  });

  const totalPages = Math.ceil(sortedArticles.length / ITEMS_PER_PAGE);
  const currentArticles = sortedArticles.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page) => setCurrentPage(page);

  const truncateText = (html, limit, id) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    const textContent = div.textContent || div.innerText || "";
    let truncatedText = textContent.length > limit ? textContent.slice(0, limit) + "..." : textContent;
    if (textContent.length > limit) {
      truncatedText += `<a class="text-blue-500 hover:underline" href="/article/${id}">Read more</a>`;
    }
    return truncatedText;
  };

  return (
    <div className="p-6 mx-auto max-w-screen-lg">
      <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">Latest Articles</h1>
      {articles.length === 0 ? (
        <div className="text-center text-gray-500 text-lg">There is no article to display!</div>
      ) : (
        <div className="flex flex-wrap gap-10 items-center justify-center">
          {currentArticles.map((article) => (
            <div
              key={article.id}
              className="flex flex-col items-center justify-center w-[280px] h-[550px] bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-gray-50 duration-300 ease-in-out"
            >
              <div className="flex gap-2 mb-4">
                <img
                  className="w-full h-48 object-cover rounded-t-lg"
                  src={article.thumbnail}  // Assuming it's a single URL string now
                  alt={`Thumbnail of ${article.title}`}
                />
              </div>
              <h2 className="text-xl font-bold text-gray-900 mb-4">{article.title}</h2>
              <p className="text-gray-700 text-sm mb-2">
                <strong>By:</strong> {users[article.author] || "Unknown Author"}
              </p>
              <p className="text-gray-500 text-sm mb-4">{article.date}</p>
              <div
                className="text-gray-700 mb-4 flex-grow"
                dangerouslySetInnerHTML={{ __html: truncateText(article.description, 100, article.id) }}
              ></div>
              <div className="mt-auto">
                <a
                  href={`/article/${article.id}`}
                  className="bg-[#eb4934] px-4 py-2 mt-auto text-white font-bold rounded hover:bg-[#d43b2e] no-underline"
                >
                  Read More
                </a>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex justify-center mt-8">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            aria-label={`Page ${index + 1}`}
            className={`px-4 py-2 border rounded ${
              currentPage === index + 1 ? "bg-[#eb4934] hover:bg-[#d43b2e] text-white" : "bg-white text-[#eb4934]"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ArticleGrid;
