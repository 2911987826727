import React from 'react';
import ArticleDisplay from '../components/articles/articlesDisp'; 
import ArticleSidebar from '../components/articles/articleSideBar';

const ArticlePage = () => {
  return (
    <div className="flex flex-col md:flex-row p-4">
      <div className="flex-1">
        <ArticleDisplay />
      </div>
      <div className="p-4 border-l border-gray-300">
        <ArticleSidebar />
      </div>
    </div>
  );
};

export default ArticlePage;
