import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Header() {
  const now = new Date();
  const hours = now.getHours();
  const isDaytime = hours >= 6 && hours < 18;
  const greeting =
    hours < 6
      ? "Good Night"
      : hours < 12
      ? "Good Morning"
      : hours < 18
      ? "Good Afternoon"
      : "Good Evening";
  const icon = hours < 6 ? faMoon : hours < 12 ? faSun : isDaytime ? faSun : faMoon;

  const iconColor =
    hours < 6
      ? "text-blue-500"
      : hours < 12
      ? "text-yellow-500"
      : isDaytime
      ? "text-yellow-300"
      : "text-gray-600";

  const formatDateTime = () => {
    const date = now.toLocaleDateString();
    const time = now.toLocaleTimeString();
    return `${date}, ${time}`;
  };

  return (
    <header className="bg-white shadow-md p-4 flex flex-col md:flex-row items-center justify-between">
      <Link to="/admin/dashboard" className="no-underline flex items-center mb-4 md:mb-0">
        <h1 className="text-xl font-semibold text-gray-900">Dashboard</h1>
      </Link>
      <div className="flex flex-col md:flex-row items-center w-full md:w-auto justify-between md:justify-center space-y-2 md:space-y-0 md:space-x-4">
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon icon={icon} className={`text-xl ${iconColor}`} title={greeting} />
          <span className="text-gray-700">
            {greeting}
          </span>
        </div>
        <div className="text-gray-600 text-sm">{formatDateTime()}</div>
      </div>
      <FontAwesomeIcon
        icon={faUserCircle}
        className="text-gray-900 text-2xl cursor-pointer mt-4 md:mt-0"
        title="Profile"
      />
    </header>
  );
}

export default Header;
