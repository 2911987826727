import React from "react";
import SEO from "../components/SEO";
const MakeAnEnquiry = () => {
  return (
    <div className="container mx-auto p-6 max-w-7xl">
      <SEO
        title={"Make An Enquiry"}
        description={
          "Make An Enquiry. Please use the form below to make an enquiry about our services. You can fill it out directly."
        }
        name={"Make An Enquiry"}
        type={"website"}
        path={"/make-an-enquiry"}
      />
      <header className="text-center mb-6">
        <h1 className="text-4xl font-bold mb-4">Make An Enquiry</h1>
        <p className="text-lg text-gray-700">
          Please use the form below to make an enquiry about our services. You
          can fill it out directly.
        </p>
      </header>

      <section className="mb-8">
        <iframe
          src="https://cmst.xyz/inquiry?code=AAE111&color=1076BC"
          title="Enquiry Form"
          width="100%"
          height="800"
          frameBorder="0"
          className="rounded-lg shadow-md"
        >
          Loading...
        </iframe>
      </section>
    </div>
  );
};

export default MakeAnEnquiry;
