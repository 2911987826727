import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { FaSquarePlus } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import numToNepWords from "num-to-nep-words";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import incomeSourceImg from "../../../../images/income-verification.png";


const IncomeSourceCertificate = () => {
  const previewRef = useRef();
  const [information, setInformation] = useState({
    name: "",
    wardChairPersonName: "",
    mr: false,
    mrs: false,
    ms: false,
    fatherName: "",
    motherName: "",
    municipality: "",
    wardNo: "",
    nameOfWard: "",
    district: "",
    province: "",
    date: "",
  });

  const [incomeInformations, setIncomeInformations] = useState([
    {
      incomeSource: "",
      owner: "",
      mr: false,
      mrs: false,
      ms: false,
      annualIncome: "",
    },
  ]);

  const handleDelete = (index) => {
    const newData = incomeInformations.filter((_, i) => index !== i);
    setIncomeInformations(newData);
  };

  const handleAdd = () => {
    setIncomeInformations([
      ...incomeInformations,
      {
        incomeSource: "",
        owner: "",
        mr: false,
        mrs: false,
        ms: false,
        annualIncome: "",
      },
    ]);
  };

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setInformation({
      ...information,
      mr: value === "mr",
      mrs: value === "mrs",
      ms: value === "ms",
    });
  };

  const handleIncomeChange = (e, index) => {
    const { name, value } = e.target;

    setIncomeInformations((prevState) =>
      prevState.map((info, i) =>
        i === index ? { ...info, [name]: value } : info
      )
    );
  };
  const handleRadioChangeForIncome = (e, index) => {
    const { value } = e.target;

    setIncomeInformations((prevState) =>
      prevState.map((info, i) =>
        i === index
          ? {
            ...info,
            mr: value === "mr",
            mrs: value === "mrs",
            ms: value === "ms",
          }
          : info
      )
    );
  };

  const totalIncome = incomeInformations.reduce(
    (acc, curr) => acc + Number(curr.annualIncome),
    0
  );

  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "birth_certificate",
    pageStyle: `
        @page { 
            size: A4; 
            margin: 40px;  /* Set consistent margin for print */
        }
        body { 
            margin: 40px;  /* Set consistent margin for the body */
        }
    `,
  });

  return (
    <>

      <NavigationBreadcrumb
        slug={"income-source-certificate"}
        name={"Income Source Certificate"}
      />
      <SEO
        title={" Income Source Certificate Builder"}
        description={
          " Build your documents, and manage your applications with ease."
        }
        name={" Income Source Certificate Builder"}
        type={"website"}
        path={"/tools/documents/income-source-certificate"}
        image={incomeSourceImg}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <h1 className="text-2xl md:text-3xl mb-4 font-semibold font-montserrat">
          Income Source Certificate Builder
        </h1>
        <div className="">
          <h1 className="text-xl font-semibold font-montserrat">
            Personal Details
          </h1>
          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Full Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={information.name}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Ram Adhikari"
                  aria-label="Full Name input"
                  autoComplete="off"
                />
              </div>
              {/* Choose mr,mrs,ms  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="gender"
                    value="mr"
                    checked={information.mr}
                    onChange={handleRadioChange}
                    className="mt-1  border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />{" "}
                  <strong className="font-medium">Mr.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="mrs"
                    checked={information.mrs}
                    onChange={handleRadioChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />{" "}
                  <strong className="font-medium">Mrs.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="ms"
                    checked={information.ms}
                    onChange={handleRadioChange}
                    className="mt-1 border rounded-md scale-150"
                    aria-label="Gender input"
                    autoComplete="off"
                  />
                  <strong className="font-medium">Miss.</strong>
                </div>
              </div>
              {/* Father Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Father Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="fatherName"
                  value={information.fatherName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Gajendra Adhikari"
                  aria-label="Father Name input"
                  autoComplete="off"
                />
              </div>
              {/* Mother Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Mother Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="motherName"
                  value={information.motherName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Sushila Adhikari"
                  aria-label="Mother Name input"
                  autoComplete="off"
                />
              </div>
              {/* Municipality  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Municipality<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="municipality"
                  value={information.municipality}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Kathmandu"
                  aria-label="Municipality input"
                  autoComplete="off"
                />
              </div>
              {/* Name of Ward */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Name of Ward<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="nameOfWard"
                  value={information.nameOfWard}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Sombare"
                  aria-label="Name of Ward input"
                  autoComplete="off"
                />
              </div>
              {/* Ward No  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward No.<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="number"
                  name="wardNo"
                  value={information.wardNo}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 07"
                  aria-label="Ward No input"
                  autoComplete="off"
                />
              </div>
              {/* District  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  District<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="district"
                  value={information.district}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Kathmandu"
                  aria-label="District input"
                  autoComplete="off"
                />
              </div>
              {/* Province  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Province<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="province"
                  value={information.province}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Koshi"
                  aria-label="Province input"
                  autoComplete="off"
                />
              </div>
              {/* Date  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Date<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="date"
                  value={information.date}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 20/01/2020"
                  aria-label="Date input"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-10">
          <h1 className="text-xl font-semibold font-montserrat">
            Additional Details
          </h1>
          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Ward Chairperson Name
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="wardChairPersonName"
                  value={information.wardChairPersonName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Ram Adhikari"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-10">
          <h1 className="text-xl font-semibold font-montserrat">
            Income Details
          </h1>
          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            {incomeInformations.map((incomeInformation, i) => (
              <div
                className="grid font-afcacad text-sm md:text-lg gap-2 grid-cols-[4fr_4fr_4fr_4fr_1fr] place-items-center md:place-content-center overflow-x-scroll"
                key={i}
              >
                {/* Income Source  */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Income Source<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <input
                    type="text"
                    name="incomeSource"
                    value={incomeInformation.incomeSource}
                    onChange={(e) => handleIncomeChange(e, i)}
                    className="w-full mt-1 p-2 border rounded-md"
                    placeholder="Eg: Salary"
                  />
                </div>
                {/* Owner  */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Owner Name<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <input
                    type="text"
                    name="owner"
                    value={incomeInformation.owner}
                    onChange={(e) => handleIncomeChange(e, i)}
                    className="w-full mt-1 p-2 border rounded-md"
                    placeholder="Eg: Ram Adhikari"
                  />
                </div>
                {/* Choose mr,mrs,ms  */}
                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Choose Option<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <div className="flex gap-4 items-center h-[45px]">
                    <input
                      type="radio"
                      name={`gender${i}`}
                      value="mr"
                      checked={incomeInformation.mr}
                      onChange={(e) => handleRadioChangeForIncome(e, i)}
                      className="mt-1  border rounded-md scale-150"
                    />{" "}
                    <strong className="font-medium">Mr.</strong>
                    <input
                      type="radio"
                      name={`gender${i}`}
                      value="mrs"
                      checked={incomeInformation.mrs}
                      onChange={(e) => handleRadioChangeForIncome(e, i)}
                      className="mt-1 border rounded-md scale-150"
                    />{" "}
                    <strong className="font-medium">Mrs.</strong>
                    <input
                      type="radio"
                      name={`gender${i}`}
                      value="ms"
                      checked={incomeInformation.ms}
                      onChange={(e) => handleRadioChangeForIncome(e, i)}
                      className="mt-1 border rounded-md scale-150"
                    />
                    <strong className="font-medium">Miss.</strong>
                  </div>
                </div>

                <div className="mb-4 w-[200px] md:w-full">
                  <label className="block text-sm md:text-lg font-medium">
                    Annual Income<span className="text-red-500">*&nbsp;</span>
                  </label>
                  <input
                    type="number"
                    name="annualIncome"
                    value={incomeInformation.annualIncome}
                    onChange={(e) => handleIncomeChange(e, i)}
                    className="w-full mt-1 p-2 border rounded-md"
                    placeholder="Eg: 50000"
                  />
                </div>

                <div className="mt-0 md:mt-5 self-center flex items-center gap-2 place-self-end">
                  <ImCross
                    className="cursor-pointer"
                    color="red"
                    size={20}
                    onClick={() => {
                      if (incomeInformations.length > 1) {
                        handleDelete(i);
                      }
                    }}
                    title="Delete"
                  />
                  <FaSquarePlus
                    className="cursor-pointer"
                    color="#01132b"
                    size={30}
                    onClick={handleAdd}
                    title="Add"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="p-4 border bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-10">Document Preview</h2>
          <div
            ref={previewRef}
            className="p-4 A4-size font-montserrat overflow-hidden"
          >
            <div className="h-[200px] w-full"></div>
            <p className="text-end">
              Date:{" "}
              {information.date
                ? information.date
                : "............................."}
            </p>
            <div className="text-center pt-10">
              <p className="uppercase text-2xl font-bold underline">
                Subject: income source certificate
              </p>
              <p className="text-xl font-bold underline">
                To Whom it May Concern
              </p>
            </div>
            <div className="pt-10 text-justify">
              This is to certify that{" "}
              <strong>
                {!information.mr && !information.mrs && !information.ms
                  ? "Mr./Mrs./Miss"
                  : information.mr
                    ? "Mr."
                    : information.mrs
                      ? "Mrs."
                      : "Miss"}
              </strong>
              &nbsp;&nbsp;
              {information.name ? information.name : "............. "}{" "}
              {!information.mr && !information.mrs && !information.ms
                ? "son/daughter"
                : information.mr
                  ? "son"
                  : "daughter"}{" "}
              of <strong>Mr. </strong>
              {information.fatherName
                ? information.fatherName
                : "............."}{" "}
              and <strong>Mrs. </strong>
              {information.motherName
                ? information.motherName
                : ".............."}{" "}
              permanent resident of{" "}
              {information.municipality
                ? information.municipality
                : ".............."}{" "}
              <strong>Municipality/Rural Municipality</strong>{" "}
              <strong>Ward No. </strong>
              {information.wardNo ? information.wardNo + " " : "........."}
              {information.district
                ? information.district
                : ",............"}{" "}
              <strong> District&nbsp;</strong>
              {information.province ? information.province : "..........."}{" "}
              <strong>Province, Nepal</strong>. They have the following source
              of annual income as shown below.
            </div>
            <div className="pt-5">
              <table className="table-auto border-collapse border border-gray-300 w-full">
                <thead>
                  <tr>
                    <td className="border border-gray-300 px-4 py-2">S.N.</td>
                    <td className="border border-gray-300 px-4 py-2">
                      Income Source
                    </td>
                    <td className="border border-gray-300 px-4 py-2">Owner</td>
                    <td className="border border-gray-300 px-4 py-2">
                      Annual Income
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {incomeInformations.map((info, i) => (
                    <tr key={i}>
                      <td className="border border-gray-300 px-4 py-2">
                        {i + 1}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        Income from{" "}
                        {info.incomeSource ? info.incomeSource : "......."}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {info.owner
                          ? `${info.mr ? "Mr. " : info.mrs ? "Mrs. " : "Miss "
                          }` + info.owner
                          : "Mr./Mrs./Miss........."}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {info.annualIncome && info.annualIncome}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pt-10">
              <p>
                In Words:{" "}
                {totalIncome === 0
                  ? "..........................................................................................................................................................."
                  : numToNepWords(totalIncome, "en")}{" "}
                only.
              </p>
            </div>
            <div className="pt-20 flex justify-between">
              <div>
                <p>
                  {information.nameOfWard
                    ? information.nameOfWard
                    : "...................................................."}
                </p>
                <p>
                  (<span className="text-red-500">Name of Ward</span>)
                </p>
              </div>
              <div>
                <p>
                  {information.wardChairPersonName
                    ? information.wardChairPersonName
                    : "............................................................."}
                </p>
                <p className="text-end font-bold">Ward Chairperson</p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handlePrint();
          }}
          className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
        // disabled={!checkFields}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default IncomeSourceCertificate;
