import { Link } from "react-router-dom"

const DocNavigationBreadcrumb = ({ slug, name }) => {
    return (
        <div className="flex font-afcacad text-sm md:text-lg px-2 md:px-8 py-2 md:py-8 space-x-2">
            <Link to={"/tools/documents"} className="hover:underline">
                Documents
            </Link>
            <p>&gt;</p>
            <Link to={`/tools/documents/${slug}`} className="hover:underline">
                {name}
            </Link>
        </div>
    )
}

export default DocNavigationBreadcrumb