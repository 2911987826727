import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import Logo from "./../../logo.png";
import destinations from "../../data/destinationdata";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <nav className="bg-[#01132b] h-15 px-4 flex items-center justify-between relative z-50">
      <Link to="/" className="flex items-center">
        <img
          className="w-36 h-auto transform md:-translate-y-6 -translate-y-3"
          src={Logo}
          alt="AAEC Global Logo"
        />
      </Link>
      <FontAwesomeIcon
        icon={faBars}
        className="text-white text-2xl cursor-pointer md:hidden"
        onClick={toggleSidebar}
        aria-label="Open menu"
      />
      <div
        className={`${isOpen ? "fixed" : "hidden"
          } md:flex flex-col md:flex-row md:items-center md:space-x-8 inset-0 bg-[#01132b] md:bg-transparent p-8 md:p-0`}
        style={{ zIndex: 50 }}
      >
        {/* Close Button for Mobile Menu */}
        {isOpen && (
          <FontAwesomeIcon
            icon={faXmark}
            className="text-white text-2xl cursor-pointer absolute top-4 right-4 md:hidden"
            onClick={toggleSidebar}
            aria-label="Close menu"
          />
        )}

        <ul className="flex flex-col md:flex-row md:items-center md:space-x-8 space-y-6 md:space-y-0 w-full h-full md:w-auto md:h-auto justify-center items-center">
          {[
            { to: "/", text: "Home" },
            {
              to: "/study-abroad",
              text: "Study Abroad",
              dropdown: destinations.map((destination) => ({
                to: `${destination.link.toLowerCase()}`,
                text: destination.name
              })),
            },
            { to: "/our-branches", text: "Our Branches" },
            { to: "/work-with-us", text: "Work With Us" },
            { to: "/articles", text: "Articles" },
            { to: "/make-an-enquiry", text: "Make an Enquiry" },
            { to: "/tools", text: "Tools" },
          ].map(({ to, text, dropdown }, index) => (
            <li key={index} className="relative group">
              <Link
                to={to}
                onClick={handleLinkClick}
                className="text-white text-lg md:text-base lg:text-lg bg-[#01132b] md:bg-transparent px-4 md:px-2 lg:px-4 py-2 hover:bg-gray-700 rounded transition"
                aria-haspopup={dropdown ? "true" : undefined}
                aria-expanded={isOpen ? "true" : "false"}
              >
                {text}
              </Link>
              {dropdown && (
                <ul className="absolute left-0 mt-2 bg-white z-50 shadow-lg hidden group-hover:block min-w-[150px]">
                  {dropdown.map((item, idx) => (
                    <li key={idx}>
                      <Link
                        to={item.to}
                        onClick={handleLinkClick}
                        className="block px-4 py-2 text-black hover:bg-gray-100"
                      >
                        {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
