import React, { useState } from 'react'
import { useCV } from '../../../context/cvbuilder/hooks/useCV';

const SkillsDetails = () => {
    const { skills, setSkills } = useCV();
    const [skill, setSkill] = useState();
    const [error, setError] = useState(""); // State for error message

    const handleAddSkill = () => {
        if (skill && skill.trim() !== "") {
            setSkills(prev => [...prev, skill]);  // Add skill to list
            setSkill("");                         // Clear input
            setError("");                         // Clear error message
        } else {
            setError("Null value is not accepted.");        // Show error message
        }
    };

    const handleResetSkills = () => {
        setSkills([]);  // Clear the skills array
        setSkill("");   // Clear the input field
        setError("");   // Clear any error message
    };


    return (
        <>
            <div className="p-4 border bg-gray-100 rounded-md mt-2">
                <h2 className="text-xl md:text-2xl font-semibold mb-2">Skills</h2>


                <div className="grid grid-cols-1 lg:flex justify-between">
                    {/* Skills */}
                    <div className="mb-4 w-full lg:w-1/2">
                        <input
                            type="text"
                            name='skill'
                            placeholder='Enter your skills'
                            className='px-2 py-2 focus:ring-1 border-none focus:ring-blue-500 focus:outline-none block w-full'
                            value={skill}
                            onChange={e => setSkill(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === 'Enter' && skill.trim() !== "") {
                                    setSkills(prev => [...prev, skill]);
                                    setSkill("");
                                }
                            }}
                            aria-label="Skill input"
                            required
                            autoComplete="off"
                        />
                        {/* Conditionally render the error message */}
                        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}

                        <button
                            className='bg-[#01132b] hover:bg-[#1a2b40] text-white px-2 lg:px-5 py-1 lg:py-2 rounded-sm mt-2'
                            onClick={handleAddSkill}
                        >
                            Add
                        </button>
                        <button
                            className='bg-[#eb4934] hover:bg-[#d43b2e] text-white mx-2 px-2 lg:px-5 py-1 lg:py-2 rounded-sm mt-2'
                            onClick={handleResetSkills}
                        >
                            Reset
                        </button>

                    </div>

                    {
                        skills.length === 0 && <div className='text-center w-full lg:w-1/2'>
                            <h1>Your skills will be shown here.</h1>
                        </div>
                    }
                    {
                        skills.length !== 0 && <div className='px-0 lg:px-4 w-full lg:w-1/2 flex flex-wrap gap-2'>

                            {skills.map(skill => <div className='px-4 py-2 w-fit h-fit rounded-lg text-white bg-blue-500'>{skill}</div>)}

                        </div>

                    }

                </div>
            </div>
        </>
    )
}

export default SkillsDetails