import React from 'react';
import { Helmet } from 'react-helmet-async';
import Logo from "../logo.png";

export default function SEO({ title, description, name, type, path, image }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name="description" content={description} />

            { /* Open Graph (Facebook, LinkedIn, etc.) */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={process.env.REACT_APP_FRONTEND_URL + path} />
            <meta property="og:image" content={image || Logo} />

            { /* Twitter */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image || Logo} />

            { /* Pinterest */}
            <meta property="pinterest-rich-pin" content="true" />
            <meta property="og:image" content={image || Logo} />
            <meta property="og:description" content={description} />

            { /* Other potential tags */}
            <meta name="application-name" content={name} />
            <meta name="theme-color" content="#ffffff" />
        </Helmet>
    );
}
