import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faUser,
  faEnvelope,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faEllipsisV,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import EditBranchModal from "./branchComponent/editbranchmodalform"; // Import the new EditBranchModal component
import axiosInstance from "../../../api/axiosInstance";
import AddBranch from "./branchComponent/addBranch";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../../context/authContext";

const ITEMS_PER_PAGE = 6;

const Branches = () => {
  const { accessToken } = useAuth();
  const [branches, setBranches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const dropdownRef = useRef(null);

  const totalPages = Math.ceil(branches.length / ITEMS_PER_PAGE);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDropdownToggle = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const handleEditClick = (branch) => {
    setEditData(branch);
    setIsEditOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditOpen(false);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteClick = async (id) => {
    const branchId = id;
    // Add your save logic here
    const toastLoading = toast.loading("Deleting Please wait...");

    try {
      const response = await axiosInstance.delete(`/api/users/branches/${branchId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 204) {
        const newBranches = branches.filter(branch => branch.id !== branchId);
        setBranches(newBranches);
        toast.dismiss(toastLoading);
        toast.success("Branch deleted successfully");
      }
    } catch (error) {

      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            errors[field].forEach(error => toast.error(error + " in " + field));
          }
        }
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      toast.dismiss(toastLoading);
    }
  }

  const handleEditSave = async (updatedBranch, id) => {
    // edit logic here
    const editedBranches = branches.map(branch => branch.id === id ? { ...updatedBranch, id: branch.id } : branch);
    setBranches(editedBranches);
  }


  useEffect(() => {
    axiosInstance.get('/api/users/branches/').then(response => {
      setBranches(response.data.data);
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const displayedBranches = branches.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  return (
    <div className="max-w-7xl mx-auto p-6">
      <header className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl sm:text-3xl font-bold">Your Branches</h2>
        <button
          onClick={() => setIsModalOpen(true)} // Open modal for adding a branch
          className="bg-[#01132b] text-white rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-blue-600 mt-4 sm:mt-0"
        >
          <FontAwesomeIcon icon={faPlus} />
          Add Branch
        </button>
      </header>
      <div className="rounded-lg shadow-lg border border-gray-200">
        {displayedBranches.map((branch) => (
          <div
            key={branch.id}
            className="border-b border-orange-200 p-4 sm:p-6 flex flex-wrap md:flex-nowrap items-start relative"
          >
            {/* Branch Details */}
            <div className="flex-grow mb-4 md:mb-0 md:w-1/2">
              <div className="flex items-center mb-4">
                <h3 className="text-lg sm:text-xl font-semibold flex-grow">
                  {branch.branch_name}
                </h3>
                <button
                  onClick={() => handleDropdownToggle(branch.id)}
                  className="bg-blue-500 text-white rounded-full p-2 text-xs hover:bg-blue-600 absolute top-2 right-1"
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                  {dropdownOpen === branch.id && (
                    <ul
                      ref={dropdownRef}
                      className="absolute top-9 right-0 bg-white border border-gray-300 rounded-lg shadow-lg w-32 z-10"
                    >
                      <li
                        onClick={() => handleEditClick(branch)}
                        className="px-2 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                        Edit
                      </li>
                      <li
                        onClick={() => handleDeleteClick(branch.id)}
                        className="px-2 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2">
                        <FontAwesomeIcon icon={faTrash} />
                        Delete
                      </li>
                    </ul>
                  )}
                </button>
              </div>
              <p className="text-gray-700 mb-2 flex items-center text-sm sm:text-base">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                <a
                  href={branch.map_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {branch.city}
                </a>
              </p>
              <p className="text-gray-600 mb-4 text-sm sm:text-base">
                {branch.desc}
              </p>
              <div className="text-left text-sm sm:text-base">
                <p className="text-gray-800 font-semibold flex items-center mb-1">
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  Branch Manager:
                  <span className="text-gray-600 ml-1">{branch.manager_name}</span>
                </p>
                <p className="text-gray-800 font-semibold flex items-center mb-1">
                  <FontAwesomeIcon icon={faPhone} className="mr-2" />
                  Phone:
                  <span className="text-gray-600 ml-1">{branch.phone_number}</span>
                </p>
                <p className="text-gray-800 font-semibold flex items-center">
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                  Email:
                  <span className="text-gray-600 ml-1">{branch.email}</span>
                </p>
              </div>
            </div>
            {/* Map View */}
            <div className="w-full md:w-1/2 h-64 bg-gray-200 rounded-lg overflow-hidden">
              <iframe
                src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
                  branch.map_link
                )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title={`Map of ${branch.branch_name}`}
              ></iframe>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`bg-gray-300 text-gray-700 rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-[#01132b] hover:text-white ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="bg-[#01132b] text-white rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-blue-600"
        >
          Next
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {isModalOpen && (
        <AddBranch
          branches={branches}
          setBranches={setBranches}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}

      {
        isEditOpen && (
          <EditBranchModal
            branch={editData}
            onSave={handleEditSave}
            isOpen={isEditOpen}
            onClose={handleEditModalClose}
          />
        )
      }
      <Toaster />
    </div>
  );
};

export default Branches;
