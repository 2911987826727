import React, { useState } from 'react'
import { useCV } from '../../../context/cvbuilder/hooks/useCV';

const LanguageDetails = () => {
    const { languageDetails, setLanguageDetails } = useCV();
    const [language, setLanguage] = useState();
    const [error, setError] = useState(""); // State for error message

    const handleAddLanguage = () => {
        if (language && language.trim() !== "") {
            setLanguageDetails(prev => [...prev, language]);  // Add skill to list
            setLanguage("");                         // Clear input
            setError("");                         // Clear error message
        } else {
            setError("Null value is not accepted.");        // Show error message
        }
    };

    const handleResetLanguageDetails = () => {
        setLanguageDetails([]);  // Clear the skills array
        setLanguage("");   // Clear the input field
        setError("");   // Clear any error message
    };

    return (
        <>
            <div className="p-4 border bg-gray-100 rounded-md mt-2">
                <h2 className="text-xl md:text-2xl font-semibold mb-2">Languages</h2>


                <div className="grid grid-cols-1 lg:flex justify-between">
                    {/* Languages */}
                    <div className="mb-4 w-full lg:w-1/2">
                        <input type="text" placeholder='Add languages that you are comfortable with'
                            className='px-2 py-2 focus:ring-1 border-none focus:ring-blue-500 focus:outline-none block w-full'
                            value={language}
                            name='language'
                            onChange={e => setLanguage(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === 'Enter' && language.trim() !== "") {
                                    setLanguageDetails(prev => [...prev, language]);
                                    setLanguage("");
                                }
                            }}
                            aria-label="Language input"
                            required
                            autoComplete="on" />
                        {/* Conditionally render the error message */}
                        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}

                        <button
                            className='bg-[#01132b] hover:bg-[#1a2b40] text-white px-2 lg:px-5 py-1 lg:py-2 rounded-sm mt-2'
                            onClick={handleAddLanguage}
                        >
                            Add
                        </button>
                        <button
                            className='bg-[#eb4934] hover:bg-[#d43b2e] text-white mx-2 px-2 lg:px-5 py-1 lg:py-2 rounded-sm mt-2'
                            onClick={handleResetLanguageDetails}
                        >
                            Reset
                        </button>
                    </div>

                    {
                        languageDetails.length === 0 && <div className='text-center w-full lg:w-1/2'>
                            <h1>Your language details will be shown here.</h1>
                        </div>
                    }
                    {
                        languageDetails.length !== 0 && <div className='px-0 lg:px-4 w-full md:w-1/2 flex flex-wrap gap-2'>

                            {languageDetails.map(language => <div className='px-4 py-2 w-fit h-fit rounded-lg text-white bg-blue-500'>{language}</div>)}

                        </div>

                    }

                </div>
            </div>
        </>
    )
}

export default LanguageDetails