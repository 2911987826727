import React from 'react'
import SEO from '../../../components/SEO';

function ScholarshipsAtEurope() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <SEO
        title={"Study Abroad | Europe Scholarships"}
        description={
          "Types of Scholarships Merit-Based Scholarships: Awarded based on academic performance, achievements, and leadership. Need-Based Scholarships: Aimed at students from low-income backgrounds to support their education costs. Program-Specific Scholarships: Available for specific fields of study or academic programs. Country-Specific Scholarships: Offered to students from particular countries or regions to promote international education.Erasmus+ Program: Provides funding for students to study abroad within Europe as part of their degree."
        }
        name={"Study Abroad Europe Scholarships"}
        type={"website"}
        path={"/study-abroad/europe/scholarships/"}
      />
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Scholarships</h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Types of Scholarships</h2>
        <ul className="list-disc pl-5 text-gray-700">
          <li className="mb-2">
            <strong>Merit-Based Scholarships:</strong> Awarded based on academic performance, achievements, and leadership.
          </li>
          <li className="mb-2">
            <strong>Need-Based Scholarships:</strong> Aimed at students from low-income backgrounds to support their education costs.
          </li>
          <li className="mb-2">
            <strong>Program-Specific Scholarships:</strong> Available for specific fields of study or academic programs.
          </li>
          <li className="mb-2">
            <strong>Country-Specific Scholarships:</strong> Offered to students from particular countries or regions to promote international education.
          </li>
          <li className="mb-2">
            <strong>Erasmus+ Program:</strong> Provides funding for students to study abroad within Europe as part of their degree.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Top Scholarships for International Students</h2>
        <ul className="list-disc pl-5 text-gray-700">
          <li className="mb-2">
            <strong>EU Erasmus+ Scholarships:</strong> Offers financial support for students studying in European countries.
          </li>
          <li className="mb-2">
            <strong>DAAD Scholarships:</strong> Funded by the German Academic Exchange Service for international students to study in Germany.
          </li>
          <li className="mb-2">
            <strong>Swedish Institute Scholarships:</strong> Provides scholarships for students from developing countries studying in Sweden.
          </li>
          <li className="mb-2">
            <strong>Chevening Scholarships:</strong> UK government-funded scholarships for outstanding international students to study in the UK.
          </li>
          <li className="mb-2">
            <strong>French Government Scholarships:</strong> Supports international students studying in France.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ScholarshipsAtEurope;