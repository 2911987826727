import React from "react";
import { Outlet } from "react-router-dom";
import NewZealandTabs from "./navNewZealand";
import SEO from "../../../components/SEO";
function StudyInNewZealand() {
  return (
    <div className="max-w-7xl mx-auto p-4">
      <SEO
        title={"Study Abroad | New Zealand"}
        description={
          "Why Study in New Zealand.New Zealand offers a unique educational experience with its high-quality institutions, stunning landscapes, and welcoming atmosphere. Known for its innovative teaching methods and research opportunities, New Zealand provides students with a comprehensive and enriching academic environment."
        }
        name={"Study Abroad New Zealand"}
        type={"website"}
        path={"/study-abroad/new-zealand"}
      />
      <NewZealandTabs />
      <Outlet />
    </div>
  );
}

export default StudyInNewZealand;
