import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUser,
    faEllipsisVertical,
    faEdit,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../../../context/authContext";
import Modal from "../modal";
import axiosInstance from "../../../../api/axiosInstance";


const TeamMember = ({
    id,
    name,
    address,
    phone_number,
    position,
    branch,
    branchName,
    email,
    photo,
    members,
    setMembers,
    branchData,
}) => {
    const [values, setValues] = useState({
        name: name,
        address: address,
        phone_number: phone_number,
        position: position,
        branch: branch,
        email: email,
    });
    const [editModal, setEditModal] = useState(false);
    const [editPhoto, setEditPhoto] = useState({
        imagePreview: photo,
        imageFile: null,
    })

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setEditPhoto({
                imagePreview: URL.createObjectURL(file),
                imageFile: file
            })
        }
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const { accessToken } = useAuth();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleEdit = async (e) => {
        e.preventDefault();
        const data = {
            name: values.name,
            address: values.address,
            phone_number: values.phone_number,
            position: values.position,
            branch: Number(values.branch),
            email: values.email,
            photo: editPhoto.imageFile
        }
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('address', data.address);
        formData.append('phone_number', data.phone_number);
        formData.append('position', data.position);
        formData.append('branch', data.branch);
        formData.append('email', data.email);
        if (data.photo) {
            formData.append('photo', data.photo);
        }
        const toastLoading = toast.loading("Submitting...");

        try {
            // Attach the token to the headers for authorization
            const response = await axiosInstance.patch(
                `/api/users/team/team-members/${id}/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`, // Attach the token in the Authorization header
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.status === 200) {
                toast.dismiss(toastLoading);
                toast.success("Successfully submitted");

                const memberIndex = members.findIndex(member => member.id === id);
                const branchName = branchData.find(branch => branch.id === response.data.branch).branch_name;

                const newMembers = members.map((member, index) => {
                    if (index === memberIndex) {
                        return { ...response.data, branchName };
                    }
                    return member;
                })

                setMembers(newMembers);

                // Close the modal after successful submission
                setEditModal(false);
            }
        } catch (error) {
            toast.dismiss(toastLoading);
            if (error.response && error.response.data) {
                const errors = error.response.data;
                for (const field in errors) {
                    if (errors.hasOwnProperty(field)) {
                        errors[field].forEach(error => toast.error(error + " in " + field));
                    }
                }
            } else {
                toast.error("Something went wrong!");
            }
        }
    };

    const handleDelete = async (id) => {
        // Implement delete functionality here
        const toastLoading = toast.loading("Deleting please wait...");
        try {
            const response = await axiosInstance.delete(`/api/users/team/team-members/${id}/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (response.status === 204) {
                const newMembers = members.filter(member => member.id !== id);
                setMembers(newMembers);
                toast.dismiss(toastLoading);
                toast.success("Member deleted successfully");
            }
        } catch (error) {
            toast.error("Something went wrong!");
            console.log(error)
        } finally {
            toast.dismiss(toastLoading);
        }
    };
    return (
        <>
            <div className="relative bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md flex flex-col hover:shadow-lg transition-shadow">
                <div className="p-4 flex flex-col flex-grow">
                    <div className="relative cursor-pointer">
                        <button
                            onClick={toggleMenu}
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <FontAwesomeIcon
                                icon={faEllipsisVertical}
                                className="text-gray-500 text-2xl"
                            />
                        </button>
                        {isMenuOpen && (
                            <div
                                ref={menuRef}
                                className="absolute right-0 mt-2 bg-white w-[150px] rounded shadow-lg z-10"
                            >
                                <ul className="absolute top-9 right-0 bg-white border border-gray-300 rounded-lg shadow-lg w-32 z-10">
                                    <li
                                        onClick={() => setEditModal(true)}
                                        className="px-2 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                        Edit
                                    </li>
                                    <li
                                        onClick={() => handleDelete(id)}
                                        className="px-2 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                        Delete
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="flex justify-center items-center mb-4 flex-grow">

                        {photo ? (
                            <img
                                src={photo}
                                alt={name || "Member"}
                                className="size-32 rounded-full object-cover mr-3"
                            />
                        ) : (
                            <div className="size-32 flex items-center justify-center bg-gray-200 rounded-full mr-3">
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className="text-gray-500 text-5xl"
                                />
                            </div>
                        )}
                    </div>


                    <div className="flex justify-center items-center mb-4">

                        <div>
                            <strong className="block capitalize text-xl">
                                {name || "Member"}
                            </strong>

                        </div>
                    </div>

                    <small className="text-gray-500 mb-2 text-base mt-2">Address: <span className="font-bold">{address}</span></small>
                    <small className="text-gray-500 mb-2 text-base mt-2">Position: <span className="font-bold">{position}</span></small>
                    <small className="text-gray-500 mb-2 text-base mt-2">Phone Number: <span className="font-bold">{phone_number}</span></small>
                    <small className="text-gray-500 text-base mb-2 mt-2">Email: <span className="font-bold">{email}</span></small>
                    <small className="text-gray-500 text-base mb-2 mt-2">Branch Name: <span className="font-bold">{branchName}</span></small>

                </div>
                <Toaster />
            </div>
            <Modal onClose={() => setEditModal(false)} isOpen={editModal}>
                <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg">
                    <h2 className="text-2xl font-bold mb-6">Edit Member Details</h2>
                    <form onSubmit={handleEdit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name='name'
                                value={values.name}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="address">Address</label>
                            <input
                                type="text"
                                id="address"
                                name='address'
                                value={values.address}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="phone_number">Phone Number</label>
                            <input
                                type="text"
                                id="phone_number"
                                name='phone_number'
                                value={values.phone_number}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="position">Position</label>
                            <input
                                type="tel" // Changed to "tel" to avoid number increment/decrement buttons
                                id="position"
                                name='position'
                                value={values.position}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="branch">Branch</label>
                            <select name="branch" id="branch"
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                                defaultValue={values.branch}
                                required
                                onChange={(event) => {
                                    setValues({ ...values, branch: event.target.value })
                                }}>
                                <option value="" disabled>Select Branch</option>
                                {
                                    branchData.map(branch => (
                                        <option key={branch.id} value={branch.id}>{branch.branch_name}</option>
                                    ))
                                }
                            </select>

                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="email">Email</label>
                            <input
                                type="email" // Changed to "tel" to avoid number increment/decrement buttons
                                id="email"
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                            />
                        </div>

                        <div>
                            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="mapLink">Photo</label>
                            <input
                                type="file"
                                id="photo"
                                name='photo'
                                onChange={handleFileChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                            />

                        </div>
                        <div className='self-center w-full flex justify-center'>
                            {
                                editPhoto.imagePreview ? (
                                    <img src={editPhoto.imagePreview} alt="preview" className="size-48 object-cover rounded-full mt-2" />
                                ) : (
                                    <div className="size-48 bg-gray-400/50 text-gray-600 mt-2 flex items-center justify-center rounded-full" >
                                        <p>No Image Choosen</p>
                                    </div>
                                )
                            }
                        </div>


                        <div className="col-span-1 md:col-span-2 flex justify-end gap-4 mt-4">
                            <button
                                type="button"
                                onClick={() => setEditModal(false)}
                                className="bg-gray-500 text-white rounded-full px-4 py-2 text-xs md:text-sm hover:bg-gray-600"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className={`rounded-full px-4 py-2 text-xs md:text-sm bg-[#01132b] text-white flex items-center gap-2 hover:bg-blue-600 mt-4 sm:mt-0`}
                            >
                                Edit Member
                            </button>
                        </div>
                    </form>
                </div>
                <Toaster />
            </Modal>
        </>
    );
};

export default TeamMember;
