// Client Name or HMIS ID:
// Date of Income Evaluation:
// Relevant Third Party’s Name:
// Relevant Third Party’s Organization:
// Relevant Third Party’s Position/Title:
// Relevant Third Party’s Contact Information:
// Date of Contact with Third Party:
// Amount of income received by the client during the most recent time period:
// Most recent time period:

import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import thirdPartyImage from "../../../../images/third-party-income.png";


const ThirdPartyOralIncomeVerification = () => {
  const previewRef = useRef();
  const [information, setInformation] = useState({
    agencyName: "",
    clientNameOrHMISId: "",
    incomeValuationDate: "",
    thirdPartyName: "",
    thirdPartyOrganization: "",
    thirdPartyPosition: "",
    thirdPartyContactInformation: "",
    dateOfContactThirdParty: "",
    incomeReceivedByClient: "",
    mostRecentTimePeriod: "",
  });

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "third_party_oral_income",
    pageStyle: `
            @page { 
                size: A4; 
            }`,
  });

  return (
    <>
      <NavigationBreadcrumb
        slug={"third-party-income-verification"}
        name={"Third Party Income Verification"}
      />
      <SEO
        title={"Third Party Income Verification"}
        description={
          "Build your documents, and manage your applications with ease."
        }
        name={"Third Party Income Verification"}
        type={"website"}
        image={thirdPartyImage}
        path={"/tools/documents/third-party-income-verification"}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <div>
          <h1 className="text-2xl md:text-3xl mb-4 font-semibold font-montserrat">
            Third Party Income Verification{" "}
          </h1>

          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad text-sm lg:text-lg gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/* Agency Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Agency Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="agencyName"
                  value={information.agencyName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: [id]"
                  aria-label="Agency Name input"
                  autoComplete="off"
                />
              </div>
              {/* client name or id  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Client Name or HMIS ID
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="clientNameOrHMISId"
                  value={information.clientNameOrHMISId}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: [id]"
                  aria-label="Client Name or HMIS ID input"
                  autoComplete="off"
                />
              </div>
              {/* Income Valuation Date  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Income Valuation Date
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="incomeValuationDate"
                  value={information.incomeValuationDate}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 2020-06-20"
                  aria-label="Income Valuation Date input"
                  autoComplete="off"
                />
              </div>
              {/* Third Party Name  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Third Party Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="thirdPartyName"
                  value={information.thirdPartyName}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Yuvraj Basnet"
                  aria-label="Third Party Name input"
                  autoComplete="off"
                />
              </div>
              {/* Third Party Organization  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Third Party Organization.
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="thirdPartyOrganization"
                  value={information.thirdPartyOrganization}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: abc Organization"
                  aria-label="Third Party Organization input"
                  autoComplete="off"
                />
              </div>
              {/* Third Party Position */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Third Party Position
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="thirdPartyPosition"
                  value={information.thirdPartyPosition}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: [position]"
                  aria-label="Third Party Position input"
                  autoComplete="off"
                />
              </div>
              {/* Third Party Contact Information  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Third Party Contact Information
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="thirdPartyContactInformation"
                  value={information.thirdPartyContactInformation}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: [contact info]"
                  aria-label="Third Party Contact Information input"
                  autoComplete="off"
                />
              </div>
              {/* Date of contact third party */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Date of Contact Third Party
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="dateOfContactThirdParty"
                  value={information.dateOfContactThirdParty}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 2014-06-20"
                  aria-label="Date of Contact Third Party input"
                  autoComplete="off"
                />
              </div>
              {/* Income Received by client  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  (Income received by client in $)
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="number"
                  name="incomeReceivedByClient"
                  value={information.incomeReceivedByClient}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: 50000"
                  aria-label="Income Received by client input"
                  autoComplete="off"
                />
              </div>

              {/* Most recent time period  */}
              <div className="mb-4 w-full">
                <label className="block text-sm md:text-lg font-medium">
                  Most recent time period
                  <span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="mostRecentTimePeriod"
                  value={information.mostRecentTimePeriod}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: [recent time]"
                  aria-label="Most recent time period input"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 border bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-10">Document Preview</h2>
          <div
            ref={previewRef}
            className="p-4 A4-size font-montserrat overflow-hidden"
          >
            <div className="uppercase font-bold text-center">
              <h6>
                {information.agencyName
                  ? information.agencyName
                  : "[Insert Agency Name]"}
              </h6>
              <h6>THIRD-PARTY ORAL CERTIFICATION OF INCOME</h6>
            </div>
            <div className="pt-5 text-sm">
              <p>
                <span className="font-bold">Instructions:</span> If annual
                income source documents (e.g., most recent wage statement,
                unemployment compensation statement, public benefits statement,
                bank statement) are not available, a relevant third party (e.g.,
                employer, government benefits administrator) may provide a
                written or oral statement of the income the program participant
                received over the most recent period. This form may be used to
                certify an oral verification of income from a third party.{" "}
              </p>
            </div>
            <div className="text-sm pt-5 space-y-5">
              <p className="font-bold">
                Client Name or HMIS
                ID:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {information.clientNameOrHMISId
                  ? information.clientNameOrHMISId
                  : "..............................................................................................................................................."}
              </p>
              <p>
                Date of Income Evaluation:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {information.incomeValuationDate
                  ? information.incomeValuationDate
                  : "....................................................................................."}
              </p>
              <div className="h-0.5 w-full bg-black"></div>
              <p>
                Relevent Third Party's
                Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {information.thirdPartyName
                  ? information.thirdPartyName
                  : "..........................................................................................................................................."}
              </p>
              <p>
                Relevant Third Party’s
                Organization:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {information.thirdPartyOrganization
                  ? information.thirdPartyOrganization
                  : "..........................................................................................................................................."}
              </p>
              <p>
                Relevant Third Party’s
                Position/Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {information.thirdPartyPosition
                  ? information.thirdPartyPosition
                  : "..........................................................................................................................................."}
              </p>
              <p>
                Relevant Third Party’s Contact
                Information:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {information.thirdPartyContactInformation
                  ? information.thirdPartyContactInformation
                  : "..........................................................................................................................................."}
              </p>
              <p>
                Date of Contact with Third
                Party:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {information.dateOfContactThirdParty
                  ? information.dateOfContactThirdParty
                  : "..........................................................................................................."}
              </p>
              <p>
                Amount of income received by the client during the most recent
                time period: $&nbsp;
                {information.incomeReceivedByClient
                  ? information.incomeReceivedByClient
                  : "..........................................................."}
              </p>
              <p>
                Most recent time period:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {information.mostRecentTimePeriod
                  ? information.mostRecentTimePeriod
                  : "........................................................ to ........................................................"}{" "}
              </p>
              <div className="h-0.5 w-full bg-black"></div>
              <p className="font-bold text-base">Staff Supplement</p>
              <p>
                I certify the above-stated information was provided by the
                relevant third party listed. I understand that collecting source
                documents is the preferred method of documenting annual income
                for an individual or household who is receiving housing
                assistance where rent or an occupancy charge is paid by the
                program participant. I understand certification by intake staff
                of third-party oral verification is only permitted when I have
                attempted to but cannot obtain the source documents.
              </p>
              <div>
                <p className="font-bold">
                  Documentation of attempt(s) to obtain third-party verification
                </p>
                <p>
                  Please list the attempts to obtain the source documentation of
                  income from a relevant third party (e.g., employer, government
                  benefits administrator):
                </p>
              </div>

              <div className="space-y-8 pt-3">
                <div className="h-0.5 w-full bg-black"></div>
                <div className="h-0.5 w-full bg-black"></div>
                <div className="h-0.5 w-full bg-black"></div>
                <div className="h-0.5 w-full bg-black"></div>
              </div>

              <div className="space-y-4">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <p>
                  Staff’s Signature:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...............................................................................................
                  &nbsp;&nbsp;&nbsp;Date:
                  .....................................................................................{" "}
                </p>
                <p>
                  Staff's Name:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...............................................................................................{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handlePrint();
          }}
          className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
        // disabled={!checkFields}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default ThirdPartyOralIncomeVerification;
