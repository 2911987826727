import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api/axiosInstance';

const ArticleSidebar = () => {
  const [featuredPage, setFeaturedPage] = useState(1);
  const [recentPage, setRecentPage] = useState(1);
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [featuredArticlesLoading, setFeaturedArticlesLoading] = useState(true);
  const [featuredArticlesError, setFeaturedArticlesError] = useState(null);
  const [recentArticles, setRecentArticles] = useState([]);
  const [recentArticlesLoading, setRecentArticlesLoading] = useState(true);
  const [recentArticlesError, setRecentArticlesError] = useState(null);
  const [users, setUsers] = useState({}); // State to hold author usernames
  const articlesPerPage = 3;

  useEffect(() => {
    // Fetch articles
    axiosInstance.get('/api/users/articles/').then(res => {
      const articles = res.data.data;

      // Set featured and recent articles
      setFeaturedArticles(articles.filter(article => article.featured));
      setFeaturedArticlesLoading(false);

      setRecentArticles(articles.sort((a, b) => new Date(b.date) - new Date(a.date)));
      setRecentArticlesLoading(false);

      // Extract unique author IDs from the articles
      const authorIds = [...new Set(articles.map(article => article.author))];

      // Fetch each author's username
      Promise.all(
        authorIds.map(id => axiosInstance.get(`/api/users/users/${id}/`)) // Corrected API endpoint syntax
      )
        .then(userData => {
          const userMap = {};
          userData.forEach(userResponse => {
            const userDetail = userResponse.data;
            userMap[userDetail.id] = userDetail.username; // Store the username against the user ID
          });
          setUsers(userMap); // Set all users fetched
        })
        .catch(error => console.error("Error fetching user data:", error));
    }).catch(error => {
      setFeaturedArticlesError("Failed to fetch Featured Articles.")
      setRecentArticlesError("Failed to fetch Recent Articles.")
      console.log(error);
    });
  }, []);

  // Filter and paginate featured articles
  const paginatedFeatured = featuredArticles.slice((featuredPage - 1) * articlesPerPage, featuredPage * articlesPerPage);
  const paginatedRecent = recentArticles.slice((recentPage - 1) * articlesPerPage, recentPage * articlesPerPage);

  // Function to truncate text
  const truncateText = (text, length) => {
    return text.length > length ? text.slice(0, length) + '...' : text;
  };

  return (
    <aside className="w-full md:w-[300px] p-4 bg-white shadow-lg rounded-lg">
      {/* Featured Articles Section */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4 border-b border-gray-300 pb-2 text-center">Featured Articles</h2>
        {
          featuredArticlesLoading && !featuredArticlesError && paginatedFeatured.length === 0 ? (
            <div className="flex justify-center items-center h-20  text-gray-500 text-sm md:text-base col-span-full">
              <p>Loading Featured Articles ....</p>
            </div>
          ) : featuredArticlesError && !featuredArticlesLoading && paginatedFeatured.length === 0 ? (
            <div className="flex justify-center items-center h-20  text-gray-500 text-sm md:text-base col-span-full">
              <p>{featuredArticlesError}</p>
            </div>
          ) : !featuredArticlesError && !featuredArticlesLoading && paginatedFeatured.length > 0 ? (
            <>
              <ul className="space-y-4">
                {paginatedFeatured.map(article => (
                  <li key={article.id} className="text-center">
                    <a
                      href={`/article/${article.id}`}
                      className="text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-300"
                    >
                      {truncateText(article.title, 60)}
                    </a>
                    <p className="text-sm text-gray-600 mt-1">
                      {users[article.author] || 'Unknown Author'} - {new Date(article.date).toLocaleDateString()}
                    </p>
                  </li>
                ))}
              </ul>
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={() => setFeaturedPage(prev => Math.max(prev - 1, 1))}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors duration-300"
                  disabled={featuredPage === 1}
                >
                  &lt;
                </button>
                <span className="text-sm">Page {featuredPage}</span>
                <button
                  onClick={() => setFeaturedPage(prev => Math.min(prev + 1, Math.ceil(featuredArticles.length / articlesPerPage)))}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors duration-300"
                  disabled={featuredPage === Math.ceil(featuredArticles.length / articlesPerPage)}
                >
                  &gt;
                </button>
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center h-20  text-gray-500 text-sm md:text-base col-span-full">
              <p>No Featured Articles Found</p>
            </div>
          )
        }

      </section>

      {/* Recent Articles Section */}
      <section>
        <h2 className="text-xl font-semibold mb-4 border-b border-gray-300 pb-2 text-center">Recent Articles</h2>
        {
          recentArticlesLoading && !recentArticlesError && paginatedRecent.length === 0 ? (
            <div className="flex justify-center items-center h-20  text-gray-500 text-sm md:text-base col-span-full">
              <p>Loading Recent Articles ....</p>
            </div>
          ) : recentArticlesError && !recentArticlesLoading && paginatedRecent.length === 0 ? (
            <div className="flex justify-center items-center h-20  text-gray-500 text-sm md:text-base col-span-full">
              <p>{recentArticlesError}</p>
            </div>
          ) : !recentArticlesError && !recentArticlesLoading && paginatedRecent.length > 0 ? (
            <>
              <ul className="space-y-4">
                {paginatedRecent.map(article => (
                  <li key={article.id} className="text-center">
                    <a
                      href={`/article/${article.id}`}
                      className="text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-300"
                    >
                      {truncateText(article.title, 60)}
                    </a>
                    <p className="text-sm text-gray-600 mt-1">
                      {users[article.author] || 'Unknown Author'} - {new Date(article.date).toLocaleDateString()}
                    </p>
                  </li>
                ))}
              </ul>
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={() => setRecentPage(prev => Math.max(prev - 1, 1))}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors duration-300"
                  disabled={recentPage === 1}
                >
                  &lt;
                </button>
                <span className="text-sm">Page {recentPage}</span>
                <button
                  onClick={() => setRecentPage(prev => Math.min(prev + 1, Math.ceil(recentArticles.length / articlesPerPage)))}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors duration-300"
                  disabled={recentPage === Math.ceil(recentArticles.length / articlesPerPage)}
                >
                  &gt;
                </button>
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center h-20  text-gray-500 text-sm md:text-base col-span-full">
              <p>No Recent Articles Found</p>
            </div>
          )
        }
      </section>
    </aside>
  );
};

export default ArticleSidebar;
