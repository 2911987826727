import Nav from "./components/nav/nav";
import Header from "./components/nav/header";
import Footer from "./components/footer/footer";
import PrimaryRoutes from "./routes/primaryRoutes";
import "./App.css";
import CvState from "./context/cvbuilder/cvState";
import ScrollToTop from "./components/scrolltop";

function App() {
  return (
  <>
    <ScrollToTop />
    <Header />
    <Nav />
    <CvState>
      <PrimaryRoutes />
    </CvState>
    <Footer />
    </>
  );
}

export default App;
