import Branches from "./branchcards";
import ArticlesCards from "./articlecards";

import React from 'react'

const Dashhome = () => {
  return (
<div className="min-h-screen bg-gray-100">
      <div className="">
      
        <Branches />
        <ArticlesCards />
      </div>
    </div>  )
}

export default Dashhome