import { useContext } from "react";
import { cvContext } from "../cvContext";

export const useCV = ()=> {
    const context = useContext(cvContext);

    if(!context){
        throw new Error("Context not found")
    }else{
        return context;
    }
}