import React, { useState } from 'react';
import destinations from './../data/destinationdata'; // Adjust the path if necessary
import axiosInstance from '../api/axiosInstance';
import toast, { Toaster } from 'react-hot-toast';
import SEO from '../components/SEO';

const initialState = {
  business_name: "",
  address: "",
  city: "",
  state_country: "",
  postcode: "",
  website: "",
  email: "",
  company_linkedin: "",
  title: "Mr",
  first_name: "",
  family_name: "",
  position_title: "",
  contact_email: "",
  function_role: "Admin Support",
  linkedin: "",
};

const WorkWithUs = () => {
  const [values, setValues] = useState(initialState);


  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  const [selectedCountry, setSelectedCountry] = useState('Australia');
  const [otherCountry, setOtherCountry] = useState('');

  const [selectedContactCountry, setSelectedContactCountry] = useState('Australia');
  const [otherContactCountry, setOtherContactCountry] = useState('');



  const handleContactCountryChange = (event) => {
    const value = event.target.value;
    setSelectedContactCountry(value);

    if (value !== 'Other') {
      setOtherContactCountry('');
    }
  };

  // Extract country names from destinations
  const countryNames = destinations.map(dest => dest.name);

  const handleCountryChange = (event) => {
    const value = event.target.value;
    setSelectedCountry(value);

    // Clear manual input if a specific country is selected
    if (value !== 'Other') {
      setOtherCountry('');
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastLoading = toast.loading("Submitting...");
    const getCountry = selectedCountry === "Other" ? otherCountry : selectedCountry;
    const getContactCountry = selectedContactCountry === "Other" ? otherContactCountry : selectedContactCountry;
    try {

      const response = await axiosInstance.post('/api/users/register/', { ...values, country: getCountry, contact_country: getContactCountry },);


      if (response.data.status === "success") {
        toast.dismiss(toastLoading);
        toast.success("Successfully submitted, Our team with contact you back as soon as possible.");
        setValues(initialState);
      }


    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            errors[field].forEach(error => toast.error(error + " in " + field));
          }
        }
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      toast.dismiss(toastLoading);
    }
  }

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <SEO
        title={"Work with us | AAEC Global"}
        description={"New Partner Registration If you are willing to work as AAEC Global partner, please complete the following form.Education providers and agents are welcome to work with us."}
        name={"Work with us"}
        type={"website"}
        path={"/work-with-us"}
      />
      <h2 className="text-2xl font-bold text-center text-[#eb4934] mb-4">New Partner Registration</h2>
      <p className="text-center text-lg mb-4">
        <strong>If you are willing to work as AAEC Global partner, please complete the following form.</strong>
      </p>
      <blockquote className="text-center text-sm italic mb-6">
        <p>Education providers and agents are welcome to work with us.</p>
      </blockquote>

      <form
        onSubmit={handleSubmit}
        noValidate
        className="space-y-6"
      >
        <h4 className="text-xl font-semibold mb-2">Company Details</h4>

        <label className="block">
          Business Name*<br />
          <input
            type="text"
            name="business_name"
            value={values.business_name}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Address*<br />
          <input
            type="text"
            name="address"
            value={values.address}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          City*<br />
          <input
            type="text"
            name="city"
            value={values.city}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          State/Country<br />
          <input
            type="text"
            name="state_country"
            value={values.state_country}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Postcode<br />
          <input
            type="number"
            name="postcode"
            value={values.postcode}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Country*<br />
          <select
            name="country"
            onChange={handleCountryChange}
            defaultValue={"Australia"}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            {countryNames.map((country) => (
              <option key={country} value={country}>{country}</option>
            ))}
            <option value="Other">Other</option>
          </select>
          {selectedCountry === 'Other' && (
            <input
              type="text"
              name="other-country"
              value={otherCountry}
              onChange={(e) => setOtherCountry(e.target.value)}
              placeholder="Please specify"
              className="w-full mt-2 p-2 border border-gray-300 rounded-md"
            />
          )}
        </label>

        <label className="block">
          Website*<br />
          <input
            type="url"
            name="website"
            value={values.website}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <p className="text-center text-sm mb-4">
          You can also add your Facebook or any other Social Media page of your company.
        </p>

        <label className="block">
          Email*<br />
          <input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Company's LinkedIn<br />
          <input
            type="url"
            name="company_linkedin"
            value={values.company_linkedin}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <h4 className="text-xl font-semibold mt-6 mb-2">Main Contact for all Correspondence</h4>

        <label className="block">
          Title*<br />
          <select
            name="title"
            onChange={handleChange}
            defaultValue={"Mr"}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Dr">Dr</option>
            <option value="Prof">Prof</option>
            <option value="Ms">Ms</option>
          </select>
        </label>

        <label className="block">
          First Name*<br />
          <input
            type="text"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Family Name*<br />
          <input
            type="text"
            name="family_name"
            onChange={handleChange}
            value={values.family_name}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Position/Job Title*<br />
          <input
            type="text"
            name="position_title"
            value={values.position_title}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Email*<br />
          <input
            type="email"
            name="contact_email"
            value={values.contact_email}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <label className="block">
          Country*<br />
          <select
            name="country"
            value={selectedContactCountry}
            onChange={handleContactCountryChange}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            {countryNames.map((country) => (
              <option key={country} value={country}>{country}</option>
            ))}
            <option value="Other">Other</option>
          </select>
          {selectedContactCountry === 'Other' && (
            <input
              type="text"
              name="other-country"
              value={otherContactCountry}
              onChange={(e) => setOtherContactCountry(e.target.value)}
              placeholder="Please specify"
              className="w-full mt-2 p-2 border border-gray-300 rounded-md"
            />
          )}
        </label>

        <label className="block">
          Function/Role*<br />
          <select
            name="function_role"
            onChange={handleChange}
            defaultValue={"Admin Support"}
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="Admin Support">Admin Support</option>
            <option value="Admissions">Admissions</option>
            <option value="Counsellor">Counsellor</option>
            <option value="Finance">Finance</option>
            <option value="Marketing/Web contact">Marketing/Web contact</option>
            <option value="Sales">Sales</option>
          </select>
        </label>

        <label className="block">
          LinkedIn<br />
          <input
            type="url"
            name="linkedin"
            value={values.linkedin}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </label>

        <button
          type="submit"
          className="w-full bg-[#eb4934] text-white py-2 rounded-md hover:bg-[#d43b2e] transition-colors"
        >
          Submit
        </button>
      </form>
      <Toaster />

    </div>
  );
};

export default WorkWithUs;
