import React from 'react'
import SEO from '../../../components/SEO';

function ScholarshipsAtNewZealand() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <SEO
        title={"Study Abroad | New Zealand Scholarships"}
        description={
          "Types of Scholarships Merit-Based Scholarships: Awarded based on academic excellence, leadership skills, and other achievements.Need-Based Scholarships: Designed for students who demonstrate financial need to help cover their education costs.Program-Specific Scholarships: Available for specific fields of study or academic programs.Country-Specific Scholarships: Offered to students from specific countries or regions to promote educational exchange. Research Grants: Provided for students engaged in research projects or postgraduate studies."
        }
        name={"Study Abroad New Zealand Scholarships"}
        type={"website"}
        path={"/study-abroad/new-zealand/scholarships/"}
      />
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Scholarships in New Zealand for International Students</h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Types of Scholarships</h2>
        <ul className="list-disc pl-5 text-gray-700">
          <li className="mb-2">
            <strong>Merit-Based Scholarships:</strong> Awarded based on academic excellence, leadership skills, and other achievements.
          </li>
          <li className="mb-2">
            <strong>Need-Based Scholarships:</strong> Designed for students who demonstrate financial need to help cover their education costs.
          </li>
          <li className="mb-2">
            <strong>Program-Specific Scholarships:</strong> Available for specific fields of study or academic programs.
          </li>
          <li className="mb-2">
            <strong>Country-Specific Scholarships:</strong> Offered to students from specific countries or regions to promote educational exchange.
          </li>
          <li className="mb-2">
            <strong>Research Grants:</strong> Provided for students engaged in research projects or postgraduate studies.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Top Scholarships for International Students</h2>
        <ul className="list-disc pl-5 text-gray-700">
          <li className="mb-2">
            <strong>New Zealand International Scholarships:</strong> Funded by the New Zealand government, covering tuition, living expenses, and travel costs.
          </li>
          <li className="mb-2">
            <strong>University of Auckland International Student Excellence Scholarship:</strong> Offers funding for outstanding international students at the University of Auckland.
          </li>
          <li className="mb-2">
            <strong>Victoria University of Wellington International Excellence Scholarship:</strong> Provides financial support for international students at Victoria University of Wellington.
          </li>
          <li className="mb-2">
            <strong>University of Otago International Masters Degree Scholarships:</strong> Available for international students pursuing a master’s degree at the University of Otago.
          </li>
          <li className="mb-2">
            <strong>Canterbury University International Scholarships:</strong> Awarded to international students based on academic merit and potential.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ScholarshipsAtNewZealand