import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBuilding,
  faUser,
  faBars,
  faTimes,
  faRightFromBracket,
  faNewspaper,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../context/authContext";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../../api/axiosInstance";

function Dashnavbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated, setUsername, accessToken } = useAuth();

  const toggleNav = () => setIsNavOpen((prev) => !prev);

  const handleLogout = async () => {
    try {
      const response = await axiosInstance.post("/api/users/checklogout/", {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      if (response.data.status === "success") {
        setIsAuthenticated(false);
        setUsername(null);
        setTimeout(() => {
          toast.success("Logged out.");

        }, 1000);
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("username");
        navigate("/admin/login"); // Redirect to login page

      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <>
      {/* Mobile Navigation Toggle Button */}
      <div className="md:hidden w-full bg-gray-800 text-white z-50">
        <div className="flex items-center justify-between p-4">
          <Link to="/" className="flex items-center text-white text-xl">
            {/* Your Logo or Brand Name Here */}
          </Link>
          <FontAwesomeIcon
            icon={isNavOpen ? faTimes : faBars}
            className="text-white text-2xl cursor-pointer"
            onClick={toggleNav}
            aria-label="Toggle Navigation"
          />
        </div>
      </div>

      {/* Navigation Bar */}
      <nav
        className={`w-full bg-[#01132b] text-white p-4 z-40 ${isNavOpen ? "block" : "hidden md:block"
          } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex items-center justify-between">
          <Link to="/" className="text-white text-xl">
            {/* Your Logo or Brand Name Here */}
          </Link>
          <div className="md:hidden">
            <FontAwesomeIcon
              icon={isNavOpen ? faTimes : faBars}
              className="text-white text-2xl cursor-pointer"
              onClick={toggleNav}
              aria-label="Toggle Navigation"
            />
          </div>
        </div>
        <div className="flex items-center justify-between mt-4 space-x-6">
          <ul
            className={`flex items-center space-x-6 ${isNavOpen ? "block" : "hidden md:flex"
              }`}
          >
            <li>
              <Link
                to="/admin/dashboard"
                className="flex items-center space-x-3 text-gray-300 hover:text-white"
              >
                <FontAwesomeIcon icon={faHome} />
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/admin/dashboard/branch"
                className="flex items-center space-x-3 text-gray-300 hover:text-white"
              >
                <FontAwesomeIcon icon={faBuilding} />
                <span>Branches</span>
              </Link>
            </li>

            <li className="relative">
              <Link
                to="/admin/dashboard/leads"
                className="flex items-center space-x-3 text-gray-300 hover:text-white"
              >
                <FontAwesomeIcon icon={faUser} />
                <span>Leads</span>
              </Link>
            </li>
            <li className="relative">
              <Link
                to="/admin/dashboard/articles"
                className="flex items-center space-x-3 text-gray-300 hover:text-white"
              >
                <FontAwesomeIcon icon={faNewspaper} />
                <span>Articles</span>
              </Link>
            </li>
            <li className="relative">
              <Link
                to="/admin/dashboard/team-members"
                className="flex items-center space-x-3 text-gray-300 hover:text-white"
              >
                <FontAwesomeIcon icon={faPeopleGroup} />
                <span>Team Members</span>
              </Link>
            </li>

          </ul>
          <ul
            className={`flex items-center space-x-6 ${isNavOpen ? "block" : "hidden md:flex"
              }`}
          >

            <li>
              <button
                onClick={handleLogout}
                className="flex items-center space-x-3 text-gray-300 hover:text-white bg-transparent border-none cursor-pointer"
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </nav>

      {/* Overlay when navigation is open on mobile */}
      {isNavOpen && (
        <div
          className="fixed inset-0 bg-gray-800 opacity-50 z-30 md:hidden"
          onClick={toggleNav}
          aria-label="Close Navigation"
        ></div>
      )}
      <Toaster />
    </>
  );
}

export default Dashnavbar;
