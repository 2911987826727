import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../api/axiosInstance';
import Datatable from './leadsDatatable/datatable';
import { useAuth } from '../../../context/authContext';

const LeadsPage = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);  // Add loading state
  const [error, setError] = useState(null);  // Add error state
  const { accessToken } = useAuth();

  useEffect(() => {
    axiosInstance.get('/api/users/leads/', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    })
      .then(res => {
        setLeads(res.data);
        setLoading(false);  // Set loading to false after data is fetched
      })
      .catch(error => {
        console.log(error);
        setError('Failed to load leads');  // Set error message
        setLoading(false);
      });
  }, [accessToken]);

  return (
    <div className='max-w-7xl mx-auto p-6'>
      <h1 className='text-3xl font-bold'>Leads List</h1>

      {loading && <div>Loading...</div>}  {/* Show loading message while data is being fetched */}
      {error && <div className="text-red-500">{error}</div>}  {/* Show error message if there's an issue */}

      {leads.length === 0 && !loading && !error && (
        <div>No leads available</div>  // Show message when there are no leads
      )}

      <div className='py-5'>
        <Datatable leads={leads} />
      </div>
    </div>
  );
}

export default LeadsPage;
