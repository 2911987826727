import React, { useState, useEffect } from 'react';
import Modal from '../modal'; // Import the Modal component
import toast from 'react-hot-toast';
import axiosInstance from '../../../../api/axiosInstance'; // Assuming axiosInstance is already configured

const EditBranchModal = ({ isOpen, onClose, branch, onSave }) => {
  const [name, setName] = useState(branch?.branch_name || '');
  const [description, setDescription] = useState(branch?.desc || '');
  const [city, setCity] = useState(branch?.city || '');
  const [manager, setManager] = useState(branch?.manager_name || '');
  const [managerPhone, setManagerPhone] = useState(branch?.manager_phone_number || '');
  const [country, setCountry] = useState(branch?.country || '');
  const [phone, setPhone] = useState(branch?.phone_number || '');
  const [email, setEmail] = useState(branch?.email || '');
  const [mapLink, setMapLink] = useState(branch?.map_link || '');  // Ensure mapLink is initialized correctly
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);

  useEffect(() => {
    if (branch) {
      setName(branch.branch_name);
      setDescription(branch.desc);
      setCity(branch.city);
      setManager(branch.manager_name);
      setManagerPhone(branch.manager_phone_number);
      setEmail(branch.email);
      setCountry(branch.country);
      setMapLink(branch.map_link || ''); // Only update mapLink if it's different or empty
    }
  }, [branch]);  // This effect runs only when branch prop changes

  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    setDescription(newDescription);
    setIsDescriptionValid(newDescription.length <= 300);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const toastLoading = toast.loading("Saving changes...");

    try {
      const authData = JSON.parse(localStorage.getItem('auth')) || JSON.parse(sessionStorage.getItem('auth'));

      if (!authData || !authData.accessToken) {
        toast.error("Authentication token not found. Please log in again.");
        return;
      }

      const token = authData.accessToken;

      const response = await axiosInstance.patch(
        `/api/users/branches/${branch.id}/`, // PATCH request for the specific branch ID
        {
          branch_name: name,
          desc: description,
          city,
          manager_name: manager,
          manager_phone_number: managerPhone,
          phone_number: phone,
          country,
          email,
          map_link: mapLink, // Ensure mapLink value is sent correctly
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Changes saved successfully!");
        toast.dismiss(toastLoading);
        onSave(response.data.data, response.data.data.id); // Pass the updated data to the parent component

        onClose();
      }
    } catch (error) {
      toast.dismiss(toastLoading);

      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            errors[field].forEach((error) => toast.error(error + " in " + field));
          }
        }
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg">
        <h2 className="text-2xl font-bold mb-6">Edit Branch Details</h2>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="country">Country</label>
            <input
              type="text"
              id="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="manager">Manager</label>
            <input
              type="text"
              id="manager"
              value={manager}
              onChange={(e) => setManager(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="manager_phone">Manager Phone</label>
            <input
              type="text"
              id="manager_phone"
              value={managerPhone}
              onChange={(e) => setManagerPhone(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="mapLink">Map Link</label>
            <input
              type="url"
              id="mapLink"
              value={mapLink}
              onChange={(e) => setMapLink(e.target.value)}  // Ensure mapLink is updated correctly
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
            />
          </div>
          <div className="md:col-span-2">
            <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="description">Description</label>
            <textarea
              id="description"
              value={description}
              onChange={handleDescriptionChange}
              required
              className={`w-full px-3 py-2 border ${isDescriptionValid ? 'border-gray-300' : 'border-red-500'} rounded-lg text-sm md:text-base`}
              rows="6"
            />
            {!isDescriptionValid && (
              <p className="text-red-500 text-sm mt-1">Description cannot exceed 300 characters.</p>
            )}
          </div>
          <div className="col-span-1 md:col-span-2 flex justify-end gap-4 mt-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white rounded-full px-4 py-2 text-xs md:text-sm hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!isDescriptionValid}
              className="bg-blue-500 text-white rounded-full px-4 py-2 text-xs md:text-sm hover:bg-blue-600"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditBranchModal;
