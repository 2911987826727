import React from 'react'
import { Link } from 'react-router-dom';
import SEO from '../../../components/SEO';

function VisaAndProcessingUk() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <SEO
        title={"Study Abroad | United Kingdom Visa and Processing"}
        description={"Student Visa Requirements, International students need a Student visa (formerly Tier 4) to study in the UK. Requirements include an offer letter from a licensed institution, proof of sufficient funds, English language proficiency, and a clean criminal record."}
        name={"Study Abroad United Kingdom Visa and Processing"}
        type={"website"}
        path={"/study-abroad/united-kingdom/visa-and-processing/"}
      />
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Visa and Processing</h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Student Visa Requirements</h2>
        <p className="text-gray-700">
          International students need a Student visa (formerly Tier 4) to study in the UK. Requirements include an offer letter from a licensed institution, proof of sufficient funds, English language proficiency, and a clean criminal record.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Application Process</h2>
        <ol className="list-decimal pl-5 text-gray-700">
          <li className="mb-2">Obtain a Confirmation of Acceptance for Studies (CAS) from a UK university.</li>
          <li className="mb-2">Prepare your documents including proof of funds, English language test results, and a valid passport.</li>
          <li className="mb-2">Submit your visa application online through the UK government website.</li>
          <li className="mb-2">Attend a biometric appointment and an interview if required.</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Processing Time</h2>
        <p className="text-gray-700">
          Processing times for a UK student visa can vary, but it generally takes around 3 weeks from the date of your application. Applying early is advisable to ensure you receive your visa in time.
        </p>
      </section>

      <section>
        <h2 className="text-base lg:text-lg font-semibold mb-4">After Arrival</h2>
        <p className="text-gray-700">
          Upon arrival in the UK, keep your visa, CAS letter, and other important documents accessible. Ensure compliance with the conditions of your visa and stay updated on any changes in immigration rules.
        </p>
      </section>

      <Link to="https://study-uk.britishcouncil.org/" target='blank'>
        <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
          Learn More
        </button>
      </Link>
    </div>
  );
};

export default VisaAndProcessingUk;