import React, { useRef, useState } from "react";
import { FaSquarePlus } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { useReactToPrint } from "react-to-print";
import NavigationBreadcrumb from "./utils/NavigationBreadcrumb";
import SEO from "../../../../components/SEO";
import relationImage from "../../../../images/relationship-verification.png";

const RelationshipCertificate = () => {
  const previewRef = useRef();
  const [information, setInformation] = useState({
    name: "",
    mr: false,
    mrs: false,
    ms: false,
    location: "",
    metroPolitan: false,
    subMetroPolitan: false,
    municipality: false,
    ruralRuralMunicipality: false,
    localBody: "",
    district: "",
    authorityName: "",
    authorityPerson: "",
  });

  const [applicantRelationShips, setApplicantRelationShips] = useState([
    {
      name: "",
      mr: "",
      mrs: "",
      ms: "",
      relation: "",
      image: null,
      imagePreview: null,
    },
  ]);
  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };
  const handleRadioChangeForGender = (e) => {
    const { value } = e.target;
    setInformation({
      ...information,
      mr: value === "mr",
      mrs: value === "mrs",
      ms: value === "ms",
    });
  };
  const handleRadioChangeForLocalBody = (e) => {
    const { value } = e.target;

    setInformation({
      ...information,
      metroPolitan: value === "metroPolitan", // Set to true if selected
      subMetroPolitan: value === "subMetroPolitan", // Set to true if selected
      municipality: value === "municipality", // Set to true if selected
      ruralRuralMunicipality: value === "ruralRuralMunicipality", // Set to true if selected
    });
  };
  const handleApplicantRelationChange = (e, i) => {
    const { name, value } = e.target;

    setApplicantRelationShips((prevState) =>
      prevState.map((relation, idx) =>
        idx === i ? { ...relation, [name]: value } : relation
      )
    );
  };
  const handleRadioChangeForApplicantRelation = (e, i) => {
    const { value } = e.target;

    setApplicantRelationShips((prevState) =>
      prevState.map((relation, idx) =>
        idx === i
          ? {
            ...relation,
            mr: value === "mr",
            mrs: value === "mrs",
            ms: value === "ms",
          }
          : relation
      )
    );
  };
  const handleDelete = (index) => {
    const newData = applicantRelationShips.filter((_, i) => index !== i);
    setApplicantRelationShips(newData);
  };

  const handleAdd = () => {
    setApplicantRelationShips((prevState) => [
      ...prevState,
      {
        name: "",
        mr: "",
        mrs: "",
        ms: "",
        relation: "",
        image: null,
        imagePreview: null,
      },
    ]);
  };
  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
    documentTitle: "tax_clearance_letter",
    pageStyle: `
            @page { 
                size: A4; 
                margin: 40px;  /* Set consistent margin for print */
            }
            body { 
                margin: 40px;  /* Set consistent margin for the body */
            }
        `,
  });
  return (
    <>
      <NavigationBreadcrumb
        slug={"relationship-certificate"}
        name={"Relationship Certificate"}
      />
      <SEO
        title={"Relationship Certificate"}
        description={
          " Build your documents, and manage your applications with ease."
        }
        name={"Relationship Certificate"}
        type={"website"}
        image={relationImage}
        path={"/tools/documents/relationship-certificate"}
      />
      <div className="py-10 px-2 md:px-4 max-w-7xl mx-auto">
        <div>
          <h1 className="text-2xl md:text-3xl font-semibold font-montserrat mb-4">
            Relationship Certificate{" "}
          </h1>

          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            <div className="grid font-afcacad gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/*  Name  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={information.name}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Hemanta Basnet"
                  aria-label="Full Name"
                  autoComplete="on"
                />
              </div>
              {/*  choose option  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="flex gap-4 items-center h-[45px]">
                  <input
                    type="radio"
                    name="gender"
                    value="mr"
                    checked={information.mr}
                    onChange={handleRadioChangeForGender}
                    aria-label="Gender"
                    autoComplete="off"
                    className="mt-1  border rounded-md scale-150"
                  />{" "}
                  <strong className="font-medium text-lg">Mr.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="mrs"
                    checked={information.mrs}
                    onChange={handleRadioChangeForGender}
                    aria-label="Gender"
                    autoComplete="off"
                    className="mt-1 border rounded-md scale-150"
                  />{" "}
                  <strong className="font-medium text-lg">Mrs.</strong>
                  <input
                    type="radio"
                    name="gender"
                    value="ms"
                    checked={information.ms}
                    onChange={handleRadioChangeForGender}
                    aria-label="Gender"
                    autoComplete="off"
                    className="mt-1 border rounded-md scale-150"
                  />
                  <strong className="font-medium text-lg">Miss.</strong>
                </div>
              </div>
              {/* district Name  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  District<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="district"
                  value={information.district}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Yubraj Basnet"
                  aria-label="District"
                  autoComplete="on"
                />
              </div>
              {/* Local body  */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Local Body<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="localBody"
                  value={information.localBody}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Itahari"
                  aria-label="Local Body"
                  autoComplete="off"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Choose Option<span className="text-red-500">*&nbsp;</span>
                </label>
                <div className="grid grid-cols-2 items-center h-[45px]">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="metroPolitan"
                      checked={information.metroPolitan}
                      onChange={handleRadioChangeForLocalBody}
                      aria-label="Local Body is Metropolitan"
                      autoCapitalize="off"
                      className="mt-1  border rounded-md scale-125"
                    />
                    <strong className="font-medium text-sm">
                      MetroPolitian
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="subMetroPolitan"
                      checked={information.subMetroPolitan}
                      aria-label="Local Body is Sub Metropolitan"
                      autoCapitalize="off"
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                    />
                    <strong className="font-medium text-sm">
                      Sub-Metropolitan
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="municipality"
                      checked={information.municipality}
                      aria-label="Local Body is Muncipality"
                      autoCapitalize="off"
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                    />
                    <strong className="font-medium text-sm">
                      Municipality
                    </strong>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="localBody"
                      value="ruralRuralMunicipality"
                      aria-label="Local Body is Rural Muncipality"
                      autoCapitalize="off"
                      checked={information.ruralRuralMunicipality}
                      onChange={handleRadioChangeForLocalBody}
                      className="mt-1 border rounded-md scale-125"
                    />
                    <strong className="font-medium text-sm">
                      Rural Municipality
                    </strong>
                  </div>
                </div>
              </div>

              {/* Location */}
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Location<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="location"
                  aria-label="city"
                  autoComplete="on"
                  value={information.location}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Kavre"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Authority Name<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="authorityName"
                  aria-label="Authority Name"
                  autoComplete="off"
                  onChange={handleChange}
                  value={information.authorityName}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Nawaraj Sapkota"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-lg font-medium">
                  Authority Person<span className="text-red-500">*&nbsp;</span>
                </label>
                <input
                  type="text"
                  name="authorityPerson"
                  aria-label="Authority Person"
                  autoComplete="off"
                  onChange={handleChange}
                  value={information.authorityPerson}
                  className="w-full mt-1 p-2 border rounded-md"
                  placeholder="Eg: Ward Chairperson"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-10">
          <h1 className="text-xl font-semibold font-montserrat">
            Applicant Relation
          </h1>
          <div className="p-4 pt-10 border bg-gray-100 rounded-md">
            {applicantRelationShips.map((applicantRelation, i) => (
              <div key={i} className="font-afcacad">
                <div className="grid text-sm md:text-lg gap-2 grid-cols-[4fr_4fr_4fr_1fr] place-items-center md:place-content-center overflow-x-scroll">
                  {/* Applicant Name  */}
                  <div className="mb-4 w-[200px] md:w-full">
                    <label className="block text-sm md:text-lg font-medium">
                      Name<span className="text-red-500">*&nbsp;</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      aria-label="Full Name"
                      autoComplete="on"
                      value={applicantRelation.name}
                      onChange={(e) => handleApplicantRelationChange(e, i)}
                      className="w-full mt-1 p-2 border rounded-md"
                      placeholder="Eg: Yubraj Basnet"
                    />
                  </div>
                  {/* Choose mr,mrs,ms  */}
                  <div className="mb-4 w-[200px] md:w-full">
                    <label className="block text-sm md:text-lg font-medium">
                      Choose Option<span className="text-red-500">*&nbsp;</span>
                    </label>
                    <div className="flex gap-4 items-center h-[45px]">
                      <input
                        type="radio"
                        name={`gender${i}`}
                        value="mr"
                        aria-label="mr"
                        autoComplete="off"
                        checked={applicantRelation.mr}
                        onChange={(e) =>
                          handleRadioChangeForApplicantRelation(e, i)
                        }
                        className="mt-1  border rounded-md scale-150"
                      />{" "}
                      <strong className="font-medium text-sm md:text-lg">
                        Mr.
                      </strong>
                      <input
                        type="radio"
                        name={`gender${i}`}
                        value="mrs"
                        aria-label="mrs"
                        autoComplete="off"
                        checked={applicantRelation.mrs}
                        onChange={(e) =>
                          handleRadioChangeForApplicantRelation(e, i)
                        }
                        className="mt-1 border rounded-md scale-150"
                      />{" "}
                      <strong className="font-medium text-sm md:text-lg">
                        Mrs.
                      </strong>
                      <input
                        type="radio"
                        name={`gender${i}`}
                        value="ms"
                        aria-label="ms"
                        autoComplete="off"
                        checked={applicantRelation.ms}
                        onChange={(e) =>
                          handleRadioChangeForApplicantRelation(e, i)
                        }
                        className="mt-1 border rounded-md scale-150"
                      />
                      <strong className="font-medium text-sm md:text-lg">
                        Miss.
                      </strong>
                    </div>
                  </div>
                  <div className="mb-4 w-[200px] md:w-full">
                    <label className="block text-sm md:text-lg font-medium">
                      Relation<span className="text-red-500">*&nbsp;</span>
                    </label>
                    <input
                      type="text"
                      name="relation"
                      aria-label="relation"
                      autoComplete="off"
                      value={applicantRelation.relation}
                      onChange={(e) => handleApplicantRelationChange(e, i)}
                      className="w-full mt-1 p-2 border rounded-md"
                      placeholder="Eg: Father"
                    />
                  </div>
                  <div className="mt-0 md:mt-5 self-center flex items-center gap-2 place-self-end">
                    <ImCross
                      className="cursor-pointer"
                      color="red"
                      size={20}
                      onClick={() => {
                        if (applicantRelationShips.length > 1) {
                          handleDelete(i);
                        }
                      }}
                      title="Delete"
                    />
                    <FaSquarePlus
                      className="cursor-pointer"
                      color="#01132b"
                      size={30}
                      onClick={() => {
                        handleAdd();
                      }}
                      title="Add"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="p-4 border bg-white rounded-md">
          <h2 className="text-2xl font-semibold mb-10">Document Preview</h2>
          <div ref={previewRef} className="A4-size px-10 font-montserrat">
            <div className="h-[100px] w-full"></div> {/*Safe Space*/}
            <div className="text-center pt-10">
              <p className="text-xl font-bold">
                Subject: Certificate of Relationship
              </p>
              <p className="mt-2 text-2xl font-bold underline">
                To Whom it May Concern
              </p>
            </div>
            <div className="pt-5 text-justify">
              This is to certify that&nbsp;
              {information.name &&
                (information.mr || information.mrs || information.ms)
                ? information.mr
                  ? `Mr. ${information.name}`
                  : information.mrs
                    ? `Mrs. ${information.name}`
                    : `Ms. ${information.name}`
                : "Mr./Mrs./Miss................"}
              , a permanent resident of{" "}
              {information.location
                ? `${information.location}-`
                : ".............."}
              {information.district ? information.district : "................"}{" "}
              Nepal has submitted an application to this office for verification
              of relationship. Whereas, after following the necessary enquiry
              and conduction public enquiry from this{" "}
              {information.localBody &&
                (information.metroPolitan ||
                  information.subMetroPolitan ||
                  information.municipality ||
                  information.ruralRuralMunicipality)
                ? information.metroPolitan
                  ? `${information.localBody} MetroPolitan City`
                  : information.subMetroPolitan
                    ? `${information.localBody} Sub-MetroPolitan City`
                    : information.municipality
                      ? `${information.localBody} Municipality`
                      : `${information.localBody} Rural Municipality`
                : ""}{" "}
              found that the following persons have following relationship.
              Therefore, it is hereby verified the following relationship in
              accordance with the section 12 (E)(1) of Local Government
              Conduction Act 2076 B.S. (2019 A.D)
              <br />
            </div>
            <p className="pb-2">
              The photographs of concerned persons are attached below.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full gap-y-0 gap-x-4 text-center">
              <div className="mt-2 flex flex-col items-center justify-center">
                <div className="w-40 h-40 sm:w-32 sm:h-32 xs:w-24 xs:h-24 overflow-hidden border-black border-2 flex justify-center items-center">
                  <p className="-rotate-45 text-xs sm:text-sm md:text-lg">
                    PHOTO
                  </p>
                </div>

                <div>
                  <p className="font-medium">
                    {information.name &&
                      (information.mr || information.mrs || information.ms)
                      ? information.mr
                        ? `Mr. ${information.name}`
                        : information.mrs
                          ? `Mrs. ${information.name}`
                          : `Ms. ${information.name}`
                      : "........................."}
                  </p>
                  <p>(Applicant)</p>
                </div>
              </div>

              {applicantRelationShips.map((relation, i) => (
                <div
                  key={i}
                  className="mt-2 flex flex-col items-center justify-center"
                >
                  <div className="w-40 h-40 sm:w-32 sm:h-32 xs:w-24 xs:h-24 overflow-hidden border-black border-2 flex justify-center items-center">
                    <p className="-rotate-45 text-xs sm:text-sm md:text-lg">
                      PHOTO
                    </p>
                  </div>

                  <div>
                    <p className="font-medium">
                      {relation.name &&
                        (relation.mr || relation.mrs || relation.ms)
                        ? relation.mr
                          ? `Mr. ${relation.name}`
                          : relation.mrs
                            ? `Mrs. ${relation.name}`
                            : `Ms. ${relation.name}`
                        : "........................."}
                    </p>
                    <p>
                      (Applicant's{" "}
                      {relation.relation ? relation.relation : "........"})
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="pt-32">
              <div className=" flex flex-col items-end">
                <p>
                  {information.authorityPerson
                    ? information.authorityPerson
                    : "..........................................................."}
                </p>
                <p className="font-bold">
                  {information.authorityName
                    ? information.authorityName
                    : "........................................................"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handlePrint();
          }}
          className={`px-4 mt-2 py-2 bg-[#eb4934] hover:bg-[#d43b2e] text-white rounded-md cursor-pointer`}
        // disabled={!checkFields}
        >
          Download PDF
        </button>
      </div>
    </>
  );
};

export default RelationshipCertificate;
