import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import Spinner from "./Spinner";

const LoginProtect = () => {
    const { isAuthenticated, username, authLoading } = useAuth();

    if (authLoading) {
        return <Spinner />;
    }

    return username && isAuthenticated ? <Navigate to="/admin/dashboard" /> : <Outlet />;
};

export default LoginProtect;
