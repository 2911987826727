import React from 'react'
import { Link } from 'react-router-dom'
import SEO from '../../../components/SEO'

function VisaAndProcessingCanada() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <SEO
        title={"Study Abroad | Canada Visa and Processing"}
        description={
          "Types of Scholarships Merit-Based Scholarships: Awarded based on academic performance, leadership skills, and extracurricular achievements. Need-Based Scholarships: Provided to students who demonstrate financial need to help cover their educational expenses. Program-Specific Scholarships: Offered for specific fields of study or academic programs, such as engineering or business. Country-Specific Scholarships: Targeted at students from particular countries or regions, often funded by governments or international organizations. Research Grants: Available for students undertaking research projects or pursuing postgraduate studies."
        }
        name={"Study Abroad Canada Visa and Processing"}
        type={"website"}
        path={"/study-abroad/canada/visa-and-processing/"}
      />
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Visa and Processing for Canada</h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Student Visa Requirements</h2>
        <p className="text-gray-700">
          To study in Canada, international students need a study permit. The requirements generally include proof of acceptance from a designated learning institution (DLI), proof of sufficient funds to cover tuition and living expenses, and a clean criminal record. A medical exam may also be required.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Application Process</h2>
        <ol className="list-decimal pl-5 text-gray-700 mb-2">
          <li className="mb-2">Receive an acceptance letter from a designated learning institution (DLI).</li>
          <li className="mb-2">Prepare required documents including proof of funds, identity documents, and medical exam results if applicable.</li>
          <li className="mb-2">Submit your study permit application online or at a Canadian visa office.</li>
          <li className="mb-2">Attend an interview if required and wait for a decision on your application.</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Processing Time</h2>
        <p className="text-gray-700">
          The processing time for a Canadian study permit application can vary depending on the country of residence and the volume of applications. On average, it can take several weeks to a few months. It is advisable to apply well in advance of your intended start date.
        </p>
      </section>

      <section>
        <h2 className="text-base lg:text-lg font-semibold mb-4">After Arrival</h2>
        <p className="text-gray-700">
          Upon arrival in Canada, make sure to carry all relevant documents with you, including your study permit, acceptance letter, and proof of funds. It is important to follow the conditions of your study permit and stay informed about any changes in immigration policies.
        </p>
      </section>
      <Link to="https://www.educanada.ca/index.aspx?lang=eng" target="blank">
        <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
          Learn More
        </button>
      </Link>
    </div>
  )
}

export default VisaAndProcessingCanada