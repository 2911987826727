import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Article from "./articlestemplate"; // Adjust path as needed
import { Link } from "react-router-dom";
import axiosInstance from "../../../api/axiosInstance";

const ARTICLES_PER_PAGE = 4; // Number of articles per page

const ArticlesGrid = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [articles, setArticles] = useState([]);
  const [users, setUsers] = useState({});

  // Calculate the index of the first and last articles on the current page
  const indexOfLastArticle = currentPage * ARTICLES_PER_PAGE;
  const indexOfFirstArticle = indexOfLastArticle - ARTICLES_PER_PAGE;

  // Get the articles to be displayed on the current page
  const currentArticles = articles
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(indexOfFirstArticle, indexOfLastArticle);

  const totalPages = Math.ceil(articles.length / ARTICLES_PER_PAGE);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    // Fetch articles
    axiosInstance
      .get("/api/users/articles/")
      .then((res) => {
        setArticles(res.data.data);

        // Extract unique author IDs from the articles
        const authorIds = [...new Set(res.data.data.map((article) => article.author))];

        // Fetch each author's username based on the user ID
        Promise.all(
          authorIds.map((id) =>
            axiosInstance.get(`/api/users/users/${id}/`) // Corrected API endpoint syntax
          )
        )
          .then((userData) => {
            const userMap = {};
            userData.forEach((userResponse) => {
              const userDetail = userResponse.data;
              userMap[userDetail.id] = userDetail.username; // Store the username against the user ID
            });
            setUsers(userMap);
          })
          .catch((error) => console.error("Error fetching user data:", error));
      })
      .catch((error) => {
        console.log("Error fetching articles:", error);
      });
  }, []);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl sm:text-3xl font-bold">Recent Articles</h2>
        <Link to={"/admin/dashboard/articles/new"}>
          <button
            className="bg-[#01132b] text-white rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-blue-600 mt-4 sm:mt-0"
            aria-label="Add Article"
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Article
          </button>
        </Link>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-4">
        {currentArticles.length !== 0 ? (
          currentArticles.map((article) => (
            <Article
              key={article.id}
              id={article.id}
              title={article.title}
              description={article.description}
              date={article.date}
              author={users[article.author] || "Loading..."} // Fetch and display username here
              keywords={article.keywords.split(",")}
              category={article.category}
              thumbnail={article.thumbnail}
              featuredImage={article.featured_image}
              articles={articles}
              setArticles={setArticles}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-96 text-gray-500 text-lg md:text-2xl col-span-full">
            <p>No articles found</p>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center mt-6">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`bg-gray-300 text-gray-700 rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-[#01132b] hover:text-white ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          Previous
        </button>
        <span className="text-lg font-semibold mb-2 sm:mb-0">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`bg-gray-300 text-gray-700 rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-[#01132b] hover:text-white ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Next
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default ArticlesGrid;
