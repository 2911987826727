import React from 'react'
import SEO from '../../../components/SEO';

function ScholarshipsAtUk() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <SEO
        title={"Study Abroad | United Kingdom Scholarships"}
        description={"Types of Scholarships Merit-Based Scholarships: Awarded based on academic excellence, achievements, and leadership.Need-Based Scholarships: Designed to help students from low-income backgrounds cover their education costs.Program-Specific Scholarships: Available for specific fields of study or academic programs.Country-Specific Scholarships: Offered to students from particular countries or regions to promote educational exchange.Research Grants: Provided for students engaged in research projects or postgraduate studies."}
        name={"Study Abroad United Kingdom Scholarships"}
        type={"website"}
        path={"/study-abroad/united-kingdom/scholarships/"}
      />
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Scholarships</h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Types of Scholarships</h2>
        <ul className="list-disc pl-5 text-gray-700">
          <li className="mb-2">
            <strong>Merit-Based Scholarships:</strong> Awarded based on academic excellence, achievements, and leadership.
          </li>
          <li className="mb-2">
            <strong>Need-Based Scholarships:</strong> Designed to help students from low-income backgrounds cover their education costs.
          </li>
          <li className="mb-2">
            <strong>Program-Specific Scholarships:</strong> Available for specific fields of study or academic programs.
          </li>
          <li className="mb-2">
            <strong>Country-Specific Scholarships:</strong> Offered to students from particular countries or regions to promote educational exchange.
          </li>
          <li className="mb-2">
            <strong>Research Grants:</strong> Provided for students engaged in research projects or postgraduate studies.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Top Scholarships for International Students</h2>
        <ul className="list-disc pl-5 text-gray-700">
          <li className="mb-2">
            <strong>Chevening Scholarships:</strong> Funded by the UK government, covering tuition, living expenses, and travel costs for one year of study.
          </li>
          <li className="mb-2">
            <strong>Commonwealth Scholarships:</strong> Support students from low- and middle-income Commonwealth countries to pursue postgraduate study.
          </li>
          <li className="mb-2">
            <strong>Gates Cambridge Scholarships:</strong> Provides full funding for outstanding international students to pursue a postgraduate degree at Cambridge University.
          </li>
          <li className="mb-2">
            <strong>Rhodes Scholarships:</strong> Offers comprehensive funding for exceptional international students to study at the University of Oxford.
          </li>
          <li className="mb-2">
            <strong>University-Specific Scholarships:</strong> Many universities, such as the University of Edinburgh and Imperial College London, offer their own scholarships for international students.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ScholarshipsAtUk;