import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './testprep.css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { slideData } from '../../data/testprep';

const TestPrep = () => {
  return (
    <div className="relative max-w-7xl mx-auto px-4 py-6">
      <h2 className="text-3xl font-bold mb-6 text-center">Test Preparation</h2>
      <Swiper
        spaceBetween={20}
        loop={true}
        navigation
        modules={[Navigation]}
        className="mySwiper"
        breakpoints={{
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {slideData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-[450px]">
              <img
                className="w-full h-48 p-2"
                src={slide.image}
                alt={slide.title}
              />
              <div className="p-4 flex-1 flex flex-col">
                <h3 className="text-xl font-semibold mb-2">{slide.title}</h3>
                <p className="text-gray-700 mb-4 flex-grow">{slide.description}</p>
                <a
                  href={slide.link}
                  className="bg-[#eb4934] text-white px-4 py-2 rounded hover:bg-[#d43b2e] transition-colors duration-200 mt-auto text-center"
                >
                  Learn More
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestPrep;
