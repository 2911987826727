import React, { useState, useEffect, useRef } from "react";
import ArticleSidebar from "../components/articles/articleSideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../api/axiosInstance";
import { useAuth } from "../context/authContext";
import SEO from "../components/SEO";
const ITEMS_PER_PAGE = 6;

const ArticlesMainPage = () => {
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [errorArticles, setErrorArticles] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useAuth();

  const dropdownRef = useRef(null);

  function truncateText(html, limit, id) {
    // Create a temporary element to extract text content
    const div = document.createElement("div");
    div.innerHTML = html;
    const textContent = div.textContent || div.innerText || "";

    // Check if truncation is needed
    if (textContent.length <= limit) return html;

    // Truncate text and create "Read more" link
    const truncatedText = textContent.slice(0, limit) + "...";
    const readMoreLink = `<a href="/article/${id}" class="text-blue-500 hover:underline">Read more</a>`;

    // Return truncated HTML with link
    return `<p>${truncatedText}</p>${readMoreLink}`;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSortDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const loadArticlesData = () => {
      axiosInstance.get("/api/users/articles/").then((response) => {
        setLoadingArticles(false);
        let articles = response.data.data;
        articles = articles.sort((a, b) => {
          return sortOrder === "asc"
            ? new Date(a.date) - new Date(b.date)
            : new Date(b.date) - new Date(a.date);
        });

        const totalItems = articles.length;
        setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;

        setDisplayedArticles(articles.slice(startIndex, endIndex));
      }).catch(error => {
        setLoadingArticles(false);
        setErrorArticles("Failed to fetch Articles.")
      })
    };
    loadArticlesData();
  }, [sortOrder, currentPage]);

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
    setCurrentPage(1);
    setShowSortDropdown(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <SEO
        title={"Articles"}
        description={"Articles"}
        name={"Articles"}
        type={"website"}
        path={"/articles"}
      />
      <main className="flex-1 p-4 lg:p-6 lg:max-w-6xl lg:mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-3xl font-bold">Articles</h1>
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={() => setShowSortDropdown(!showSortDropdown)}
              className="bg-[#eb4934] hover:bg-[#d43b2e] text-white p-2 rounded flex items-center"
            >
              <FontAwesomeIcon icon={faSort} className="mr-2" />
              Sort
            </button>
            {showSortDropdown && (
              <div className="absolute top-full right-0 border p-2 rounded w-64 mt-2 bg-white shadow-lg z-20">
                <label
                  htmlFor="sort-order"
                  className="block mb-2 text-sm font-semibold"
                >
                  Sort By:
                </label>
                <select
                  id="sort-order"
                  value={sortOrder}
                  onChange={handleSortOrderChange}
                  className="border p-2 rounded w-full mb-4"
                >
                  <option value="desc">Newest First</option>
                  <option value="asc">Oldest First</option>
                </select>
              </div>
            )}
          </div>
        </div>
        <ul>
          {
            loadingArticles && !errorArticles && displayedArticles.length === 0 ? (
              <div className="flex justify-center items-center h-96 text-gray-500 text-lg md:text-2xl col-span-full">
                <p>Loading Articles ....</p>
              </div>
            ) : errorArticles && !loadingArticles && displayedArticles.length === 0 ? (
              <div className="flex justify-center items-center h-96 text-gray-500 text-lg md:text-2xl col-span-full">
                <p>{errorArticles}</p>
              </div>
            ) : !errorArticles && !loadingArticles && displayedArticles.length > 0 ? (
              displayedArticles.map((article) => (
                <li key={article.id} className="border-b py-4">
                  <div className="flex flex-col lg:flex-row">
                    <img
                      src={article.thumbnail}
                      alt={article.title}
                      className="w-full lg:w-32 h-32 object-contain mr-4"
                    />
                    <div className="flex-1">
                      <h2 className="text-xl font-semibold">{article.title}</h2>
                      <div
                        className="text-gray-700"
                        dangerouslySetInnerHTML={{
                          __html: truncateText(
                            article.description,
                            100,
                            article.id
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    <p>
                      By {user ? user : "Unknown author"} on{" "}
                      {new Date(article.date).toLocaleDateString()}
                    </p>
                    <p className="mt-1">Keywords: {article.keywords}</p>
                  </div>
                </li>
              ))
            ) : (
              <div className="flex justify-center items-center h-96 text-gray-500 text-lg md:text-2xl col-span-full">
                <p>No Articles Found.</p>
              </div>
            )
          }
        </ul>

        <div className="flex justify-center mt-6">
          <div className="flex space-x-1">
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page + 1}
                onClick={() => handlePageChange(page + 1)}
                className={`p-2 rounded ${currentPage === page + 1
                  ? "bg-[#eb4934] hover:bg-[#d43b2e] text-white"
                  : "bg-gray-200 text-gray-700"
                  } border border-gray-300`}
              >
                {page + 1}
              </button>
            ))}
          </div>
        </div>
      </main>
      <div className="p-4 border-l border-gray-300 hidden lg:block">
        <ArticleSidebar />
      </div>
    </div>
  );
};

export default ArticlesMainPage;
