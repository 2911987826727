import React from 'react'
import { Link } from 'react-router-dom'
import SEO from '../../../components/SEO';

function VisaAndProcessingNewZealand() {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <SEO
        title={"Study Abroad | New Zealand Visa and Processing"}
        description={
          "To study in New Zealand, international students need a student visa. Requirements typically include proof of acceptance from a recognized educational institution, proof of sufficient funds, a clean criminal record, and a medical certificate if required."
        }
        name={"Study Abroad New Zealand Visa and Processing"}
        type={"website"}
        path={"/study-abroad/new-zealand/visa-and-processing/"}
      />
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Visa and Processing for New Zealand</h1>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Student Visa Requirements</h2>
        <p className="text-gray-700">
          To study in New Zealand, international students need a student visa. Requirements typically include proof of acceptance from a recognized educational institution, proof of sufficient funds, a clean criminal record, and a medical certificate if required.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Application Process</h2>
        <ol className="list-decimal pl-5 text-gray-700">
          <li className="mb-2">Obtain an offer letter from a recognized educational institution in New Zealand.</li>
          <li className="mb-2">Gather necessary documents including proof of funds, health insurance, and identification.</li>
          <li className="mb-2">Submit your student visa application online or at a New Zealand visa office.</li>
          <li className="mb-2">Attend an interview if required and await the decision on your visa application.</li>
        </ol>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Processing Time</h2>
        <p className="text-gray-700">
          The processing time for a New Zealand student visa can vary based on your country of residence and the volume of applications. Generally, it takes several weeks to a few months, so applying well in advance is recommended.
        </p>
      </section>

      <section>
        <h2 className="text-base lg:text-lg font-semibold mb-4">After Arrival</h2>
        <p className="text-gray-700">
          Upon arrival in New Zealand, keep all relevant documents handy, including your student visa, acceptance letter, and proof of funds. Ensure that you adhere to the conditions of your visa and stay informed about any changes in immigration policies.
        </p>
      </section>

      <Link to="https://www.studywithnewzealand.govt.nz/en" target="blank">
        <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
          Learn More
        </button>
      </Link>
    </div>
  );
};

export default VisaAndProcessingNewZealand