import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const TabLink = ({ to, label, isActive }) => (
  <Link
    to={to}
    className={classNames(
      'p-2 text-sm font-medium text-center',
      {
        'text-blue-500 border-b-2 border-blue-500': isActive,
        'text-gray-500': !isActive,
      }
    )}
  >
    {label}
  </Link>
);

const EuropeTabs = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const tabs = [
    { path: '/study-abroad/europe', label: 'Overview' },
    { path: '/study-abroad/europe/life-and-study', label: 'Life in Europe' },
    { path: '/study-abroad/europe/universities', label: 'Universities' },
    { path: '/study-abroad/europe/countries', label: 'Countries' },
    { path: '/study-abroad/europe/scholarships', label: 'Scholarships' },
    { path: '/study-abroad/europe/visa-and-processing', label: 'Visa and Processing' },
  ];

  return (
    <nav className="bg-white border-b m-4 border-gray-200">
      <div className="max-w-7xl mx-auto p-2">
        <div className="relative z-0 flex justify-center flex-wrap space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row flex-col">
          {tabs.map((tab) => (
            <TabLink
              key={tab.path}
              to={tab.path}
              label={tab.label}
              isActive={currentPath === tab.path}
            />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default EuropeTabs;
