import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const TabLink = ({ to, label, isActive }) => (
  <Link
    to={to}
    className={classNames(
      'p-2 text-sm font-medium text-center',
      {
        'text-blue-500 border-b-2 border-blue-500': isActive,
        'text-gray-500': !isActive,
      }
    )}
  >
    {label}
  </Link>
);

const CanadaTabs = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const tabs = [
    { path: '/study-abroad/canada', label: 'Overview' },
    { path: '/study-abroad/canada/life-and-study', label: 'Life at Canada' },
    { path: '/study-abroad/canada/universities', label: 'Universities' },
    { path: '/study-abroad/canada/scholarships', label: 'Scholarships' },
    { path: '/study-abroad/canada/visa-and-processing', label: 'Visa and Processing' },
  ];

  return (
    <nav className="bg-white border-b m-4 border-gray-200">
      <div className="max-w-7xl mx-auto p-2">
        <div className="relative z-0 flex flex-col sm:flex-row sm:text-center justify-center space-y-2 sm:space-y-0 sm:space-x-4">
          <ul className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            {tabs.map((tab) => (
              <li key={tab.path}>
                <TabLink to={tab.path} label={tab.label} isActive={currentPath === tab.path} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default CanadaTabs;
