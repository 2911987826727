import React from 'react';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-select-dt';
import './datatable.css';

// Initialize DataTable
DataTable.use(DT);

// Function to format the date in YYYY/MM/DD, HH:mm:ss format
function formatDate(dateString) {
  const dateObj = new Date(dateString);
  if (isNaN(dateObj)) {
    return 'Invalid Date';  // Return a fallback if date is invalid
  }
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  return `${year}/${month}/${day}, ${hours}:${minutes}:${seconds}`;
}

function Datatable({ leads }) {
  // Define columns and format the date field
  const columns = [
    { data: 'created_at', title: 'Date', render: formatDate },  // Format date
    { data: 'name', title: 'Name' },    // Map 'name' field
    { data: 'email', title: 'Email' },  // Map 'email' field
    { data: 'message', title: 'Message' },  // Map 'message' field
  ];


  return (
    <div>
      {/* <button
        onClick={exportToExcel}
        className="export-button px-6 py-3 bg-[#01132b] text-white font-semibold rounded-lg shadow-md transform transition-all duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg hover:translate-y-[-2px] active:bg-green-700 active:translate-y-0 focus:outline-none"
      >
        Export to Excel
      </button> */}

      <DataTable
        data={leads}
        columns={columns}
        className="display"
        options={{
          responsive: true,
          select: true,
        }}
      >
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Message</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  );
}

export default Datatable;
