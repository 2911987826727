import { Link } from "react-router-dom"

const CVNavigationBreadcrumb = ({ slug, name }) => {
    return (
        <div className="flex font-afcacad text-sm md:text-lg px-2 md:px-8 py-2 md:py-8 space-x-2">
            <Link to={"/tools/"} className="hover:underline">
                Tools
            </Link>
            <p>&gt;</p>
            <Link to={`/tools/${slug}`} className="hover:underline">
                {name}
            </Link>
        </div>
    )
}

export default CVNavigationBreadcrumb