import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleUp } from "react-icons/fa";
import logo from "./../../logo.png";
import AdvertisementBanner from "./footAdvertisment";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-[#01132b] text-white w-full mx-auto pt-8">
      <div className="w-full max-w-[1200px] mx-auto border-b-2 border-white p-4 pb-6">
        <AdvertisementBanner />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.4521332813606!2d85.31992117525361!3d27.70332317618483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19db9433c02b%3A0x813abb6022393017!2sAAEC%20GLOBAL!5e0!3m2!1sen!2snp!4v1722395365362!5m2!1sen!2snp"
          width="100%"
          height="300"
          className="rounded-lg"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="google maps"
        ></iframe>
      </div>

      <div className="w-full max-w-[1200px] mx-auto flex flex-col sm:flex-row flex-wrap justify-between text-center sm:text-left py-2 space-y-8 sm:space-y-0 sm:space-x-4 lg:space-x-12">
        {/* First Column */}
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-4 lg:mb-0">
          <Link to="/">
            <img className="h-20 w-36 mx-auto px-4 lg:px-0 sm:mx-0 mb-4" src={logo} alt="logo" />
          </Link>
          <h4 className="text-xl px-4 lg:px-0 mb-3">AAEC Global Educational Consultancy Pvt. Ltd.</h4>
          <p className="text-sm px-4 lg:px-0 leading-relaxed">
            We are one of the leading providers of educational consultancy services in Nepal for aspirants
            who are willing to study abroad.
          </p>
        </div>

        {/* Second Column */}

        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4 mb-4 lg:mb-0">
          <ul className="space-y-3 mb-3 lg:mb-0">
            <li>
              <Link to="/" className="hover:underline">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about-us" className="hover:underline">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="hover:underline">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/our-branches" className="hover:underline">
                Our Branches
              </Link>
            </li>
            <li>
              <Link to="/tools" className="hover:underline">
                Student Tools
              </Link>
            </li>
            <li>
              <Link to="/track-application" className="hover:underline">
                Track Application Status
              </Link>
            </li>
          </ul>
        </div>
        {/* Third Column */}
        <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4 mb-4 lg:mb-0">
          <ul className="space-y-3">
            <li>
              <Link to="/study-abroad/united-kingdom" className="hover:underline">
                Study In UK
              </Link>
            </li>
            <li>
              <Link to="/study-abroad/united-states-of-america" className="hover:underline">
                Study In USA
              </Link>
            </li>
            <li>
              <Link to="study-abroad/australia" className="hover:underline">
                Study In Australia
              </Link>
            </li>
            <li>
              <Link to="study-abroad/canada" className="hover:underline">
                Study In Canada
              </Link>
            </li>
            <li>
              <Link to="study-abroad/new-zealand" className="hover:underline">
                Study In New Zealand
              </Link>
            </li>
            <li>
              <Link to="study-abroad/europe" className="hover:underline">
                Study In Europe
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="text-center py-4 text-xs sm:border-t border-white sm:mt-4">
        © AAEC Global - 2024. All rights reserved. Powered by & IT Partner: Axor Technology Private Limited
      </div>

      <button
        id="scroll-top"
        title="Go to Top"
        onClick={scrollToTop}
        className={`fixed bottom-5 right-5 bg-[#01132B] text-white rounded-full w-12 h-12 text-2xl shadow-md transition-colors hover:bg-blue-700 flex items-center justify-center ${isVisible ? '' : 'hidden'}`}
      >
        <FaAngleUp />
      </button>
    </footer>
  );
};

export default Footer;
