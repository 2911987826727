import "react-quill/dist/quill.snow.css"; // Import the styles
import PersonalDetails from "./PersonalDetails";
import WorkExperience from "./WorkExperience";
import SkillsDetails from "./Skills";
import EducationalDetails from "./EducationalDetails";
import LanguageDetails from "./LanguagesDetails";
import Summary from "./Summary";
import CvDocument from "./CvDocument";
import CVNavigationBreadcrumb from "../cvbuilder/utils/NavigationBreadcrumb";
import SEO from "../../../components/SEO";

const CvBuilder = () => {
  return (
    <>
      <CVNavigationBreadcrumb slug={"cv-builder"} name={"CV Builder"} />
      <SEO
        title={"CV Builder"}
        description={" Build your CV, and manage your applications with ease."}
        name={"CV Builder"}
        type={"website"}
        path={"/tools/cv-builder"}
      />
      <div className="max-w-7xl mx-auto p-2 md:p-6">
        <div className="">
          {/* Left column: Form to fill details */}
          <PersonalDetails />
          <WorkExperience />
          <SkillsDetails />
          <EducationalDetails />
          <LanguageDetails />
          <Summary />
          {/* Right column: CV Preview */}
          <CvDocument />
        </div>
      </div>
    </>
  );
};

export default CvBuilder;
