import { useState } from "react"
import { cvContext } from "./cvContext"


const initialPersonalDetails = {
  fullName: "",
  city: "",
  email: "",
  country: "",
  phone: "",
};

const CvState = ({ children }) => {
  const [personalDetails, setPersonalDetails] = useState(initialPersonalDetails);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const [educationalDetails, setEducationalDetails] = useState([]);
  const [languageDetails, setLanguageDetails] = useState([]);
  const [summary, setSummary] = useState(null);


  return (
    <cvContext.Provider value={{ workExperiences, setWorkExperiences, personalDetails, setPersonalDetails, skills, setSkills, educationalDetails, setEducationalDetails, languageDetails, setLanguageDetails, summary, setSummary }} >
      {children}
    </cvContext.Provider>
  )
}

export default CvState