import React from 'react';
import { Outlet } from 'react-router-dom';
import AustraliaTabs from './navAustralia';
import SEO from '../../../components/SEO';


const StudyInAustralia = () => {
  return (
    <div className="max-w-7xl mx-auto p-6">
      <SEO
        title={"Study Abroad | Australia"}
        description={"Why Study in Australia. Australia is a popular destination for international students, known for its high-quality education system, multicultural society, and vibrant cities. The country offers a wide range of programs across various disciplines, with many universities ranked among the best in the world."}
        name={"Study Abroad Australia"}
        type={"website"}
        path={"/study-abroad/australia"}
      />
      <AustraliaTabs />
      <Outlet />
    </div>
  );
};

export default StudyInAustralia;
