import { useEffect, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import TeamMember from "./TeamMember";
import AddTeamMember from "./AddTeamMember.jsx";


const MEMBERS_PER_PAGE = 6;
export default function TeamMembers() {
    const [addModal, setAddModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [members, setMembers] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const totalPages = Math.ceil(members.length / MEMBERS_PER_PAGE);
    const lastPosition = currentPage * MEMBERS_PER_PAGE;
    const firstPosition = lastPosition - MEMBERS_PER_PAGE;

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const fetchTeamMembers = async () => {
        try {
            const teamResponse = await axiosInstance.get("/api/users/team/team-members/");
            const branchResponse = await axiosInstance.get("/api/users/branches/");
            const branchData = branchResponse.data.data;
            const teamData = teamResponse.data.results;

            setBranchData(branchData);
            const newTeamData = teamData.map((team, index) => {
                const branch = branchData.find((branch) => branch.id === team.branch);

                return {
                    ...team,
                    branchName: branch.branch_name
                }
            });
            setMembers(newTeamData);

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchTeamMembers();
    }, [])

    const currentMembers = members.slice(firstPosition, lastPosition);

    return (
        <div className="max-w-7xl mx-auto p-6">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <h2 className="text-2xl sm:text-3xl font-bold">Team Members List</h2>
                <button
                    className="bg-[#01132b] text-white rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-blue-600 mt-4 sm:mt-0"
                    aria-label="Add Team Member"
                    onClick={() => setAddModal(true)}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    Add Team Member
                </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-6 p-4">
                {currentMembers.length !== 0 ? (
                    currentMembers.map((member) => (
                        <TeamMember
                            key={member.id}
                            id={member.id}
                            name={member.name}
                            address={member.address}
                            phone_number={member.phone_number}
                            position={member.position}
                            branch={member.branch}
                            branchName={member.branchName}
                            email={member.email}
                            photo={member.photo}
                            branchData={branchData}
                            members={members}
                            setMembers={setMembers}
                        />
                    ))
                ) : (
                    <div className="flex justify-center items-center h-96 text-gray-500 text-lg md:text-2xl col-span-full">
                        <p>No Member List Found</p>
                    </div>
                )}
            </div>
            <div className="flex justify-between items-center mt-6">
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className={`bg-gray-300 text-gray-700 rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-[#01132b] hover:text-white ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Previous
                </button>
                <span className="text-lg font-semibold mb-2 sm:mb-0">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={`bg-gray-300 text-gray-700 rounded-lg px-4 py-2 flex items-center gap-2 hover:bg-[#01132b] hover:text-white ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                >
                    Next
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
            <AddTeamMember members={members} branchData={branchData} setMembers={setMembers} isOpen={addModal} onClose={() => setAddModal(false)} />
        </div>
    )
}