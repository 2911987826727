import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEllipsisVertical,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../api/axiosInstance";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../../context/authContext";

function truncateText(html, limit, id) {
  // Create a temporary div element to hold the HTML content
  const div = document.createElement("div");
  div.innerHTML = html;

  // Extract the plain text content from the HTML
  const textContent = div.textContent || div.innerText || "";

  // Truncate the text content
  let truncatedText = textContent.length > limit ? textContent.slice(0, limit) + "..." : textContent;

  // Return the original or truncated HTML content, not the plain text
  if (textContent.length > limit) {
    truncatedText += `<a class="text-blue-500 hover:underline" href="/article/${id}">Read more</a>`;
  }

  // Return the HTML string
  return truncatedText;
}

const Article = ({
  id,
  title,
  description,
  date,
  author = {},
  keywords = [],
  category,
  thumbnail,
  featuredImage,
  articles,
  setArticles
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { accessToken } = useAuth();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleEdit = () => {
    navigate(`/admin/dashboard/edit/article/${id}`);
  };

  const handleDelete = async (id) => {
    // Implement delete functionality here
    const toastLoading = toast.loading("Deleting please wait...");
    try {
      const response = await axiosInstance.delete(`/api/users/articles/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (response.status === 204) {
        const newArticles = articles.filter(article => article.id !== id);
        setArticles(newArticles);
        toast.dismiss(toastLoading);
        toast.success("Article deleted successfully");
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error)
    } finally {
      toast.dismiss(toastLoading);
    }
  };
  return (
    <div className="relative bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md flex flex-col hover:shadow-lg transition-shadow">
      <div className="p-4 flex flex-col flex-grow">
        <div className="relative cursor-pointer">
          <button
            onClick={toggleMenu}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              className="text-gray-500 text-2xl"
            />
          </button>
          {isMenuOpen && (
            <div
              ref={menuRef}
              className="absolute right-0 mt-2 bg-white w-[150px] rounded shadow-lg z-10"
            >
              <ul className="absolute top-9 right-0 bg-white border border-gray-300 rounded-lg shadow-lg w-32 z-10">
                <li
                  onClick={handleEdit}
                  className="px-2 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                >
                  <FontAwesomeIcon icon={faEdit} />
                  Edit
                </li>
                <li
                  onClick={() => handleDelete(id)}
                  className="px-2 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                >
                  <FontAwesomeIcon icon={faTrash} />
                  Delete
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="flex items-center mb-4">
          {author.profilePic ? (
            <img
              src={author.profilePic}
              alt={author || "Author"}
              className="w-12 h-12 rounded-full object-cover mr-3"
            />
          ) : (
            <div className="w-12 h-12 flex items-center justify-center bg-gray-200 rounded-full mr-3">
              <FontAwesomeIcon
                icon={faUser}
                className="text-gray-500 text-2xl"
              />
            </div>
          )}
          <div>
            <strong className="block capitalize text-lg">
              {author || "Admin"}
            </strong>
            <span className="text-sm text-gray-500">
              @{author || "unknown"}
            </span>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mb-4">
          <span className="bg-gray-200 text-gray-700 px-2 py-1 text-xs font-semibold rounded">
            {category || "Uncategorized"}
          </span>
          {keywords.map((keyword, index) => (
            <span
              key={index}
              className="bg-blue-100 text-blue-700 px-2 py-1 text-xs font-semibold rounded"
            >
              {keyword}
            </span>
          ))}
        </div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        {featuredImage && (
          <img
            src={featuredImage}
            alt={`Featured ${title}`}
            className="w-full h-48 object-cover mt-2"
          />
        )}
        <small className="text-gray-500 mb-2 mt-2">{date || "No Date"}</small>
        <div className="text-gray-700 mb-2" dangerouslySetInnerHTML={{ __html: truncateText(description, 150, id) }}>

        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Article;
