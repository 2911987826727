import React from 'react';
import { Link } from 'react-router-dom';
import Destinations from '../../../data/destinationdata';

const OverviewAmerica = () => {
  // Find the destination object for the United States of America
  const usa = Destinations.find(dest => dest.name === "USA");

  // Sort universities by rank
  const sortedUniversities = usa.universities.sort((a, b) => {
    const rankA = parseInt(a.rank.split(' ')[0].replace(/\D/g, ''), 10);
    const rankB = parseInt(b.rank.split(' ')[0].replace(/\D/g, ''), 10);
    return rankA - rankB;
  });

  // Get the top 5 universities
  const topUniversities = sortedUniversities.slice(0, 5);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 text-center mb-8">Overview</h1>
      
      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Why Study in the United States?</h2>
        <p className="text-gray-700">
          The United States is a leading destination for international students, offering a diverse range of programs, cutting-edge technology, and a global perspective. With hundreds of top-ranked universities, the US provides students with the opportunity to receive a high-quality education and engage in a multicultural environment.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Top Universities</h2>
        <ul className="list-disc pl-5 text-lg text-gray-700 font-semibold">
          {topUniversities.map((university, index) => (
            <li key={index}>{university.name} ({university.rank})</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Admission Requirements</h2>
        <p className="text-gray-700">
          Admission to US universities generally requires standardized test scores like the SAT or ACT, proof of English language proficiency (e.g., TOEFL or IELTS), academic transcripts, letters of recommendation, and a personal statement. Requirements may vary by university and program.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Cost of Living</h2>
        <p className="text-gray-700">
          The cost of living in the US varies widely depending on the location. Cities like New York, San Francisco, and Los Angeles are more expensive, while smaller towns and cities offer more affordable living options. Students should budget for tuition, housing, food, transportation, and other personal expenses.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-base lg:text-lg font-semibold mb-4">Scholarships and Financial Aid</h2>
        <p className="text-gray-700">
          Many universities in the US offer scholarships and financial aid to international students. Additionally, there are various external scholarships such as the Fulbright Program and university-specific grants. It's important to research and apply for financial aid early in the application process.
        </p>
      </section>

      <section>
        <h2 className="text-base lg:text-lg font-semibold mb-4">Post-Study Opportunities</h2>
        <p className="text-gray-700">
          International students in the US can take advantage of Optional Practical Training (OPT), which allows them to work in the US for up to one year after graduation (or up to three years for STEM graduates). This provides valuable work experience and can be a stepping stone to longer-term employment in the US.
        </p>
      </section>

      <Link to="/make-an-enquiry">
        <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
          Start Your Application
        </button>
      </Link>
    </div>
  );
}

export default OverviewAmerica;
