import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../api/axiosInstance';

const ArticleDisplay = () => {
  const { articleId } = useParams(); // Get articleId from route params
  const [article, setArticle] = useState(null);
  const [users, setUsers] = useState({});

  useEffect(() => {
    axiosInstance.get(`/api/users/articles/${articleId}/`)
      .then((res) => {
        const fetchedArticle = res.data.data;
        setArticle(fetchedArticle);

        // Extract unique author IDs from the article
        const authorIds = [fetchedArticle.author]; // Use fetched article's author ID

        // Fetch each author's username based on the user ID
        Promise.all(
          authorIds.map((id) =>
            axiosInstance.get(`/api/users/users/${id}/`)
          )
        )
          .then((userData) => {
            const userMap = {};
            userData.forEach((userResponse) => {
              const userDetail = userResponse.data;
              userMap[userDetail.id] = userDetail.username; // Store the username against the user ID
            });
            setUsers(userMap); // Set all users fetched
          })
          .catch((error) => console.error("Error fetching user data:", error));
      })
      .catch((error) => {
        console.log("Error fetching articles:", error);
      });
  }, [articleId]);

  if (!article) return <div>Article not found</div>;

  return (
    <div className="flex max-w-6xl flex-col md:flex-row">
      <main className="flex-1 p-6">
        {/* Image Section */}
        <div className="relative mb-6">
          <img
            src={article.featured_image}
            alt={article.title}
            className="object-cover w-full h-[260px] rounded"
          />
          {article.featured && (
            <div className="absolute top-2 right-2">
              <FontAwesomeIcon icon={faStar} className="text-orange-500 text-xl" title="Featured" />
            </div>
          )}
        </div>

        {/* Title and Author Section */}
        <div className="mb-6">
          <h1 className="text-3xl text-center font-bold mb-2">{article.title}</h1>
          <div className="flex items-center mb-4">
            <div className="relative w-12 h-12 mr-4">
              {article.author && article.author.profilePic ? (
                <img
                  src={article.author.profilePic}
                  alt={users[article.author] || "Loading..."}
                  className="object-cover w-full h-full rounded-full"
                />
              ) : (
                <FontAwesomeIcon icon={faUser} className="text-gray-500 text-4xl" />
              )}
            </div>
            <div>
              <p className="text-lg font-semibold">{users[article.author] || 'Unknown Author'}</p>
              <p className="text-gray-600 text-sm">{users[article.author] ? "@" + users[article.author] : 'No Username'}</p>
            </div>
          </div>

          {/* Category Tags */}
          <div className="mb-2 text-left">
            {article.category.split(',').map((cat, index) => (
              <span key={index} className="inline-block bg-blue-100 text-blue-700 text-sm px-3 py-1 rounded-full mr-2 mb-2">
                {cat.trim()}
              </span>
            ))}
          </div>

          <p className="text-gray-600 text-left mb-4">Published on {new Date(article.date).toLocaleDateString()}</p>
        </div>

        {/* Description Section */}
        <div className="mb-6">
          <div className="text-gray-600 custom-html-description" dangerouslySetInnerHTML={{ __html: article.description }}></div>
        </div>

        {/* Additional Info */}
        <div className="space-y-4">
          <p><strong>Keywords:</strong> {article.keywords}</p>
        </div>
      </main>
    </div>
  );
};

export default ArticleDisplay;
