import React, { useState } from 'react';
import Modal from '../modal';
import toast, { Toaster } from 'react-hot-toast';
import axiosInstance from '../../../../api/axiosInstance'; // Assuming axiosInstance is already configured

const initialState = {
    branch_name: "",
    country: "",
    city: "",
    phone_number: "",
    manager_name: "",
    manager_phone_number: "",
    email: "",
    desc: "",
    map_link: "",
};

const AddBranch = ({ onClose, isOpen, branches, setBranches }) => {
    const [values, setValues] = useState(initialState);

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const toastLoading = toast.loading("Submitting...");

        try {
            // Get the access token from localStorage or sessionStorage
            const authData = JSON.parse(localStorage.getItem('auth')) || JSON.parse(sessionStorage.getItem('auth'));

            // Ensure the token exists
            if (!authData || !authData.accessToken) {
                toast.error("Authentication token not found. Please log in again.");
                return;
            }

            const token = authData.accessToken;

            // Attach the token to the headers for authorization
            const response = await axiosInstance.post(
                '/api/users/branches/', 
                values,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Attach the token in the Authorization header
                    }
                }
            );

            if (response.data.status === "success") {
                toast.dismiss(toastLoading);
                toast.success("Successfully submitted");
                setValues(initialState);
                setBranches([...branches, response.data.data]);

                // Close the modal after successful submission
                onClose();
            }
        } catch (error) {
            toast.dismiss(toastLoading);

            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;
                for (const field in errors) {
                    if (errors.hasOwnProperty(field)) {
                        errors[field].forEach(error => toast.error(error + " in " + field));
                    }
                }
            } else {
                toast.error("Something went wrong!");
            }
        }
    };

    const isDescriptionValid = values.desc.length <= 300;

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg">
                <h2 className="text-2xl font-bold mb-6">Add Branch Details</h2>
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name='branch_name'
                            value={values.branch_name}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="country">Country</label>
                        <input
                            type="text"
                            id="country"
                            name='country'
                            value={values.country}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="city">City</label>
                        <input
                            type="text"
                            id="city"
                            name='city'
                            value={values.city}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="phone">Phone Number</label>
                        <input
                            type="tel" // Changed to "tel" to avoid number increment/decrement buttons
                            id="phone"
                            name='phone_number'
                            value={values.phone_number}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="manager_name">Manager Name</label>
                        <input
                            type="text"
                            id="manager_name"
                            name='manager_name'
                            value={values.manager_name}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="manager_name">Manager Phone Number</label>
                        <input
                            type="tel" // Changed to "tel" to avoid number increment/decrement buttons
                            id="manager_phone_number"
                            name='manager_phone_number'
                            value={values.manager_phone_number}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="manager_name">Email</label>
                        <input
                            type="email"
                            id="email"
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>
                    <div className="md:col-span-2">
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            name='desc'
                            value={values.desc}
                            onChange={handleChange}
                            required
                            className={`w-full px-3 py-2 border ${isDescriptionValid ? 'border-gray-300' : 'border-red-500'} rounded-lg text-sm md:text-base`}
                            rows="6"
                        />
                        {!isDescriptionValid && (
                            <p className="text-red-500 text-sm mt-1">Description cannot exceed 300 characters.</p>
                        )}
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2 text-sm md:text-base" htmlFor="mapLink">Map Link</label>
                        <input
                            type="url"
                            id="map_link"
                            name='map_link'
                            value={values.map_link}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm md:text-base"
                        />
                    </div>

                    <div className="col-span-1 md:col-span-2 flex justify-end gap-4 mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white rounded-full px-4 py-2 text-xs md:text-sm hover:bg-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={!isDescriptionValid}
                            className={`rounded-full px-4 py-2 text-xs md:text-sm ${isDescriptionValid ? 'bg-[#01132b] text-white hover:bg-[#022b47]' : 'bg-blue-300 text-gray-500 cursor-not-allowed'}`}
                        >
                            Add Branch
                        </button>
                    </div>
                </form>
            </div>
            <Toaster />
        </Modal>
    );
};

export default AddBranch;
